import { useQuery } from "@apollo/client";
import { GET_EDITIONS } from "./graphql";
import TableContainer from "@mui/material/TableContainer";
import {
  Pagination,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { PageContainer } from "../../components/PageContainer";
import { LinkButton } from "../../components/LinkButton";
import { LoadingOverlay } from "../../components/Loading";

const Editions = () => {
  const { data, error, loading } = useQuery(GET_EDITIONS, {
    fetchPolicy: "cache-first",
  });
  if (loading) return null;

  return (
    <PageContainer title="Festival Editions">
      <LinkButton path={`/edition/create`} text="Create" variant="contained" />
      <LoadingOverlay loading={loading}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">name</TableCell>
                <TableCell align="left">year</TableCell>
                <TableCell align="left">actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.festivalEditions.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.year}</TableCell>
                  <TableCell align="left">
                    <LinkButton path={`/edition/${row.id}`} text="View" />
                    <LinkButton
                      path={`/edition/edit/${row.id}`}
                      text="Edit settings"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </LoadingOverlay>
    </PageContainer>
  );
};

export default Editions;
