import { useMutation, useQuery } from "@apollo/client";
import TableContainer from "@mui/material/TableContainer";
import {
  Pagination,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import { GET_ALL_SPONSORS, DELETE_SPONSOR } from "./graphql";
import { LinkButton } from "../../components/LinkButton";
import { PageContainer } from "../../components/PageContainer";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { UpdateMiscConfigButton } from "../links/updateMiscConfig";

const Sponsors = () => {
  const { data, error, loading, refetch } = useQuery(GET_ALL_SPONSORS, {
    fetchPolicy: "cache-first",
  });
  const [deleteSponsor] = useMutation(DELETE_SPONSOR, {
    onCompleted: () => {
      toast("Sponsor deleted");
      refetch();
    },
  });

  const handleDelete = (sponsorId: string) => {
    confirmAlert({
      title: "Delete this Sponsor?",
      message: `Are you sure? this can't be undone.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteSponsor({ variables: { sponsorId: sponsorId } }),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  if (loading) return null;

  return (
    <PageContainer title="Sponsors">
      <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
        <LinkButton
          path="/sponsor/create"
          text="Create new sponsor"
          variant={"contained"}
        />
        <UpdateMiscConfigButton />
      </div>
      <TableContainer component={Paper} style={{ marginTop: 16 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">name</TableCell>
              <TableCell align="left">order</TableCell>
              <TableCell align="center">actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.sponsors.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.order}</TableCell>
                <TableCell align="center">
                  <LinkButton path={`/sponsor/${row.id}`} text="View" />
                  <Button
                    title="Delete"
                    variant="contained"
                    color="error"
                    onClick={() => handleDelete(row.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageContainer>
  );
};

export default Sponsors;
