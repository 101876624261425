import { useQuery } from "@apollo/client";
import { GET_NOTIFICATION_HISTORY } from "./graphql";
import {
  CircularProgress,
  Container,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import React from "react";
import { LinkButton } from "../../components/LinkButton";
import { PageContainer } from "../../components/PageContainer";
import { LoadingOverlay } from "../../components/Loading";
import dayjs from "dayjs";
import { Note } from "../../components/Note";
const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Name", width: 130 },
  {
    field: "bookings",
    headerName: "Bookings",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.bookings.length}`,
  },
];

const NotificationHistory = () => {
  const [page, setPage] = React.useState(1);
  const { data, error, loading } = useQuery(GET_NOTIFICATION_HISTORY);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <PageContainer title="Notifications">
      <LinkButton
        path="/notifications"
        text="New Notification"
        variant={"contained"}
        style={{ marginBottom: 30 }}
      />
      <Pagination
        // count={Math.round(data?.bandCount! / 50)}
        onChange={handleChange}
        page={page}
      />
      <LoadingOverlay loading={loading}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">title</TableCell>
                <TableCell align="left">message</TableCell>
                <TableCell align="left">date</TableCell>
                <TableCell align="left">lineup update number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.notificationHistories.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{row.title}</TableCell>
                  <TableCell align="left">{row.message}</TableCell>
                  <TableCell align="left">
                    {dayjs(row.date).format("YYYY/MM/DD hh:mm")}
                  </TableCell>
                  <TableCell align="left">{row.exportNumber}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </LoadingOverlay>
      <Note
        message={`Note: An export number indicates the notification was used to tell the app that a lineup has been published`}
      />
    </PageContainer>
  );
};

export default NotificationHistory;
