import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { StageForm } from "./form";
import { GET_STAGE } from "./graphql";

const Stage = () => {
  const [getStage, { data, error, loading }] = useLazyQuery(GET_STAGE);
  const { id } = useParams();

  useEffect(() => {
    console.log(id);
    if (!id) return;

    getStage({ variables: { id: id } });
  }, [id]);

  if (loading || !data) return null;
  
  if(!data.stage) return<div>stage not found</div>

  return <StageForm stage={data?.stage} />;
};

export default Stage;
