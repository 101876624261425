import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { ButtonContainer } from "../../components/ButtonContainer";
import { PageContainer } from "../../components/PageContainer";
import { useQuery } from "@apollo/client";
import { GET_CONFIG } from "./graphql";
import { ManageConfigTable } from "./ManageConfigTable";

const schema = yup
  .object({
    config: yup
      .array()
      .of(yup.object({ keyId: yup.string(), value: yup.string() })),
  })
  .required();

export const ManageConfig = () => {
  const { data, refetch, loading, error } = useQuery(GET_CONFIG);

  if (!data) return;

  return <ManageConfigTable data={data.configs} refetch={refetch}/>;
};
