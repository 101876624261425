// import { request } from "https";
// const https = require('node:https');
import axios from "axios";
import { URL } from "url";

export const s3Put = async (
  url: string | URL,
  data: BlobPart,
  addContentType: boolean = false
) => {
  // return new Promise((resolve, reject) => {

  const config = {
    headers: {
      "Content-Type": addContentType ? "application/json; charset=utf-8" : undefined,
    },
  };
  const res = await axios.put(url as string, data, config);

  console.log("res", res);
  //   const req = request(
  //     url,
  //     { method: "PUT", headers: { },
  //     (res: any) => {
  //       let responseBody = "";
  //       res.on("data", (chunk: any) => {
  //         responseBody += chunk;
  //       });
  //       res.on("end", () => {
  //         resolve(responseBody);
  //       });
  //     }
  //   );
  //   req.on("error", (err: any) => {
  //     reject(err);
  //   });
  //   req.write(data);
  //   req.end();
  // });
  // })
};
