import { useMutation } from "@apollo/client";
import { Button } from "@mui/material";
import React, { useState } from "react";
import Resizer from "react-image-file-resizer";
import { ADD_IMAGE } from "../graphql";
import { s3Put } from "../s3";
import { ButtonContainer } from "../../../components/ButtonContainer";
import { PageContainer } from "../../../components/PageContainer";
import { EditImageContainer } from "./Select";
import { Note } from "../../../components/Note";
interface IUploadProps {
  file: string;
  fileName: string;
  returnAction: (imageUrl: string) => void;
}

const Upload = ({ file, fileName, returnAction }: IUploadProps) => {
  const [image, setImage] = useState<string>(file);
  const [resizedFile, setResizedFile] = useState<string>();
  const [getImageUrl, { data, error }] = useMutation(ADD_IMAGE, {});
  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        2000, // maxWidth
        2000, // maxHeight
        "JPEG",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
        // 1500, // minWidth?
        // 1500 // minHeight
      );
    });
  const handleClick = async () => {
    try {
      const fileBlob = await fetch(file).then((r) => r.blob());
      const image = (await resizeFile(fileBlob)) as string;
      setResizedFile(image);
    } catch (err) {
      console.log(err);
    }
  };
  const handleSave = async () => {
    const url = `${process.env.REACT_APP_BUCKET_URL}/${fileName}`;

    const res = await getImageUrl({ variables: { image: fileName } });
    if (!res.data?.addImage || !resizedFile) return;

    const fileBlob = await fetch(resizedFile).then((r) => r.blob());
    await s3Put(res.data?.addImage, fileBlob);

    returnAction(url);
  };
  return (
    <PageContainer title="Resize & Upload">
      <EditImageContainer>
        <ButtonContainer>
          <Button title="Reize" onClick={handleClick}>
            Resize
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!resizedFile}
          >
            Upload
          </Button>
        </ButtonContainer>
      </EditImageContainer>
      <Note
        message={`Info: This step will greatly reduce the file size of the image \n a preview of the prepared image can be found below`}
        style={{ left: 0 }}
      />

      <h4>Before</h4>
      <img src={image} alt="" width={"75%"} />

      <h4>After</h4>
      <img src={resizedFile} alt="" width={"75%"} />
    </PageContainer>
  );
};

export default Upload;
