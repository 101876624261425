import { Navigate, Outlet } from "react-router-dom";
import {
  Context,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ME } from "./users/graphql";

// type AccessToken = {
//   token: string;
//   refreshToken: string;
// };

type TokenProviderType = {
  accessToken: string;
  setToken: (token: string | undefined) => void;
};

export const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const auth = useAuth();
  const navigate = useNavigate();
  if (!auth?.accessToken) {
    return <Navigate to={"login"} />;
  }
  return <Outlet />;
};

const AuthContext = createContext<TokenProviderType | null>(null);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [token, setToken] = useLocalStorage<string>("token", "");
  const navigate = useNavigate();

  const value = useMemo(
    () => ({
      accessToken: token,
      setToken: (token: string | undefined) => setToken(token),
    }),
    [token]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
export const useLocalStorage = <T,>(keyName: string, defaultValue: T) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue: object) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};
