import { gql } from "@apollo/client";

export const GET_VENDOR = gql(`
query vendor($id: String!) {
  foodVendor (where: {id: $id}){
    id
    name
    description
    image
    type    
  }
}
`);

export const UPDATE_VENDOR = gql(`
mutation updateVendor($vendor: UpdateVendorInputType!) {
  updateFoodVendor(vendor: $vendor) {
    id
    name
    description
    image
    type    

  }
}
`);

export const CREATE_VENDOR = gql(`
mutation createFoodVendor($name: String!, $description:String!) {
  createFoodVendor(name: $name, description:$description){
    id
    name
    description
  }
}
`);

export const ADD_IMAGE = gql(`
mutation addImage($image: String!){
  addImage(image: $image)
}
`);

export const DELETE_VENDOR = gql(`mutation deleteFoodVendor($vendorId:String!) {
  deleteFoodVendor(vendorId: $vendorId){
    id
  }
}`);
