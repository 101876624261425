import { useMutation } from "@apollo/client";
import { DELETE_EDITION, UPDATE_EDITION } from "./graphql";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Container, TextField } from "@mui/material";
import { type Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  FestivalEdition,
  UpdateBookingDocument,
} from "../../../__generated__/graphql";
import { PageContainer } from "../../../components/PageContainer";
import { ButtonContainer } from "../../../components/ButtonContainer";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import { Info } from "../../../components/Info";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const schema = yup
  .object({
    name: yup.string().required(),
    year: yup.string().required(),
    availabilityDate: yup.string().required(),
  })
  .required();

const EditionForm = ({ edition }: { edition: Partial<FestivalEdition> }) => {
  const navigate = useNavigate();
  const [updateEdition, { loading: updateLoading, error }] = useMutation(
    UPDATE_EDITION,
    {
      onCompleted: () => {
        toast("edition updated");
      },
    }
  );
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control
  } = useForm({ resolver: yupResolver(schema) });
  const [deleteEdition] = useMutation(DELETE_EDITION, {
    onCompleted: () => {
      toast("editon deleted");
      navigate(-1);
    },
  });
  const onSubmit = (val: any) => {
    const { name, year, availabilityDate } = val;
    updateEdition({
      variables: {
        id: edition.id!,
        name,
        year,
        availabilityDate: availabilityDate,
      },
    });
  };
  const handleDelete = () => {
    confirmAlert({
      title: "Delete this edition?",
      message: "Are you sure? this can't be undone",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            deleteEdition({ variables: { editionId: edition.id! } }),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <PageContainer title="Edit edition details">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container style={{ flexDirection: "column", display: "flex" }}>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            defaultValue={edition?.name}
            {...register("name")}
          />
          <p>{errors.name?.message}</p>
          <TextField
            id="outlined-basic"
            label="Year"
            variant="outlined"
            defaultValue={edition?.year}
            {...register("year")}
          />
           <Controller
            control={control}
            name="availabilityDate"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DateTimePicker
                format="YYYY/MM/DD HH:mm"
                ampm={false}
                timezone="Europe/London"
                label="Available in app on date"
                // @ts-ignore
                defaultValue={dayjs(edition?.availabilityDate)}
                // @ts-ignore
                onChange={onChange} // send value to hook form
              />
            )}
          />
          <p>{errors.availabilityDate?.message}</p>
          <ButtonContainer>
            <Button type="submit" variant="contained">
              Save
            </Button>
            <Button
              variant="contained"
              onClick={handleDelete}
              style={{ backgroundColor: "red" }}
            >
              Delete
            </Button>
          </ButtonContainer>
        </Container>
      </form>
      <Info />
    </PageContainer>
  );
};

export default EditionForm;
