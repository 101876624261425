import { gql } from "../../../__generated__/gql";

export const GET_EDITIONS = gql(`
query festivalEditions {
    festivalEditions {
        id
        name
      year
      days {
        id
        date
        name
      }
    }
  }
`);
