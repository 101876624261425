import React, { useRef, useState } from "react";
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import { Button } from "@mui/material";
import { PageContainer } from "../../../../components/PageContainer";
import { EditImageContainer } from "../Select";
import { Note } from "../../../../components/Note";
interface ICropProps {
  file: string;
  handleCroppedFile: (file: string) => void;
}
//https://codesandbox.io/s/react-image-crop-demo-with-react-hooks-y831o?file=/src/App.tsx:1967-1983

//numbers from iphone 15 pro
const aspectRatios = [
  393 / 180, // news list
  393 / 250, // news item
  16 / 9,
  1 / 1,
];

const CropImage = ({ file, handleCroppedFile }: ICropProps) => {
  const [imgSrc, setImgSrc] = useState(file);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(16 / 9);

  //   function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
  //     if (e.target.files && e.target.files.length > 0) {
  //       setCrop(undefined); // Makes crop preview update between images.
  //       const reader = new FileReader();
  //       reader.addEventListener("load", () =>
  //         setImgSrc(reader.result?.toString() || "")
  //       );
  //       reader.readAsDataURL(e.target.files[0]);
  //     }
  //   }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function onContinue() {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);
      handleCroppedFile(blobUrlRef.current);
      //   hiddenAnchorRef.current!.href = blobUrlRef.current;
      //   hiddenAnchorRef.current!.click();
    });
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const handleAspectRatioPress = (aspectRatio: number | undefined) => {
    // @ts-ignore
    const { width, height } = imgRef.current;

    setCrop(centerAspectCrop(width, height, aspectRatio ?? 1));
    setAspect(aspectRatio);
  };

  return (
    <PageContainer title="Crop the image">
      <EditImageContainer>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "25% 25%",
            // gridGap: "20",
            gridColumnGap: "8px",
            alignItems: "center",
          }}
        >
          <div>
            <label htmlFor="scale-input">Scale: </label>
            <input
              id="scale-input"
              type="number"
              step="0.1"
              value={scale}
              disabled={!imgSrc}
              onChange={(e) => setScale(Number(e.target.value))}
            />
          </div>
          <div>
            <label htmlFor="rotate-input">Rotate: </label>
            <input
              id="rotate-input"
              type="number"
              value={rotate}
              disabled={!imgSrc}
              onChange={(e) =>
                setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
              }
            />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gridColumnStart: 1,
              gridColumnEnd: 4,
              gridRow: 2,
              paddingTop:50
            }}
          >
            <Button
              disabled={true}
              onClick={() => handleAspectRatioPress(2 / 1)}
              variant={aspect === 2 / 1 ? "contained" : "outlined"}
              style={{ flex: 1 }}
            >
              Aspect Ratio: 2/1
            </Button>
            <Button
              onClick={() => handleAspectRatioPress(16 / 9)}
              variant={aspect === 16 / 9 ? "contained" : "outlined"}
              style={{ flex: 1 }}
            >
              Aspect Ratio: 16 / 9
            </Button>
            <Button
              onClick={() => handleAspectRatioPress(1 / 1)}
              variant={aspect === 1 / 1 ? "contained" : "outlined"}
              style={{ flex: 1 }}
            >
              Aspect Ratio: 1/1
            </Button>
            <Button
              onClick={() => handleAspectRatioPress(undefined)}
              variant={aspect === undefined ? "contained" : "outlined"}
              style={{ flex: 1 }}
            >
              Free Form
            </Button>
          </div>
        </div>
        <div style={{ paddingTop: 30, paddingBottom: 30 }}>
          <Button variant="contained" onClick={onContinue}>
            Proceed
          </Button>
          <a
            ref={hiddenAnchorRef}
            download
            style={{
              position: "absolute",
              top: "-200vh",
              visibility: "hidden",
            }}
          >
            Hidden download
          </a>
        </div>
      </EditImageContainer>
      <Note
        message={`Info: A preview of the cropped image can be found below`}
        style={{ marginBottom: 38 }}
      />
      {!!imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      {!!completedCrop && (
        <>
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
        </>
      )}
    </PageContainer>
  );
};

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default CropImage;
