import { useLazyQuery } from "@apollo/client";
import { GET_BAND } from "../graphql";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import "react-image-crop/dist/ReactCrop.css";
import BandForm from "./form";
import { Band as BandType } from "../../../__generated__/graphql";

const Band = () => {
  const [getBand, { data, loading }] = useLazyQuery(GET_BAND);
  const { id } = useParams();
  useEffect(() => {
    console.log(id);
    if (!id) return;
    getBand({ variables: { id: id } });
  }, [id]);

  if (loading) return null;
  // aspect ratio 3∶2
  if (!data?.band) return <div>band not found</div>;

  return <BandForm band={data?.band as BandType} />;
};

export default Band;
