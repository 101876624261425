import { gql } from "../../../../__generated__/gql";

export const GET_EDITION = gql(`
query festivalSingalEdition($festivalId: String!) {
  festivalEdition(where: {
    id: $festivalId
  }) {
    id
    name
    year
    availabilityDate
  }
}
`);

export const UPDATE_EDITION = gql(`
mutation updateEdition($id:String!, $name: String!, $year:String!, $availabilityDate: String!) {
  updateEdition(festival: {
    id: $id,
    name: $name,
    year: $year    
    availabilityDate: $availabilityDate
}){
    id
}
}
`);
export const DELETE_EDITION = gql(`mutation deleteEdition($editionId: String!) {
  deleteEditionAndBands(editionId: $editionId)
}`);
