import TableContainer from "@mui/material/TableContainer";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { BandBooking } from "../../__generated__/graphql";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { LinkButton } from "../../components/LinkButton";
interface IBandTableProps {
  bands: BandBooking[];
}

const BandsTable = ({ bands }: IBandTableProps) => {
  return (
    <>
    <h2>{bands[0].stage.name}</h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" width='50%' >name</TableCell>
              <TableCell align="left" width="10%">start - end</TableCell>
              <TableCell align="left" width="10%">duration</TableCell>
              <TableCell align="left" width="15%">stage</TableCell>
              <TableCell align="left" width="15%">actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* @ts-ignore */}
            {bands.sort((a,b)=> new Date(a.startTime) - new Date(b.startTime)).map((row) => (
              <TableRow
                key={row.id}
                // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{row.band.name}</TableCell>
                <TableCell align="left">
                  {dayjs(row.startTime).format("HH:mm")} - {dayjs(row.endTime).format("HH:mm")}
                </TableCell>
                <TableCell align="left">{row.duration}</TableCell>
                <TableCell align="left">{row.stage.name}</TableCell>
                <TableCell align="left">
                  <LinkButton path={`/booking/${row.id}`} text="View" />
                  <LinkButton path={`/band/${row.band.id}`} text="View Band" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default BandsTable;
