import { gql } from "../../../__generated__";

export const GET_INFO = gql(`
query info($id: String!) {
  info(where: {
    id: $id
  }) {
    id
    infoId: id
    title
    value
    order
    hidden
}
}
`);

export const GET_INFOS = gql(`
    query infos {
      infos {
         id
         infoId: id
        title
        value
        order
        hidden
}
    }
    `);

export const CREATE_INFO = gql(`
  mutation createInfo($title: String!, $value: JSON!) {
  createInfo(
    title: $title,
    value: $value,
  ) {
    id
    infoId: id
    title
    value
    order
    hidden
    }
}`)

export const UPDATE_INFO = gql(`
  mutation updateInfo($id: String!, $title: String!, $value: JSON!, $hidden: Boolean!, $order: Int!) {
  updateInfo(info:{id: $id, title: $title, value: $value, hidden: $hidden, order:$order}) {
    id  
    title
    value
    order
    hidden  
  }  
}    `  
);

export const DELETE_INFO = gql(`
 mutation deleteInfo($infoId: String!) {
  deleteInfo(infoId: $infoId) {
    id
  }
}`)