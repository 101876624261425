import { Container } from "@mui/material";
export const Note = ({
  message,
  style,
}: {
  message: string;
  style?: React.CSSProperties;
}) => {
  return (
    <Container
      style={{
        // margin: 30,
        backgroundColor: "lightblue",
        width: "50%",
        borderRadius: 14,
        height: 150,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        // textAlign: "center",
        // alignSelf:'center',
        margin: "auto",
        marginTop: 30,
        ...style,
      }}
    >
      <p style={{ textAlign: "center" }}>{message}</p>
    </Container>
  );
};
