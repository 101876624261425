import { gql } from "../../../__generated__/gql";


export const GET_NOTIFICATION_HISTORY = gql(`query notificationHistories {
  notificationHistories(orderBy: {
    date: desc
  }) {
    id
    date
    title
    message
    exportNumber
  }
}`)