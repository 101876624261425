import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { Info, Link, LinkType } from "../../../__generated__/graphql";
import { LoadingOverlay } from "../../../components/Loading";
import { PageContainer } from "../../../components/PageContainer";
import { CREATE_INFO, GET_INFOS, UPDATE_INFO } from "../graphql";
import * as yup from "yup";
import { UpdateMiscConfigButton } from "../../links/updateMiscConfig";

const schema = yup.object({
  title: yup.string().required(),
  order: yup.number().required(),
  hidden: yup.boolean().required(),
});

export const EditInfoForm = ({
  info,
  refetch,
}: {
  info: Info;
  refetch: () => void;
}) => {
  const [updateInfo] = useMutation(UPDATE_INFO, {
    onCompleted: () => {
      refetch();
      toast("Update complete");
    },
  });

  const onSubmit = ({
    title,
    order,
    hidden,
  }: {
    title: string;
    order: number;
    hidden: boolean;
  }) => {
    updateInfo({
      // @ts-ignore
      variables: {
        ...info,
        title,
        order: order ?? 0,
        hidden,
      },
    });
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: info.title ?? "placeholder title",
      order: info.order ?? 0,
      hidden: info.hidden ?? false,
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageContainer title="Edit Info Section">
        <LoadingOverlay loading={false}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="submit"
              variant="contained"
              style={{ marginTop: 18, marginBottom: 18, marginRight: 8 }}
            >
              Save
            </Button>

            <UpdateMiscConfigButton />
          </div>
          <TextField
            style={{ marginTop: 16 }}
            fullWidth
            variant="outlined"
            label="order"
            defaultValue={info.title}
            error={!!errors.title?.message}
            {...register(`title`)}
          />
          <TextField
            style={{ marginTop: 16 }}
            fullWidth
            type="number"
            variant="outlined"
            label="order"
            defaultValue={info.order}
            error={!!errors.order?.message}
            {...register(`order`)}
          />
          <TextField
            style={{ marginTop: 16 }}
            fullWidth
            variant="outlined"
            label="hidden"
            disabled
            defaultValue={info.hidden}
            error={!!errors.hidden?.message}
            {...register(`hidden`)}
          />
          <hr />
        </LoadingOverlay>
        {/* @ts-ignore */}
        {!!errors && errors?.items?.length > 0 && (
          <>
            <h5>Please fix the following errors:</h5>
            {/* @ts-ignore */}
            {errors?.items?.map((item) => {
              if (!item) return;

              return Object.keys(item).map((key) => {
                // @ts-ignore
                const message = item?.[key].message ?? "";
                const info = message.split(".")[1];
                // @ts-ignore
                return <p>{info || message}</p>;
              });
            })}
          </>
        )}
      </PageContainer>
    </form>
  );
};
