import { useQuery } from "@apollo/client";
import { GET_POSTS } from "./graphql";
import {
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import React from "react";
import { LinkButton } from "../../components/LinkButton";
import { PageContainer } from "../../components/PageContainer";
import { LoadingOverlay } from "../../components/Loading";
import dayjs from "dayjs";
const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Name", width: 130 },
  {
    field: "bookings",
    headerName: "Bookings",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.bookings.length}`,
  },
];

const Posts = () => {
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const { data, error, loading } = useQuery(GET_POSTS, {
    variables: {
      skip: (page - 1) * 50,
      take: 50,
      // @ts-ignore
      where:
        search.length > 0
          ? {
              name: {
                contains: search,
              },
            }
          : undefined,
    },
  });
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  // if (loading) return null;

  return (
    <PageContainer title="News">
      <TextField
        id="outlined-basic"
        disabled={true}
        label="Search"
        variant="outlined"
        placeholder="Search..."
        style={{ width: "40%" }}
        onChange={(value) => setSearch(value.target.value)}
      />
      <LinkButton
        path="/post/create"
        text="Create new post"
        variant={"contained"}
        style={{ marginLeft: 30 }}
      />
      <Pagination
        count={Math.round(data?.posts.length! / 50)}
        onChange={handleChange}
        page={page}
      />
      <LoadingOverlay loading={loading}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">title</TableCell>
                <TableCell align="center">date</TableCell>
                <TableCell align="center">actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.posts.map((row) => (
                <TableRow
                  key={row.title}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell align="center">
                    {dayjs(row.date).format("YYYY/MM/DD HH:mm")}
                  </TableCell>
                  {/* <TableCell align="center">{row.bookings.length}</TableCell> */}
                  <TableCell align="center">
                    <LinkButton path={`/post/${row.id}`} text="View" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </LoadingOverlay>
    </PageContainer>
  );
};

export default Posts;
