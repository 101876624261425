import { gql } from "../../../../__generated__/gql";

export const CREATE_EDITION = gql(`
mutation createEdition($name: String!, $year: String!, $orgId: String!) {
  createEdition (festival: {
    name: $name, year: $year, organisationId: $orgId
  }){
    id
    name    
  }
}
`);
