import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import SelectSearch from "react-select-search";
import { useQuery } from "@apollo/client";
import "react-select-search/style.css";
import "./BandPicker.css";

import { GET_BANDS } from "./graphql";
interface IBandPickerProps {
  handleChange: (stageId: string) => void;
  defaultValue?: string;
  value: string | undefined;
  placeholder?: string;
  controlStyle?: React.CSSProperties;
}

export const BandPicker = ({
  handleChange,
  defaultValue,
  value,
  placeholder,
  controlStyle
}: IBandPickerProps) => {
  const { data, error, loading } = useQuery(GET_BANDS, {
    variables: { orgId: process.env.REACT_APP_ORG_ID ?? "" },
  });
  const options =
    data?.bands.map((band) => ({
      name: band.name,
      value: band.id,
    })) ?? [];

  return (
    <FormControl
      fullWidth
      style={{
        marginTop: "20px",
        marginBottom: "20px",
        backgroundColor: "white",
        width: "100%",
        ...controlStyle
      }}
    >
      <InputLabel id="demo-simple-select-label">Band</InputLabel>
      <SelectSearch
        options={options}
        value={value}
        search={true}
        placeholder={placeholder ?? "Select a band"}
        onChange={(val) => handleChange(val as string)}
      />
    </FormControl>
  );
};
