import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { Info, Link, LinkType } from "../../../__generated__/graphql";
import { LoadingOverlay } from "../../../components/Loading";
import { PageContainer } from "../../../components/PageContainer";
import { CREATE_INFO, GET_INFOS, UPDATE_INFO } from "../graphql";
import * as yup from "yup";
import { UpdateMiscConfigButton } from "../../links/updateMiscConfig";

interface IContentObject {
  infoType: string;
  content: string;
  order: number;
}

// const schema = yup
//   .object({
//     infos: yup
//       .array()
//       .of(
//         yup.object({
//           id: yup.string().required(),
//           title: yup.string().required(),
//           order: yup.number().required(),
//           hidden: yup.boolean().required(),
//           value: yup.object({
//             infoType: yup.string().required(),
//             content: yup.string().required(),
//           }),
//         })
//       )
//       .required(),
//   })
//   .required();

const schema = yup.object({
  items: yup
    .array()
    .of(
      yup
        .object({
          infoType: yup.string().required(),
          content: yup.string().required(),
          order: yup.number().required(),
        })
        .required()
    )
    .required(),
});

export const SecondForm = ({
  info,
  items,
  refetch,
}: {
  info: Info;
  items: IContentObject[];
  refetch: () => void;
}) => {
  useEffect(() => {
    replace(items);
  }, [items]);

  const [updateInfo] = useMutation(UPDATE_INFO, {
    onCompleted: () => {
      refetch();
      toast("Update complete");
    },
  });

  const onSubmit = (val: { items: IContentObject[] }) => {
    console.log("submitted values", JSON.stringify(val));
    updateInfo({
      // @ts-ignore
      variables: {
        ...info,
        value: {
          items: val.items,
        },
      },
    });
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      items: items as IContentObject[],
    },
  });
  const { fields, replace, append, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "items", // unique name for your Field Array
    }
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageContainer title="Edit Section Items">
        <LoadingOverlay loading={false}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="submit"
              variant="contained"
              style={{ marginTop: 18, marginBottom: 18, marginRight: 8 }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                const newInfo: IContentObject = {
                  order: fields.length,
                  infoType: "text",
                  content: "",
                };
                // @ts-ignore
                append(newInfo);
              }}
              color="success"
              variant="contained"
              style={{ marginTop: 18, marginBottom: 18 }}
            >
              Add New
            </Button>

            <UpdateMiscConfigButton />
          </div>

          <hr />

          {fields
            // @ts-ignore
            .sort((a, b) => a.order - b.order)
            .map((row, index) => {
              return (
                <div style={{ paddingBottom: 90 }} key={row.content}>
                  <Controller
                    control={control}
                    {...register(`items.${index}.infoType`)}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                      const types = ["text", "image"]
                      return (
                        <>
                          <InputLabel id="demo-simple-select-label">
                            Content Type
                          </InputLabel>
                          <Select
                            value={value}
                            label="Content Type"
                            defaultValue={"text"}
                            onChange={(event) => onChange(event.target.value)}
                          >
                            {types.map((ty) => {
                              return <MenuItem value={ty}>{ty}</MenuItem>;
                            })}
                          </Select>
                        </>
                      );
                    }}
                  />
                  <TextField
                    style={{ marginTop: 16 }}
                    fullWidth
                    type="number"
                    variant="outlined"
                    id={row.id}
                    label="order"
                    defaultValue={row.order}
                    error={!!errors.items?.[index]?.order?.message}
                    {...register(`items.${index}.order`)}
                  />
                  <TextField
                    id={row.id}
                    style={{ marginTop: 16 }}
                    fullWidth
                    variant="outlined"
                    multiline={true}
                    label="content"
                    defaultValue={row.content}
                    error={!!errors.items?.[index]?.content?.message}
                    {...register(`items.${index}.content`)}
                  />
                  <Button
                    onClick={() => {
                      remove(index);
                    }}
                    color="error"
                    variant="contained"
                    style={{
                      marginBottom: 20,
                    }}
                  >
                    delete
                  </Button>
                </div>
              );
            })}
        </LoadingOverlay>
        {/* @ts-ignore */}
        {!!errors && errors?.items?.length > 0 && (
          <>
            <h5>Please fix the following errors:</h5>
            {/* @ts-ignore */}
            {errors?.items?.map((item) => {
              if (!item) return;

              return Object.keys(item).map((key) => {
                // @ts-ignore
                const message = item?.[key].message ?? "";
                const info = message.split(".")[1];
                // @ts-ignore
                return <p>{info || message}</p>
              });
            })}
          </>
        )}
      </PageContainer>
    </form>
  );
};
