import { MelioraTheme } from "./types";


interface IHomeLayoutProps {
  theme: MelioraTheme;
}

export const HomeLayout = (props: IHomeLayoutProps) => {
  const { primaryColor, primaryColorText, primaryColorDark} =
    props.theme;
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: primaryColorDark,
        height: "100%",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          height: 165,
          backgroundColor: primaryColor,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <text
          style={{
            textAlign: "center",
            alignSelf: "center",
            color: primaryColorText,
          }}
        >
          Countdown timer
        </text>
      </div>
      <NewsItem primaryColor={primaryColor} />
      <NewsItem primaryColor={primaryColor} />
    </div>
  );
};

const NewsItem = ({ primaryColor }: { primaryColor: string }) => (
  <div
    style={{
      marginTop: 18,
      alignSelf: "center",
      width: "90%",
      height: 250,
      backgroundColor: primaryColor,
    }}
  >
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        height: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <text>image placeholder</text>
    </div>
  </div>
);
