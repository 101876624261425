import { useMutation } from "@apollo/client";
import { CREATE_EDITION } from "./graphql";
import { useForm } from "react-hook-form";
import { Button, Container, TextField } from "@mui/material";
import "react-image-crop/dist/ReactCrop.css";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  FestivalEdition,
} from "../../../__generated__/graphql";
import { PageContainer } from "../../../components/PageContainer";
import { ButtonContainer } from "../../../components/ButtonContainer";
import { useNavigate } from "react-router-dom";
const schema = yup
  .object({
    name: yup.string().required(),
    year: yup.string().required(),
  })
  .required();

const EditionForm = () => {
  const navigate =useNavigate()
  const [createEdition, { loading: updateLoading, error }] = useMutation(
    CREATE_EDITION,
    {
      onCompleted: () => {
        toast("edition created");
        navigate(-1)

      },
      onError: (error) => {
        toast(JSON.stringify(error))
      }
    }
  );
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  // const handleDelete = () => {
  //   confirmAlert({
  //     title: "Delete this Edition?",
  //     message: "Are you sure? this can't be undone",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () =>
  //           deleteBand({ variables: { bandId: band.id } }),
  //       },
  //       {
  //         label: "No",
  //         onClick: () => {},
  //       },
  //     ],
  //   });
  // };
  const onSubmit = (val: any) => {
    const { name, year } = val;
    createEdition({
      variables: {
        orgId: process.env.REACT_APP_ORG_ID ?? '',
        name,
        year,
      },
    });
  };

  return (
    <PageContainer title="Create edition">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container style={{ flexDirection: "column", display: "flex" }}>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            {...register("name")}
          />
          <p>{errors.name?.message}</p>
          <TextField
            id="outlined-basic"
            label="Year"
            variant="outlined"
            {...register("year")}
          />
          <p>{errors.year?.message}</p>
          <ButtonContainer>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </ButtonContainer>
          {/* <Button variant="contained" onClick={handleDelete} style={{backgroundColor:'red'}}>Delete band</Button> */}
        </Container>
      </form>
    </PageContainer>
  );
};

export default EditionForm;
