import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "../graphql";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { User } from "../../../__generated__/graphql";
import UserForm from "./form";

const EditUser = () => {
  const [getUser, { data, loading, error }] = useLazyQuery(GET_USER);
  const { id } = useParams();

  console.log("id", id);
  console.log("error", error);
  useEffect(() => {
    try {
      console.log(id);
      if (!id) return;
      getUser({ variables: { id: id } });
    } catch (ex) {
      console.log("ex", ex);
    }
  }, [id]);

  if (loading) return null;

  if (!data?.users) return <div>User not found</div>;

  return <UserForm user={data?.users[0] as User} />;
};

export default EditUser;
