import { Box, Container } from "@mui/material";

const Landing = () => {
  return (
    <Container>
      <h1>{process.env.REACT_APP_FESTIVAL} Admin page</h1>
      <h3>
        If you make any change, or update an image - remember to save the
        changes. publishing updates won't work unless you save
      </h3>
      <h3>
        Please note all changes, to the lineup and news will not be received by
        the app until they are published
      </h3>
      <p>News items are published individually</p>
      <p>
        Lineup changes are all published via the "Update App" button, when
        viewing a festival edition
      </p>
      <br />
      <hr />
      <br />
      <Box>
        <h3>What's new as of 2023/10/24</h3>
        <ul>
          <li>You can schedule news posts by picking a date in the future</li>
        </ul>
      </Box>
      <Box>
        <h3>What's new as of 2023/08/22</h3>
        <ul>
          <li>Edit band now lists all performances</li>
          <li>Start and end times are now correctly validated</li>
        </ul>
      </Box>
    </Container>
  );
};

export default Landing;
