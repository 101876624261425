import { useMutation, useQuery } from "@apollo/client";
import { DELETE_LINK, GET_LINKS, UPDATE_LINKS } from "./graphql";
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import { PageContainer } from "../../components/PageContainer";
import { LoadingOverlay } from "../../components/Loading";
import { toast } from "react-toastify";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Link, LinkPosition, LinkType } from "../../__generated__/graphql";
import { LinkTypePicker } from "../../components/LinkTypePicker";
import { useExportMiscAppConfig } from "./helpers";
import { UpdateMiscConfigButton } from "./updateMiscConfig";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LinkPositionPicker } from "../../components/LinkPositionPicker";

const schema = yup
  .object({
    links: yup
      .array()
      .of(
        yup.object({
          id: yup.string().nullable(),
          linkId: yup.string().nullable(),
          title: yup.string().required(),
          url: yup
            .string()
            .matches(
              // https://stackoverflow.com/questions/61634973/yup-validation-of-website-using-url-very-strict
              /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
              "Url requires a full valid URL including https://www."
            )
            .required("Please enter website"),
          linkType: yup
            .mixed<LinkType>()
            .oneOf(Object.values(LinkType))
            .required(),
          position: yup
            .mixed<LinkPosition>()
            .oneOf(Object.values(LinkPosition))
            .required(),
          order: yup.number().required(),
          disabled: yup.boolean().required(),
        })
      )
      .required(),
  })
  .required();

const Links = () => {
  const { data, loading, refetch } = useQuery(GET_LINKS);

  if (!data?.links) return <div>Loading</div>;

  const sortedLinks = [...data?.links].sort(sortLinks);

  return <LinksForm links={sortedLinks} refetch={refetch} />;
};

const LinksForm = ({
  links,
  refetch,
}: {
  links: Link[];
  refetch: () => void;
}) => {
  // const { data, loading, refetch } = useQuery(GET_links);
  useEffect(() => {
    // @ts-ignore
    replace(links);
  }, [links]);

  const [updatelinks] = useMutation(UPDATE_LINKS, {
    onCompleted: () => {
      refetch();
      toast("Update complete");
    },
  });

  const [exportMiscAppConfig, { loading }] = useExportMiscAppConfig();

  const [deleteLink] = useMutation(DELETE_LINK, {
    refetchQueries: [GET_LINKS],
    awaitRefetchQueries: true,
    onCompleted: () => {
      refetch();
      toast("Update complete");
    },
  });

  const onSubmit = (val: { links: Link[] }) => {
    const updatedLinks = val.links;
    updatelinks({
      variables: {
        links: updatedLinks.map((item: Link) => {
          // @ts-ignore
          const { __typename, linkId, linkType, order, position, ...rest } =
            item;

          return {
            ...rest,
            id: linkId,
            linkType: linkType,
            // @ts-ignore
            order: parseInt(order ?? "0"),
            position,
          };
        }),
      },
    });
  };
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    // @ts-ignore
    defaultValues: { links: links?.sort((a, b) => a.order - b.order) ?? [] },
  });
  const { fields, replace, prepend, remove, swap, move, insert } =
    useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "links", // unique name for your Field Array
    });

  return (
    // @ts-ignore
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageContainer title="Menu Links">
        <LoadingOverlay loading={false}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="submit"
              variant="contained"
              style={{ marginTop: 18, marginBottom: 18, marginRight: 8 }}
            >
              Save
            </Button>
            <Button
              onClick={() =>
                // @ts-ignore
                prepend({
                  id: "",
                  // @ts-ignore
                  linkId: "",
                  title: "",
                  disabled: false,
                  linkType: LinkType.External,
                  url: "",
                } as Link)
              }
              color="success"
              variant="contained"
              style={{ marginTop: 18, marginBottom: 18 }}
            >
              Add New
            </Button>

            <UpdateMiscConfigButton />
          </div>

          <TableContainer component={Paper} style={{ overflow: "visible" }}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              style={{ overflow: "visible" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Title</TableCell>
                  <TableCell align="left">url</TableCell>
                  <TableCell align="left">type</TableCell>
                  <TableCell align="left">position</TableCell>
                  <TableCell align="left">order</TableCell>
                  <TableCell align="left">disabled</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    style={{ overflow: "visible" }}
                  >
                    <input
                      type="hidden"
                      defaultValue={row.linkId ?? ""}
                      {...register(`links.${index}.id`)}
                    />
                    <input
                      type="hidden"
                      defaultValue={row.linkId ?? ""}
                      {...register(`links.${index}.linkId`)}
                    />
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="title"
                        variant="outlined"
                        defaultValue={row.title}
                        error={!!errors.links?.[index]?.title?.message}
                        {...register(`links.${index}.title`)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        multiline={false}
                        label="url"
                        variant="outlined"
                        defaultValue={row.url}
                        error={!!errors.links?.[index]?.url?.message}
                        {...register(`links.${index}.url`)}
                      />
                    </TableCell>
                    <TableCell align="left" style={{ overflow: "visible" }}>
                      <Controller
                        control={control}
                        {...register(`links.${index}.linkType`)}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <LinkTypePicker
                            controlStyle={{
                              // standard for other controls
                              marginBottom: 20,
                            }}
                            // @ts-ignore
                            handleChange={onChange}
                            defaultValue={value}
                            value={value}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell align="left" style={{ overflow: "visible" }}>
                      <Controller
                        control={control}
                        {...register(`links.${index}.position`)}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <LinkPositionPicker
                            controlStyle={{
                              // standard for other controls
                              marginBottom: 20,
                            }}
                            // @ts-ignore
                            handleChange={onChange}
                            defaultValue={value}
                            value={value}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        multiline={false}
                        label="order"
                        type="number"
                        variant="outlined"
                        defaultValue={index + 1}
                        error={!!errors.links?.[index]?.order?.message}
                        {...register(`links.${index}.order`)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Checkbox
                        id="outlined-basic"
                        style={{
                          // standard for other controls
                          marginBottom: 20,
                        }}
                        defaultChecked={row.disabled}
                        // error={!!errors.links?.[index]?.disabled?.message}
                        {...register(`links.${index}.disabled`)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        onClick={() => {
                          // @ts-ignore
                          if (row.linkId === "") {
                            remove(index);
                          } else {
                            // @ts-ignore
                            deleteLink({ variables: { linkId: row.linkId } });
                          }
                        }}
                        color="error"
                        variant="contained"
                        style={{
                          // marginTop: 18,
                          marginBottom: 20,
                          // marginRight: 8,
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </LoadingOverlay>
        {/* @ts-ignore */}
        {!!errors && errors?.links?.length > 0 && (
          <>
            <h5>Please fix the following errors:</h5>
            {/* @ts-ignore */}
            {errors?.links?.map((item) => {
              if (!item) return;

              return Object.keys(item).map((key) => {
                // @ts-ignore
                const message = item?.[key].message ?? "";
                const info = message.split(".")[1];
                // @ts-ignore
                return <p>{info || message}</p>;
              });
            })}
          </>
        )}
      </PageContainer>
    </form>
  );
};

export const sortLinks = (a: Link, b: Link) =>
  a.title.toLowerCase().localeCompare(b.title.toLowerCase());

export default Links;
