import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Checkbox,
} from "@mui/material";
import { useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { Info, Link, LinkType } from "../../__generated__/graphql";
import { LoadingOverlay } from "../../components/Loading";
import { PageContainer } from "../../components/PageContainer";
import { CREATE_INFO, DELETE_INFO, GET_INFOS, UPDATE_INFO } from "./graphql";
import * as yup from "yup";
import { UpdateMiscConfigButton } from "../links/updateMiscConfig";
import { useNavigate } from "react-router-dom";

const schema = yup
  .object({
    infos: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          infoId: yup.string().required(),
          title: yup.string().required(),
          order: yup.number().required(),
          hidden: yup.boolean().required(),
          value: yup.object({
            infoType: yup.string().required(),
            content: yup.string().required(),
          }),
        })
      )
      .required(),
  })
  .required();

export const InfoList = ({
  infos,
  refetch,
}: {
  infos: Info[];
  refetch: () => void;
}) => {
  // const { data, loading, refetch } = useQuery(GET_links);
  useEffect(() => {
    // @ts-ignore
    replace(infos);
  }, [infos]);

  const [createInfo] = useMutation(CREATE_INFO, {
    onCompleted: () => {
      refetch();
      toast("Create complete");
    },
  });

  const [deleteInfo] = useMutation(DELETE_INFO, {
    refetchQueries: [GET_INFOS],
    awaitRefetchQueries: true,
    onCompleted: () => {
      refetch();
      toast("Delete complete");
    },
  });

  const onSubmit = (val: { infos: Info[] }) => {
    // @ts-ignore
    const sortedLinks = val.infos.sort((a, b) => a.order - b.order);
    // updatelinks({
    //   variables: {
    //     links: sortedLinks.map((item: Link) => {
    //       // @ts-ignore
    //       const { __typename, linkId, linkType, order, ...rest } = item;

    //       return {
    //         ...rest,
    //         id: linkId,
    //         linkType: linkType,
    //         // @ts-ignore
    //         order: parseInt(order ?? "0"),
    //       };
    //     }),
    //   },
    // });
  };
  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    // @ts-ignore
    defaultValues: { infos: infos ?? [] },
  });
  const { fields, replace, append, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "infos", // unique name for your Field Array
    }
  );
  const navigate = useNavigate();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageContainer title="Info">
        <LoadingOverlay loading={false}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              onClick={() => {
                const newInfo: Info = {
                  id: "",
                  title: "",
                  order: 0,
                  hidden: false,
                  value: {
                    infoType: "text",
                    content: "",
                  },
                };
                // @ts-ignore
                append(newInfo);
              }}
              color="success"
              variant="contained"
              style={{ marginTop: 18, marginBottom: 18 }}
            >
              Add New Section
            </Button>

            <UpdateMiscConfigButton />
          </div>

          <TableContainer component={Paper} style={{ overflow: "visible" }}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              style={{ overflow: "visible" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Section title</TableCell>
                  <TableCell align="left">order</TableCell>
                  <TableCell align="left">hidden</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((row, index) => {
                  const hasId = !(row.infoId === "" || !row.infoId);
                  return (
                    <TableRow
                      key={row.infoId}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      style={{ overflow: "visible" }}
                    >
                      <input
                        type="hidden"
                        defaultValue={row.infoId}
                        {...register(`infos.${index}.id`)}
                      />
                      <TableCell align="left">
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="title"
                          variant="outlined"
                          defaultValue={row.title}
                          error={!!errors.infos?.[index]?.title?.message}
                          {...register(`infos.${index}.title`)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          multiline={false}
                          label="order"
                          disabled
                          type="number"
                          variant="outlined"
                          defaultValue={index + 1}
                          error={!!errors.infos?.[index]?.order?.message}
                          {...register(`infos.${index}.order`)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="hidden"
                          variant="outlined"
                          disabled
                          error={!!errors.infos?.[index]?.hidden?.message}
                          {...register(`infos.${index}.hidden`)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {hasId && (
                          <Button
                            onClick={() =>
                              navigate(`/information/edit/${row.infoId}`)
                            }
                            color="info"
                            variant="contained"
                            style={{
                              // marginTop: 18,
                              marginBottom: 20,
                              // marginRight: 8,
                            }}
                          >
                            Edit
                          </Button>
                        )}
                        {!hasId && (
                          <Button
                            onClick={() => {
                              const title = getValues(`infos.${index}.title`);
                              createInfo({
                                variables: {
                                  title,
                                  value: {
                                    items: [
                                      {
                                        infoType: "text",
                                        content: "",
                                      },
                                    ],
                                  },
                                },
                              });
                            }}
                            color="success"
                            variant="contained"
                            style={{
                              // marginTop: 18,
                              marginBottom: 20,
                              // marginRight: 8,
                            }}
                          >
                            Save
                          </Button>
                        )}
                        <Button
                          onClick={() => {
                            if (!hasId) {
                              remove(index);
                            } else {
                              console.log("row", row.infoId);
                              // @ts-ignore
                              deleteInfo({
                                variables: { infoId: row.infoId },
                              });
                            }
                          }}
                          color="error"
                          variant="contained"
                          style={{
                            // marginTop: 18,
                            marginBottom: 20,
                            // marginRight: 8,
                          }}
                        >
                          delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </LoadingOverlay>
        {/* @ts-ignore */}
        {!!errors && errors?.links?.length > 0 && (
          <>
            <h5>Please fix the following errors:</h5>
            {/* @ts-ignore */}
            {errors?.links?.map((item) => {
              if (!item) return;

              return Object.keys(item).map((key) => {
                // @ts-ignore
                const message = item?.[key].message ?? "";
                const info = message.split(".")[1];
                // @ts-ignore
                return <p>{info || message}</p>;
              });
            })}
          </>
        )}
      </PageContainer>
    </form>
  );
};
