import { useMutation, useQuery } from "@apollo/client";
import { EXPORT_MISC_APP_CONFIG, GET_SHOWCASE_BANDS, REMOVE_ALL_SHOWCASES } from "./graphql";
import {
  Box,
  Button,
} from "@mui/material";
import { PageContainer } from "../../components/PageContainer";
import { LoadingOverlay } from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import { Note } from "../../components/Note";
import { BandPicker } from "../../components/BandPicker";
import { UPDATE_BAND } from "../band/graphql";
import { toast } from "react-toastify";

const ShowcaseBands = () => {
  const navigate = useNavigate();
  const { data, loading, refetch } = useQuery(GET_SHOWCASE_BANDS);
  const [exportShowcases, { loading: updateLoading }] = useMutation(
    EXPORT_MISC_APP_CONFIG,
    {
      onCompleted: (data) => {
        console.log("data", data);
        toast("Showcase section updated");
      },
      onError: () => {
        toast("Error exporting showcase");
      },
    }
  );
  const [removeAllShowcases] = useMutation(
    REMOVE_ALL_SHOWCASES,
    {
      onCompleted: (data) => {
        
        toast("Showcase section updated");
        refetch()
        exportShowcases()
      },
      onError: () => {
        toast("Error removing showcases");
      },
    }
  );

  const [updateBand] = useMutation(UPDATE_BAND, {
    onCompleted: () => {
      refetch();
      toast("Update complete");
    },
  });

  const onAdd = (id: string) => {
    updateBand({
      variables: {
        id: id,
        showcase: true,
      },
    });
  };

  const onRemove = (id: string) => {
    updateBand({
      variables: {
        id: id,
        showcase: false,
      },
    });
  };

  return (
    <PageContainer title="Showcase Bands">
      <Box
        style={{ flex: 1, justifyContent: "space-between", display: "flex" }}
      >
        <BandPicker
          controlStyle={{ width: "50%" }}
          placeholder="Add a band"
          handleChange={(id) => {
            onAdd(id);
          }}
          value={undefined}
        />
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            removeAllShowcases();
          }}
        >
          Remove all & Update
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            exportShowcases();
          }}
        >
          Update Showcase section
        </Button>
      </Box>
      <Note
        message={`Note: these bands will feature prominently within the app, and can be used to draw attention to next years announcements while the app still contains the old lineup.`}
        style={{ marginBottom: 20 }}
      />
      <LoadingOverlay loading={loading}></LoadingOverlay>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gridTemplateRows: "1fr",
          width: "100%",
          rowGap: 20,
        }}
      >
        {data?.bands.map((item) => {
          return (
            <div
              style={{
                position: "relative",
              }}
            >
              <h5
                style={{
                  color: "red",
                  textShadow: "white",
                  fontSize: 18,
                }}
              >
                {!item.description ? "⚠️ Description missing": '✅'}
              </h5>
              <img
                src={item.imageUrl ?? ""}
                width={400}
                height={400}
                onClick={() => navigate(`/band/${item.id}`)}
                style={{ cursor: "pointer", objectFit: "cover" }}
              />

              <Button
                style={{
                  position: "absolute",
                  bottom: 6,
                  left: 0,
                  zIndex: 99,
                  minWidth: 150,
                }}
                color="error"
                variant="contained"
                onClick={() => {
                  onRemove(item.id);
                }}
              >
                <h4>Remove {item.name}</h4>
              </Button>
            </div>
          );
        })}
      </div>
    </PageContainer>
  );
};

export default ShowcaseBands;
