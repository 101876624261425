import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { GET_VENDOR } from "../../graphql";
import VendorForm from './form'
import { FoodVendor } from "../../../../__generated__/graphql";

const Vendor = () => {
  const [getVendor, { data, loading }] = useLazyQuery(GET_VENDOR)
  const { id } = useParams();
  useEffect(() => {
    if (!id) return;
    getVendor({ variables: { id: id } });
  }, [id]);

  if (loading) return null;
  // aspect ratio 3∶2
  if (!data?.foodVendor) return <div>Vendor not found</div>;

  return <VendorForm vendor={data?.foodVendor as unknown as FoodVendor} />;
};

export default Vendor;
