import { Container, TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Post } from "../../../__generated__/graphql";
import { DELETE_POST, EXPORT_POST, UPDATE_POST } from "../graphql";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { ButtonContainer } from "../../../components/ButtonContainer";
import { PageContainer } from "../../../components/PageContainer";
import { Note } from "../../../components/Note";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SocialLinks } from "../SocialLinks";

const schema = yup
  .object({
    title: yup.string().required(),
    description: yup.string().required(),
    date: yup.string().required(),
    image: yup.string(),
    socials: yup.object({
      facebook: yup.string().url().optional(),
      tiktok: yup.string().url().optional(),
      twitter: yup.string().url().optional(),
      instagram: yup.string().url().optional(),
    }),
  })
  .required();
interface IPostFormProps {
  post: Post;
}

export const PostForm = ({ post }: IPostFormProps) => {
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState(post.image ?? "");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { date: post.date, socials: post.socials },
  });
  const location = useLocation();
  const [deletePost] = useMutation(DELETE_POST, {
    onCompleted: () => {
      toast("post deleted");
      navigate("/news");
    },
  });
  const [updatePost, { loading: updateLoading, error }] = useMutation(
    UPDATE_POST,
    {
      onCompleted: () => {
        toast("Update complete");
      },
    }
  );
  const [exportPost, { loading: exportLoading, error: exportError }] =
    useMutation(EXPORT_POST, {
      onCompleted: () => {
        toast("publish complete");
      },
    });

  useEffect(() => {
    console.log("STATE HAS CHANGED!", location.state);
    if (location?.state?.updatedImageUrl) {
      console.log("updating image!");
      setImageUrl(location?.state?.updatedImageUrl);
      setValue("image", location?.state?.updatedImageUrl);
    }
  }, [location.state]);

  // const imageUrl =  ?? ;
  const onSubmit = (data: Partial<Post>) => {
    const { title, description, date, image } = data;
    updatePost({
      variables: {
        id: post.id,
        title: title!,
        description: description!,
        date,
        image: imageUrl,
        socials: data.socials,
      },
    });
  };
  const handleExport = () => {
    exportPost({
      variables: {
        postId: post.id,
      },
    });
  };
  const handleDelete = () => {
    confirmAlert({
      title: "Delete this Post?",
      message: "Are you sure? this can't be undone",
      buttons: [
        {
          label: "Yes",
          onClick: () => deletePost({ variables: { postId: post.id } }),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const imageKey = location?.state?.updated ?? "imageUrl";
  return (
    <PageContainer title={"Edit Post"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container style={{ flexDirection: "column", display: "flex" }}>
          <img
            src={imageUrl}
            width={500}
            style={{ paddingBottom: 8 }}
            key={imageKey}
          />
          <p>{errors.image?.message}</p>
          <Button
            onClick={() => {
              navigate("/image", {
                state: {
                  postId: post.id,
                },
              });
            }}
          >
            Update Image
          </Button>
          <TextField
            // style={{margin:20}}
            id="outlined-basic"
            label="Name"
            variant="outlined"
            defaultValue={post?.title}
            {...register("title")}
          />
          <p>{errors.title?.message}</p>

          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DateTimePicker
                format="YYYY/MM/DD HH:mm"
                ampm={false}
                timezone="Europe/London"
                label="Publish Date"
                // @ts-ignore
                defaultValue={dayjs(post.date)}
                // @ts-ignore
                onChange={onChange} // send value to hook form
              />
            )}
          />
          <p>{errors.date?.message}</p>
          <Note
            message={`Note: you can schedule news posts by picking a date in the future - just publish the item as normal - the app won't load the news until the scheduled date and TIME`}
            style={{ marginTop: -40, marginBottom: 20 }}
          />
          <TextField
            id="outlined-basic"
            label="Description"
            variant="outlined"
            multiline={true}
            defaultValue={post?.description}
            {...register("description")}
          />
          <p>{errors.description?.message}</p>
          {/* <p>{post.socials ?? "no socials"}</p> */}
          <Controller
            control={control}
            name="socials"
            render={({ field: { onChange, onBlur, value, ref } }) => (              
              <SocialLinks
                data={value}
                // @ts-ignore
                onChange={onChange}
                errors={errors.socials}
              />
            )}
          />
          {JSON.stringify(errors, null, 2).replace("{", "").replace("}", "")}

          <ButtonContainer>
            <Button type="submit" variant="contained">
              Save
            </Button>
            <Button variant="contained" onClick={handleExport}>
              Publish post
            </Button>
            <Button
              variant="contained"
              onClick={handleDelete}
              style={{ backgroundColor: "red" }}
            >
              Delete post
            </Button>
          </ButtonContainer>
        </Container>
      </form>
      <Note message={"Changes won't be seen until you publish"} />
    </PageContainer>
  );
};
