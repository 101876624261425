import { useMutation, useQuery } from "@apollo/client";
import { DELETE_DRINK, GET_DRINKS, UPDATE_DRINKS } from "./graphql";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { LinkButton } from "../../components/LinkButton";
import { PageContainer } from "../../components/PageContainer";
import { LoadingOverlay } from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import { UPDATE_BAND } from "../band/graphql";
import { toast } from "react-toastify";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Drink } from "../../__generated__/graphql";
import { UpdateMiscConfigButton } from "../links/updateMiscConfig";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorField } from "../../components/ErrorField";
import { DrinkTypePicker } from "./DrinkPicker";

const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

const schema = yup
  .object({
    drinks: yup
      .array()
      .of(
        yup.object({
          id: yup.string().nullable(),
          drinkId: yup.string().nullable(),
          name: yup.string().max(50).required(),
          description: yup.string().max(500).required(),
          type: yup.string().required().max(50),
          abv: yup
            .number()
            .moreThan(-0.1)
            .test(
              "is-decimal",
              "The amount should be a decimal with maximum two digits after comma",
              (val: any) => {
                if (val != undefined) {
                  return patternTwoDigisAfterComma.test(val);
                }
                return true;
              }
            )
            .required(),
          manufacturer: yup.string().max(50).required(),
        })
      )
      .required(),
  })
  .required();

const Drinks = () => {
  const { data, loading, refetch } = useQuery(GET_DRINKS);

  if (!data?.drinks) return <div>Loading</div>;

  return <DrinksForm drinks={data?.drinks} refetch={refetch} />;
};

const DrinksForm = ({
  drinks,
  refetch,
}: {
  drinks: Drink[];
  refetch: () => void;
}) => {
  useEffect(() => {
    // @ts-ignore
    replace(drinks);
  }, [drinks]);

  const [updateDrinks] = useMutation(UPDATE_DRINKS, {
    onCompleted: () => {
      refetch();
      toast("Update complete");
    },
  });
  const [deleteDrink] = useMutation(DELETE_DRINK, {
    refetchQueries: [GET_DRINKS],
    awaitRefetchQueries: true,
    onCompleted: () => {
      refetch();
      toast("Update complete");
    },
  });
  const onSubmit = (val: any) => {
    updateDrinks({
      variables: {
        drinks: val.drinks.map((item: Drink) => {
          // @ts-ignore
          const { __typename, drinkId, ...rest } = item;
          return {
            ...rest,
            id: drinkId,
            // @ts-ignore
            abv: parseFloat(item.abv),
          };
        }),
      },
    });
  };
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { drinks: drinks ?? [] },
  });
  const { fields, replace, prepend, remove, swap, move, insert } =
    useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "drinks", // unique name for your Field Array
    });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageContainer title="VIP Bar">
        {/* <TextField
          id="outlined-basic"
          label="Search"
          disabled
          variant="outlined"
          placeholder="Search..."
          style={{ width: "40%" }}
          // onChange={(value) => setSearch(value.target.value)}
        /> */}
        {/* <LinkButton
        path="/band/create"
        text="Create new band"
        variant={"contained"}
        style={{ marginLeft: 30 }}
      />      */}
        <LoadingOverlay loading={false}>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              minHeight: "100%",
              alignItems: "center",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              style={{ marginTop: 18, marginBottom: 18, marginRight: 8 }}
            >
              Save
            </Button>
            <Button
              onClick={() =>
                prepend({
                  id: "",
                  // @ts-ignore
                  drinkId: "",
                  name: "",
                  abv: 0,
                  description: "",
                  manufacturer: "",
                  type: "Beer",
                })
              }
              color="success"
              variant="contained"
              style={{ marginTop: 18, marginBottom: 18 }}
            >
              Add New
            </Button>
            <UpdateMiscConfigButton />
          </div>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">name</TableCell>
                  <TableCell align="left">description</TableCell>
                  <TableCell align="left">abv</TableCell>
                  <TableCell align="left">manufacturer</TableCell>
                  <TableCell align="left">type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <input
                      type="hidden"
                      defaultValue={row.id}
                      {...register(`drinks.${index}.id`)}
                    />
                    <TableCell align="left">
                      <TextField
                        id="outlined-basic"
                        label="Name"
                        variant="outlined"
                        defaultValue={row.name}
                        error={!!errors.drinks?.[index]?.name?.message}
                        {...register(`drinks.${index}.name`)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        id="outlined-basic"
                        multiline={true}
                        label="Description"
                        variant="outlined"
                        defaultValue={row.name}
                        error={!!errors.drinks?.[index]?.description?.message}
                        {...register(`drinks.${index}.description`)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        id="outlined-basic"
                        label="Abv"
                        variant="outlined"
                        defaultValue={row.abv}
                        error={!!errors.drinks?.[index]?.abv?.message}
                        {...register(`drinks.${index}.abv`)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        id="outlined-basic"
                        label="manufacturer"
                        variant="outlined"
                        defaultValue={row.manufacturer}
                        error={!!errors.drinks?.[index]?.manufacturer?.message}
                        {...register(`drinks.${index}.manufacturer`)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {/* <TextField
                        // @ts-ignore
                        error={!!errors.drinks?.[index]?.type?.message}
                        id="outlined-basic"
                        label="Type"
                        variant="outlined"
                        defaultValue={row.type}
                        {...register(`drinks.${index}.type`)}
                      /> */}
                      <Controller
                          control={control}
                          {...register(`drinks.${index}.type`)}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <DrinkTypePicker
                              controlStyle={{
                                // standard for other controls
                                marginBottom: 20,
                              }}
                              // @ts-ignore
                              handleChange={onChange}
                              defaultValue={value}
                              value={value}
                            />
                          )}
                        />

                      {/* <Controller
                        control={control}
                        name={`drinks.${index}.type`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <>
                            <DrinkTypePicker
                              {...register(`drinks.${index}.type`)}
                              handleChange={onChange}
                              defaultValue={value}
                              value={value}
                            />
                          </>
                        )}
                      /> */}
                      <ErrorField additionalStyle={{ marginTop: -6 }}>
                        {/* @ts-ignore */}
                        {errors.drinks?.[index]?.type?.message}
                      </ErrorField>
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        onClick={() => {
                          // @ts-ignore
                          if (row.drinkId === "" || !row.drinkId) {
                            remove(index);
                          } else {
                            // @ts-ignore
                            deleteDrink({
                              variables: { drinkId: row.drinkId },
                            });
                          }
                        }}
                        color="error"
                        variant="contained"
                        style={{
                          marginTop: 18,
                          marginBottom: 18,
                          marginRight: 8,
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* @ts-ignore */}
          {!!errors && errors?.drinks?.length > 0 && (
            <>
              <h5>Please fix the following errors:</h5>
              {/* @ts-ignore */}
              {errors?.drinks?.map((item) => {
                if (!item) return;

                return Object.keys(item).map((key) => {
                  // @ts-ignore
                  const message = item?.[key].message ?? "";
                  const info = message.split(".")[1];
                  // @ts-ignore
                  return <p>{info || message}</p>;
                });
              })}
            </>
          )}
        </LoadingOverlay>
      </PageContainer>
    </form>
  );
};

export default Drinks;
