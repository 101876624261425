import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_EDITIONS } from "../../routes/editions/graphql";
interface IStagePickerProps {
  handleChange: (editionId: string) => void;
  defaultValue?: string;
  value: string | undefined;
}

export const EditionPicker = ({
  handleChange,
  defaultValue,
  value,
}: IStagePickerProps) => {
  const { data, error, loading } = useQuery(GET_EDITIONS);

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Edition</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label="Edition"
        defaultValue={defaultValue}
        onChange={(event) => handleChange(event.target.value)}
      >
        {data?.festivalEditions.map((edition) => {
          return <MenuItem key={edition.id} value={edition.id}>{edition.name}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};
