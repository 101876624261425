import { Container, TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Sponsor } from "../../__generated__/graphql";
import { PageContainer } from "../../components/PageContainer";
import { ButtonContainer } from "../../components/ButtonContainer";
import { Note } from "../../components/Note";
import { UPDATE_SPONSOR } from "./graphql";
import { useState } from "react";

const schema = yup
  .object({
    name: yup.string().required(),
    order: yup.number().required(),
    url: yup.string().required(),
    imageUrl: yup.string().required(),
    imageWidth: yup
      .string()
      .required()
      .test(
        "is-percentage",
        ({ value }) => `${value} must be a percentage between 1-100`, // a message can also be a function
        async (value, testContext) => {
          const isPercentage = value.includes("%");

          const number = parseInt(value.replace("%", ""));
          return !!isPercentage && number > 0 && number <= 100;
        }
      ),
  })
  .required();

interface IPostFormProps {
  sponsor: Sponsor;
}
export const SponsorForm = ({ sponsor }: IPostFormProps) => {
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState(sponsor.value.imageUrl);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: sponsor.name,
      order: sponsor.order!,
      url: sponsor.value["url"],
      imageUrl: sponsor.value["imageUrl"],
      imageWidth: sponsor.value["imageWidth"] ?? "70%",
    },
  });
  const [updateSponsor, { loading: updateLoading, error }] = useMutation(
    UPDATE_SPONSOR,
    {
      onCompleted: () => {
        toast("Update complete");
        navigate("/sponsors");
      },
    }
  );

  const onSubmit = (data: unknown) => {
    // @ts-ignore
    const { name, order, imageUrl, url, imageWidth } = data;
    updateSponsor({
      variables: {
        id: sponsor.id,
        name: name!,
        order: order!,
        value: {
          imageUrl,
          url,
          imageWidth,
        },
      },
    });
  };

  return (
    <PageContainer title={"Edit Sponsor"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container style={{ flexDirection: "column", display: "flex" }}>
          <TextField
            // style={{margin:20}}
            id="outlined-basic"
            label="Name"
            variant="outlined"
            defaultValue={sponsor?.name}
            {...register("name")}
          />
          <p>{errors.name?.message}</p>
          <TextField
            id="outlined-basic"
            type="number"
            label="Order"
            variant="outlined"
            defaultValue={sponsor?.order}
            {...register("order")}
          />
          <p>{errors.order?.message}</p>

          <TextField
            id="outlined-basic"
            label="Website Url"
            variant="outlined"
            defaultValue={sponsor?.value["url"]}
            {...register("url")}
          />
          {/* @ts-ignore */}
          <p>{errors.url?.message}</p>

          <img
            src={imageUrl}
            width={500}
            style={{
              paddingBottom: 8,
              marginBottom: 8,
              backgroundColor: "grey",
            }}
          />
          <p>Grey background is added to preview image only</p>

          <TextField
            id="outlined-basic"
            label="Logo Image"
            variant="outlined"
            defaultValue={sponsor.value?.imageUrl}
            {...register("imageUrl")}
            onChange={(value) => {
              setImageUrl(value.target.value);
            }}
          />
          {/* @ts-ignore */}

          <p>{errors.imageUrl?.message}</p>
          <TextField
            id="outlined-basic"
            label="Image scaling value"
            variant="outlined"
            defaultValue={sponsor.value?.imageWidth}
            {...register("imageWidth")}
          />
          {/* @ts-ignore */}
          <p>{errors.imageWidth?.message}</p>

          <ButtonContainer>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </ButtonContainer>
        </Container>
      </form>
      <Note message={"Changes won't be seen until you publish"} />
    </PageContainer>
  );
};
