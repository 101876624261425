import React, { useState } from "react";
import { Select } from "./Select";
import CropImage from "./Crop";
import Upload from "./Upload";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const ImageEditor = () => {
  const [file, setFile] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const [croppedFile, setCroppedFile] = useState<string>();
  const location = useLocation();
  const handleSelectFile = (file: string, fileName: string) => {
    setFile(file);
    const state = location.state;

    let id = "";
    let fileNamePrefix = "";
    if (state.bandId) {
      id = state.bandId;
    }

    if (state.postId) {
      id = state.postId;
    }

    if (state.vendorId) {
      id = state.postId;
      fileNamePrefix = "vendors/";
    }

    if (id === "") {
      toast.error("Something went wrong, id not found");
      return;
    }

    setFileName(`${fileNamePrefix}${id}_${fileName}`);
  };
  const handleCroppedFile = (file: string) => {
    setCroppedFile(file);
  };
  const navigate = useNavigate();

  const returnAction = (imageUrl: string) => {
    const state = location.state;
    let returnRoute = "";

    if (state.postId) {
      returnRoute = `/post/${state.postId}`;
    }
    if (state.bandId) {
      returnRoute = `/band/${state.bandId}`;
    }
    if (state.vendorId) {
      returnRoute = `/vendor/${state.vendorId}`;
    }

    navigate(returnRoute, {
      state: {
        updatedImageUrl: imageUrl,
        updated: Date.now(),
      },
    });
  };

  if (!file) {
    return <Select onCompletePress={handleSelectFile} />;
  }
  if (!croppedFile)
    return <CropImage file={file} handleCroppedFile={handleCroppedFile} />;

  return (
    <Upload
      file={croppedFile}
      returnAction={returnAction}
      fileName={fileName!}
    />
  );
};
