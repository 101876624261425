import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { PostForm } from "./form";
import { Post as PostType } from "../../../__generated__/graphql";
import { GET_POST } from "../graphql";

const Post = () => {
  const [getBand, { data, error, loading }] = useLazyQuery(GET_POST);
  const { id } = useParams();

  useEffect(() => {
    console.log(id);
    if (!id) return;

    getBand({ variables: { id: id } });
  }, [id]);

  if (loading || !data) return null;
  
  if(!data.post) return<div>post not found</div>

  return <PostForm post={data?.post as PostType} />;
};

export default Post;
