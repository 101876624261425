import { Input, Button, TextField, Select, MenuItem } from "@mui/material";
import React, { useCallback, useState } from "react";
import { PageContainer } from "../../components/PageContainer";
import { EditImageContainer } from "../band/imageEdit/Select";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";

const ImageSelection = () => {
  const [maxWidth, setMaxWidth] = useState(2000);
  const [maxHeight, setMaxHeight] = useState(2000);
  const [format, setFormat] = useState("default");
  const onDrop = useCallback(
    async (acceptedFiles: any[]) => {
      // Do something with the files
      console.log("acceptedFiles", acceptedFiles);
      processFile(acceptedFiles[0]);
    },
    [maxHeight, maxHeight, format]
  );

  const processFile = async (file: any) => {
    const [filename, actualExtension] = (file.path as string).split(".");
    const extension = format === "default" ? actualExtension : format;

    const obj = URL.createObjectURL(file);
    const fileBlob = await fetch(obj).then((r) => r.blob());
    const image = (await resizeFile(fileBlob, extension)) as string;

    const reblobbed = await fetch(image).then((r) => r.blob());
    const objUrl = URL.createObjectURL(reblobbed);

    const link = document.createElement("a");
    link.href = objUrl;
    link.setAttribute("download", `${filename}-compressed.${extension}`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const resizeFile = (file: any, extension: string) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxWidth, // maxWidth
        maxHeight, // maxHeight
        extension.toUpperCase() ?? "JPEG",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
        // 1500, // minWidth?
        // 1500 // minHeight
      );
    });
  const handleClick = async () => {
    try {
      //   const fileBlob = await fetch(file).then((r) => r.blob());
      //   const image = (await resizeFile(fileBlob)) as string;
    } catch (err) {
      console.log(err);
    }
  };
  const handleDownload = () => {
    // if (blobUrlRef.current) {
    //     URL.revokeObjectURL(blobUrlRef.current);
    //   }
    //   blobUrlRef.current = URL.createObjectURL(blob);
    //   handleCroppedFile(blobUrlRef.current);
    //   hiddenAnchorRef.current!.href = blobUrlRef.current;
    //   hiddenAnchorRef.current!.click();
  };
  //   const handleSave = async () => {
  //     // const url = `${process.env.REACT_APP_BUCKET_URL}/${fileName}`;

  //     // const res = await getImageUrl({ variables: { image: fileName } });
  //     // if (!res.data?.addImage || !resizedFile) return;

  //     // const fileBlob = await fetch(resizedFile).then((r) => r.blob());
  //   };
  return (
    <PageContainer title="Image Compressor">
      <EditImageContainer>
        <div style={{ flex: 1 }}>
          <div style={{ flexDirection: "row" }}>
            <TextField
              key={"maxWidth"}
              id="outlined-basic"
              label="Max Width"
              variant="outlined"
              type="number"
              defaultValue={2000}
              onChange={(value) => setMaxWidth(parseInt(value.target.value))}
            />
            <TextField
              key={"maxHeight"}
              id="outlined-basic"
              label="Max Height"
              variant="outlined"
              type="number"
              defaultValue={2000}
              onChange={(value) => {
                console.log("value", value.target.value);
                setMaxHeight(parseInt(value.target.value));
              }}
            />
            <Select
              labelId="file format"
              id="simple-select"
              value={format}
              label="Stage"
              defaultValue={"default"}
              onChange={(event) => setFormat(event.target.value)}
            >
              {["default", "jpeg", "png"].map((format) => {
                return <MenuItem value={format}>{format}</MenuItem>;
              })}
            </Select>
          </div>
          <div
            {...getRootProps()}
            style={{
              border: "1px solid black",
              padding: 8,
              flex: 1,
              height: 300,
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>Drag 'n' drop some files here, or click to select files</p>
            )}
          </div>
        </div>
      </EditImageContainer>
    </PageContainer>
  );
};

export default ImageSelection;
