import { useQuery } from "@apollo/client";
import { GET_BANDS } from "./graphql";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import React from "react";
import { LinkButton } from "../../components/LinkButton";
import { PageContainer } from "../../components/PageContainer";
import { LoadingOverlay } from "../../components/Loading";
import { QueryMode } from "../../__generated__/graphql";
const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Name", width: 130 },
  {
    field: "bookings",
    headerName: "Bookings",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.bookings.length}`,
  },
];

const NO_BOOKING_FILTER = { bookings: { none: { id: { not: null } } } };

const Bands = () => {
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [onlyBandsWithoutBookings, setOnlyBandsWithoutBookings] =
    React.useState(false);

  const bandsFilter = !!onlyBandsWithoutBookings
    ? NO_BOOKING_FILTER
    : undefined;

  const { data, error, loading } = useQuery(GET_BANDS, {
    variables: {
      skip: (page - 1) * 50,
      take: 50,
      where:
        search.length > 0
          ? {
              name: {
                contains: search,
                mode: QueryMode.Insensitive,
              },
              ...bandsFilter,
            }
          : bandsFilter,
    },
  });
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  // if (loading) return null;

  return (
    <PageContainer title="Bands">
      <TextField
        id="outlined-basic"
        label="Search"
        variant="outlined"
        placeholder="Search..."
        style={{ width: "40%" }}
        onChange={(value) => setSearch(value.target.value)}
      />
      <FormControlLabel
        style={{ margin: 8 }}
        control={
          <Checkbox
            value={onlyBandsWithoutBookings}
            onClick={() =>
              setOnlyBandsWithoutBookings(!onlyBandsWithoutBookings)
            }
          />
        }
        label={"Exclude bands with bookings"}
      />
      <LinkButton
        path="/band/create"
        text="Create new band"
        variant={"contained"}
        style={{ marginLeft: 30 }}
      />
      <Pagination
        count={Math.round(data?.bandCount! / 50)}
        onChange={handleChange}
        page={page}
      />
      <LoadingOverlay loading={loading}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">name</TableCell>
                <TableCell align="left">bookings</TableCell>
                <TableCell align="left">actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.bands.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.bookings.length}</TableCell>
                  <TableCell align="left">
                    <LinkButton path={`/band/${row.id}`} text="View" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </LoadingOverlay>
    </PageContainer>
  );
};

export default Bands;
