import { gql } from "../../../__generated__/gql";

export const GET_DRINKS = gql(`
query drinks {
  drinks {
    id
    drinkId:id
    name
    description
    abv
    manufacturer
    type
  }
}
`);

export const UPDATE_DRINKS =
  gql(`mutation updateDrinks($drinks: [DrinkInputType!]!) {
  updateDrinks(drinks: $drinks) {
    id
    drinkId:id
    name
    description
    abv
    manufacturer
  }
}`);

export const DELETE_DRINK = gql(`mutation deleteDrink($drinkId: String!) {
  deleteDrink(drinkId: $drinkId)
}`);
