import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";

import "./DrinkPicker.css";
interface IDrinkPickerProps {
  handleChange: (stageId: string) => void;
  defaultValue?: string;
  value: string | undefined;
  placeholder?: string;
  controlStyle?: React.CSSProperties;
}

export const DrinkTypePicker = ({
  handleChange,
  defaultValue,
  value,
  placeholder,
  controlStyle,
}: IDrinkPickerProps) => {
  const drinkTypes = ["Beer", "Cider", "Craft", "Other", "Lager"];
  const options = drinkTypes.map((item) => ({
    name: item,
    value: item,
  }));

  return (
    <FormControl
      fullWidth
      style={{
        marginTop: "20px",
        marginBottom: "20px",
        backgroundColor: "white",
        width: "100%",
        ...controlStyle,
      }}
    >
      <InputLabel id="demo-simple-select-label">Drink Type</InputLabel>
      {/* <SelectSearch
        options={options}
        value={value}
        search={true}
        placeholder={placeholder ?? "Select a type"}
        onChange={(val) => handleChange(val as string)}
      /> */}
      <Select
        labelId="select-drink-type-label"
        id="select-drink-type"
        value={value}
        label="Type"
        defaultValue={defaultValue}
        onChange={(event) => handleChange(event.target.value)}
      >
        {options?.map((option) => {
          return (
            <MenuItem key={option.name} value={option.value}>
              {option.value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
