import React, { useEffect, useRef, useState } from "react";
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
// import { useDebounceEffect } from "./useDebounceEffect";
import { Button } from "@mui/material";
import { useDebounceEffect } from "../band/imageEdit/Crop/useDebounceEffect";
import { canvasPreview } from "../band/imageEdit/Crop/canvasPreview";
import { ImageDetail, SelectedPreview } from "./preview";

interface ICropProps {
  file: string;
  imageDetail: ImageDetail;
  handleCroppedFile: (key: SelectedPreview, file: string) => void;
}
//https://codesandbox.io/s/react-image-crop-demo-with-react-hooks-y831o?file=/src/App.tsx:1967-1983

//numbers from iphone 15 pro
const aspectRatios = [
  393 / 180, // news list
  393 / 250, // news item
  16 / 9,
  1 / 1,
];

const CropImage = ({ file, handleCroppedFile, imageDetail }: ICropProps) => {
  // console.log('imageDetail', JSON.stringify(imageDetail))
  // console.log('image', imageDetail.file)
  const [imgSrc, setImgSrc] = useState(imageDetail?.image);

  useEffect(() => {
    setImgSrc(imageDetail?.image);
    setAspect(imageDetail?.aspectRatio);
  }, [imageDetail?.key]);

  const previewCanvasRef = imageDetail?.ref ?? null;
  const imgRef = useRef<HTMLImageElement>(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(16 / 9);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (imageDetail.size) {
      setCrop({
        x: 0,
        y: 0,
        width: imageDetail.size,
        height: imageDetail.size,
        unit: "px",
      });
      return;
    }

    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function onContinue() {
    if (!previewCanvasRef?.current) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef?.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      // if (blobUrlRef.current) {
      //   URL.revokeObjectURL(blobUrlRef.current);
      // }
      // blobUrlRef.current = URL.createObjectURL(blob);
      handleCroppedFile(imageDetail.key, URL.createObjectURL(blob));
    });
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef?.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
          imageDetail?.key === "adaptive_icon"
        );
      }
    },
    100,
    [completedCrop, scale, rotate, imageDetail?.image]
  );

  const handleAspectRatioPress = (aspectRatio: number) => {
    // @ts-ignore
    const { width, height } = imgRef.current;
    setCrop(centerAspectCrop(width, height, aspectRatio));
    setAspect(aspectRatio);
  };

  if (!previewCanvasRef) return null;

  return (
    <div style={{ paddingLeft: 18, maxHeight: "50%", maxWidth: "50%" }}>
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "25% 25%",
          // gridGap: "20",
          gridColumnGap: "8px",
          alignItems: "center",
        }}
      >
        <div>
          <label htmlFor="scale-input">Scale: </label>
          <input
            id="scale-input"
            type="number"
            step="0.1"
            value={scale}
            disabled={!imgSrc}
            onChange={(e) => setScale(Number(e.target.value))}
          />
        </div>
        <div>
          <label htmlFor="rotate-input">Rotate: </label>
          <input
            id="rotate-input"
            type="number"
            value={rotate}
            disabled={!imgSrc}
            onChange={(e) =>
              setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
            }
          />
        </div>
        {/* <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridColumnStart: 1,
            gridColumnEnd: 4,
            gridRow: 2,
          }}
        >
          <Button
            disabled={true}
            onClick={() => handleAspectRatioPress(2 / 1)}
            variant={aspect === 2 / 1 ? "contained" : "outlined"}
            style={{ width: "80%" }}
          >
            Aspect Ratio: 2/1
          </Button>
          <Button
            onClick={() => handleAspectRatioPress(16 / 9)}
            variant={aspect === 16 / 9 ? "contained" : "outlined"}
            style={{ width: "80%" }}
          >
            Aspect Ratio: 16 / 9
          </Button>
          <Button
            onClick={() => handleAspectRatioPress(1 / 1)}
            variant={aspect === 1 / 1 ? "contained" : "outlined"}
            style={{ width: "80%" }}
          >
            Aspect Ratio: 1/1
          </Button>
        </div> */}
      </div>
      {/* <div style={{ paddingTop: 30, paddingBottom: 30 }}>
       
        <a
          ref={hiddenAnchorRef}
          download
          style={{
            position: "absolute",
            top: "-200vh",
            visibility: "hidden",
          }}
        >
          Hidden download
        </a>
      </div> */}
      <Button
        variant="contained"
        onClick={onContinue}
        style={{ marginBottom: 8 }}
      >
        Proceed
      </Button>
      {!!imgSrc && (
        <ReactCrop
          // key={imageDetail?.key}
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
          locked={!!imageDetail.size}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      {/* {!!completedCrop && (
        <>
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
        </>
      )} */}
    </div>
  );
};

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default CropImage;
