import { useQuery } from "@apollo/client";
import { GET_VENDORS } from "./graphql";
import {
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import React from "react";
import { LinkButton } from "../../components/LinkButton";
import { PageContainer } from "../../components/PageContainer";
import { LoadingOverlay } from "../../components/Loading";
import { UpdateMiscConfigButton } from "../links/updateMiscConfig";

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Name", width: 130 },
  {
    field: "bookings",
    headerName: "Bookings",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.bookings.length}`,
  },
];

const NO_BOOKING_FILTER = { bookings: { none: { id: { not: null } } } };

const FoodVendors = () => {
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");

  const { data, error, loading } = useQuery(GET_VENDORS);

  return (
    <PageContainer title="Vendors">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          height: 56,
          marginBottom: 16
        }}
      >
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          placeholder="Search..."
          style={{ width: "40%" }}
          onChange={(value) => setSearch(value.target.value)}
        />
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            minHeight: '100%',
            alignItems: 'center'
          }}
        >
          <LinkButton
            path="/vendor/create"
            text="Create Vendor"
            variant={"contained"}
            style={{ marginLeft: 30, height: 36 }}
          />
          <UpdateMiscConfigButton />
        </div>
      </div>
      <LoadingOverlay loading={loading}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">name</TableCell>
                <TableCell align="left">actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.foodVendors.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">
                    <LinkButton path={`/vendor/${row.id}`} text="View" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </LoadingOverlay>
    </PageContainer>
  );
};

export default FoodVendors;
