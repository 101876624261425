import { gql } from "../../../__generated__/gql";

export const GET_VENDORS = gql(`
query foodVendors {
  foodVendors {
    id
    name
    image
    description
    type    
  }
}
`);

export const GET_VENDOR = gql(`
query foodVendor($id: String!) {
  foodVendor(where: {
    id: $id
  }) {
    id
    name
    image
    description
    type
  }
}
`);