import React, { PropsWithChildren } from "react";
interface IErrorField {
  additionalStyle?: React.CSSProperties;
}
export const ErrorField: React.FC<PropsWithChildren<IErrorField>> = ({
  children,
  additionalStyle,
}) => {
  return (
    <div
      style={{
        height: 50,
        display: "flex",
        marginTop: -15,
        marginLeft: 8,
        color: "red",
        ...additionalStyle,
      }}
    >
      {children}
    </div>
  );
};

export const validator = (fieldName: string, action: () => void) => {};
