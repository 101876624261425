import { useQuery } from "@apollo/client";
import { GET_FESTIVAL_SETTINGS, UPDATE_FESTIVAL_SETTINGS } from "./graphql";
import { ISettingsProps, SettingsForm } from "./form";
import { LoadingOverlay } from "../../components/Loading";
import { Setting } from "../../__generated__/graphql";
import { PageContainer } from "../../components/PageContainer";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { ManageConfig } from "../config";
import ShowcaseBands from "../showcase";

export const Settings = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <PageContainer title={`Settings`}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="Config" value={0} />
        <Tab label="Showcase" value={1} />
        <Tab label="App Settings" value={2} />        
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <ManageConfig />
      </CustomTabPanel>      
      <CustomTabPanel value={value} index={1}>
        <ShowcaseBands />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <SettingsTab />
      </CustomTabPanel>
    </PageContainer>
  );
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export const SettingsTab = () => {
  const { data, error, loading } = useQuery(GET_FESTIVAL_SETTINGS);

  if (loading) return <LoadingOverlay loading={loading} />;

  // if (!data?.settings[0]) return null;

  const defaults: ISettingsProps = {
    editionId: "",
    // @ts-expect-error
    setting: {
      downForMaintenance: false,
      editionId: "",
      minVersion: "0.0.1",
      id: 1,
      countdownDate: null,
    },
  };

  return (
    <SettingsForm
      // @ts-ignore
      setting={data?.settings[0] ?? defaults.setting}
      versionNumber={data?.versionNumber ?? defaults.versionNumber}
    />
  );
};
