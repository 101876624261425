import { Button, Input, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { PageContainer } from "../../components/PageContainer";
import React from "react";
// import { archiveFiles, saveZip } from "./archiver";
import { aspectRatios, getBrandingUrl } from "./preview";
import { HomeLayout } from "./pages/home";
import { LineupLayout } from "./pages/lineup";
import { ClashfinderLayout } from "./pages/clashfinder";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MelioraTheme } from "./pages/types";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ASSET_CONFIG, UPDATE_ASSET_CONFIG } from "../meta/config/graphql";
import { ADD_ASSET } from "./graphql";
import { s3Put } from "../band/s3";
import { toast } from "react-toastify";
import { updateConfig } from "./helpers/configs";
import { useParams } from "react-router-dom";
// import JSZip from "jszip";

const darker = "#081c51";
const regular = "#5d32b3";
const border = "#f93fd6";
const darkest = "black";
const _white = "white";
const _color1 = "rgb(246, 246, 246)";

const melioraTheme: MelioraTheme = {
  primaryColor: regular, //227, 230, 228 // white
  primaryColorDark: regular,
  primaryColorDarker: darker,
  primaryColorText: "white", //227, 230, 228
  alternativeTextColor: "black",
};
const purpleTheme: MelioraTheme = {
  primaryColor: "rgba(87,52,172)", //227, 230, 228 // white
  primaryColorDark: "rgba(73,26,163)",
  primaryColorDarker: "rgba(9,19,64)",
  primaryColorText: "white", //227, 230, 228
  alternativeTextColor: "black",
};

const bloodstockTheme: MelioraTheme = {
  primaryColor: "rgb(41,40,48)",
  primaryColorText: "white",
  primaryColorDarker: "rgb(24, 23, 27)",
  primaryColorDark: "rgb(32, 30, 36)",
  alternativeTextColor: "black",
};

const Theme = () => {
  const [active, setActive] = useState<SelectedPreview>("home");
  const { id } = useParams();
  const { data, loading } = useQuery(GET_ASSET_CONFIG, {
    variables: {
      id: id!,
    },
  });
  const [getAssetUploadUrl] = useMutation(ADD_ASSET);
  const [updateAssetConfig] = useMutation(UPDATE_ASSET_CONFIG, {
    onCompleted: () => {
      toast.success("Updated Config");
    },
  });

  const [currentTheme, setCurrentTheme] = useState<MelioraTheme>();

  useEffect(() => {
    if (data?.assetConfig) {
      if (Object.keys(data?.assetConfig?.value?.theme ?? {}).length !== 0) {
        setCurrentTheme(data.assetConfig.value.theme);
      } else {
        setCurrentTheme(purpleTheme);
      }
    }
  }, [data?.assetConfig]);

  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);

  const handleSave = async () => {
    const basePath = `${id}/theme`;
    // const zip = new JSZip(); // instance of JSZip
    const json = JSON.stringify(currentTheme, null, 2);

    // console.log("json", json);

    // maybe change this encoding
    const configBlob = new Blob([json], { type: "application/json;charset=utf-8" });

    const targeturl = (
      await getAssetUploadUrl({ variables: { key: `${basePath}/colors.json` } })
    ).data?.addAssets;

    await s3Put(targeturl, configBlob, true);
    const updatedConfig = updateConfig(data, {}, {}, currentTheme);

    updateAssetConfig({
      variables: {
        id: id!,
        value: updatedConfig.value,
      },
    });
  };

  const handleDownload = async (theme: MelioraTheme) => {
    // const zip = new JSZip(); // instance of JSZip
    const json = JSON.stringify(theme, null, 2);

    console.log("json", json);

    const configBlob = new Blob([json], { type: "text/plain;charset=utf-8" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(configBlob);
    link.download = `colors.json`;
    link.click();
  };

  console.log("assetConfig", data?.assetConfig);

  if (!data?.assetConfig) return <></>;

  return (
    <PageContainer title="Setup Theme">
      <a
        ref={hiddenAnchorRef}
        download
        style={{
          position: "absolute",
          top: "-200vh",
          visibility: "hidden",
        }}
      >
        Hidden download
      </a>
      <Button
        onClick={() => setActive("home")}
        variant={active === "home" ? "contained" : "outlined"}
      >
        Home
      </Button>
      <Button
        onClick={() => setActive("lineup")}
        variant={active === "lineup" ? "contained" : "outlined"}
      >
        Lineup
      </Button>
      <Button
        onClick={() => setActive("clashfinder")}
        variant={active === "clashfinder" ? "contained" : "outlined"}
      >
        Clashfinder
      </Button>
      {/* <Button
        onClick={() => setActive("menu")}
        variant={active === "menu" ? "contained" : "outlined"}
      >
        Menu
      </Button> */}
      {currentTheme && (
        <PhoneMockup
          selectedPreview={active}
          key={active}
          handleDownload={handleDownload}
          setCurrentTheme={setCurrentTheme}
          currentTheme={currentTheme!}
          handleSave={() => handleSave()}
        />
      )}
    </PageContainer>
  );
};

export type SelectedPreview = "home" | "lineup" | "menu" | "clashfinder";

// dimensions in mm
const iPhone15 = { width: 71.6, height: 146 };

const PhoneMockup = ({
  selectedPreview,
  handleDownload,
  setCurrentTheme,
  currentTheme,
  handleSave,
}: {
  selectedPreview: SelectedPreview;
  handleDownload: (theme: MelioraTheme) => void;
  setCurrentTheme: (newTheme: MelioraTheme) => void;
  currentTheme: MelioraTheme;
  handleSave: () => void;
}) => {
  const { id } = useParams();
  const scale = 6;
  const scaledDimensions = {
    height: iPhone15.height * scale,
    width: iPhone15.width * scale,
  };

  const { data, loading } = useQuery(GET_ASSET_CONFIG, {
    variables: {
      id: id!,
    },
  });

  const menuIsActive = false; //selectedPreview === "menu_background";

  const schema = yup
    .object({
      primaryColor: yup.string(),
      primaryColorText: yup.string(),
      primaryColorDarker: yup.string(),
      primaryColorDark: yup.string(),
      alternativeTextColor: yup.string(),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: currentTheme });

  const onSubmit = (val: any) => {
    setCurrentTheme(val);
  };

  if (!data?.assetConfig?.value) return;

  const backgroundImage = getBrandingUrl(data, "home_background", id!);
  const logoImage = getBrandingUrl(data, "home_logo", id!);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              ...scaledDimensions,
              border: "1px solid black",
              borderRadius: 16,
              overflow: "hidden",
              position: "relative",
            }}
          >
            {selectedPreview === "home" && (
              <>
                <HomeHeaderBackground
                  menuIsActive={menuIsActive}
                  backgroundImage={backgroundImage}
                >
                  <HomeHeaderLogo image={logoImage} />
                </HomeHeaderBackground>
                <HomeLayout theme={currentTheme} />
              </>
            )}
            {menuIsActive && <MenuBackground image={logoImage} />}

            {selectedPreview === "lineup" && (
              <LineupLayout theme={currentTheme} />
            )}
            {selectedPreview === "clashfinder" && (
              <ClashfinderLayout theme={currentTheme} />
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 30,
              marginTop: 60,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              style={{ marginTop: 8, marginBottom: 18 }}
            >
              Update theme
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              style={{ marginTop: 8, marginBottom: 18 }}
            >
              Save/export
            </Button>
            {Object.keys(currentTheme).map((key) => {
              return (
                <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                  <TextField
                    inputProps={{
                      className: "textShadow",
                    }}
                    id="outlined-basic"
                    label={key}
                    variant="outlined"
                    // @ts-ignore
                    defaultValue={currentTheme[key]}
                    style={
                      {
                        // @ts-ignore
                        // backgroundColor: currentTheme[key] as string,
                      }
                    }
                    // @ts-ignore
                    {...register(key)}
                  />
                  <div
                    style={{
                      width: 56,
                      height: 56,
                      marginLeft: 8,
                      // @ts-ignore
                      backgroundColor: currentTheme[key] as string,
                      border: "1px solid black",
                    }}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>
      </form>
    </div>
  );
};

export const MenuBackground = ({ image }: { image?: string }) => {
  return (
    <>
      <img
        src={image}
        style={{
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          width: "75%", // app is about 70%
          height: "100%",
          backgroundColor: "purple",
          zIndex: 5,
        }}
      />
    </>
  );
};

export const HomeHeaderBackground: React.FC<
  React.PropsWithChildren<{
    image?: string;
    menuIsActive: boolean;
    backgroundImage?: string;
    logoImage?: string;
  }>
> = ({ children, image, menuIsActive = false, backgroundImage }) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%", // app is about 70%
        height: "30%",
        backgroundColor: "red",
        // actual props
        objectFit: "cover",
        display: "flex",
        // paddingTop: 45,
        justifyContent: "center",
        opacity: menuIsActive ? 0.6 : 1,
      }}
    >
      <img
        src={backgroundImage}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          zIndex: 0,
        }}
      />
      {children}
    </div>
  );
};

export const HomeHeaderLogo = ({ image }: { image?: string }) => {
  return (
    <>
      <img
        src={image}
        style={{
          objectFit: "contain",
          width: "50%", // app is about 70%
          height: "50%",
          // backgroundColor: "blue",
          // actual props
          alignSelf: "center",
          zIndex: 1,
        }}
      />
    </>
  );
};

export default Theme;
