import JSZip from "jszip";
import { ImageDetails, SelectedPreview } from "../preview";
import Resizer from "react-image-file-resizer";

export const archiveFiles = async (
  files: ImageDetails,
  filename: string,
  skipdownload: boolean = false
) => {
  const zip = new JSZip(); // instance of JSZip

  const jsonConfig = {};

  const promises = Object.keys(files).map(async (key) => {
    if (!key) return;

    const item = files[key as SelectedPreview];

    if (!item.crop || !item?.file?.name) return;

    const split = item.file.name.split(".");
    const extension = split[split.length - 1];
    const imageName = `${key}.${extension}`;

    let fileBlob = await fetch(item.crop).then((r) => r.blob());
    if (item.size) {
      fileBlob = (await resizeFile(
        fileBlob,
        item.size,
        extension.toUpperCase() as "JPEG" | "PNG" | "WEBP"
      )) as Blob;
    }

    // @ts-ignore
    jsonConfig[key] = imageName;

    // https://dev.to/jaydeepkhachariya/how-make-zip-of-images-and-download-it-using-react-js-in-3-easy-steps-lkb
    await zip.file(imageName, fileBlob);
  });
  await Promise.all(promises);

  const json = JSON.stringify(jsonConfig, null, 2);

  const configBlob = new Blob([json], { type: "text/plain;charset=utf-8" });
  await zip.file(`${filename}.json`, configBlob);

  if (skipdownload)
    return {
      zipFile: await zip.generateAsync({
        type: "blob",
        streamFiles: true,
      }),
      config: jsonConfig,
    };

  await saveZip(zip, filename);
};

const saveZip = async (zip: JSZip, filename: string) => {
  const zipData = await zip.generateAsync({
    type: "blob",
    streamFiles: true,
  });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(zipData);
  link.download = `${filename}.zip`;
  link.click();
};

const resizeFile = (file: any, size: number, format: "JPEG" | "PNG" | "WEBP") =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      size, // maxWidth
      size, // maxHeight
      format,
      50,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
      // 1500, // minWidth?
      // 1500 // minHeight
    );
  });

type FileConfig = { fullFileName: string; file: string };
interface IZipItems {
  files: FileConfig[];
  config?: FileConfig;
}

export const zipItems = async ({ files, config }: IZipItems) => {
  // const obj = URL.createObjectURL(event.target.files[0]);
  const zip = new JSZip();
  // const json = JSON.stringify(jsonConfig, null, 2);
  
  const promises = files.map((item) => {
    const configBlob = new Blob([item.file], {
      type: "text/plain;charset=utf-8",
    });
    return zip.file(`${item.fullFileName}`, configBlob);
  });

  await Promise.all(promises);

  // const configBlob = new Blob([json], { type: "text/plain;charset=utf-8" });
  // await zip.file(`${filename}.json`, configBlob);

  // if (skipdownload)
  return {
    zipFile: await zip.generateAsync({
      type: "blob",
      streamFiles: true,
    }),
  };
};
