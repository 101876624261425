import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_STAGES } from "./graphql";
interface IStagePickerProps {
  festivalId: string;
  handleChange: (stageId: string) => void;
  defaultValue?: string;
  value: string|undefined;
}

export const StagePicker = ({
  handleChange,
  defaultValue,
  value,
  festivalId,
}: IStagePickerProps) => {
  const { data, error, loading } = useQuery(GET_STAGES);

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Stage</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label="Stage"
        defaultValue={defaultValue}
        onChange={(event) => handleChange(event.target.value)}
      >
        {data?.stages.map((stage) => {
          return <MenuItem value={stage.id}>{stage.name}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};
