import { Box, Container } from "@mui/material";
import React, { PropsWithChildren } from "react";
interface IPageContainer {
  // title: string;
}
export const FieldContainer: React.FC<PropsWithChildren<IPageContainer>> = ({
  children,
}) => {
  return (
    <Container style={{ flexDirection: "column", display: "flex" }}>
      {children}
    </Container>
  );
};
