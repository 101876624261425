import { gql } from "../../../__generated__/gql";

export const GET_ALL_STAGES = gql(`
query getAllStages {
  stages(orderBy: {order: asc}) {
    id
    name
    order
  }
}
`);

export const DELETE_STAGE = gql(`
mutation deleteStage($stageId:String!) {
  deleteStage(stageId: $stageId) {
    id

    }
}
`);

