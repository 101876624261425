import { useLocation } from "react-router-dom";
import { CreateBookingForm } from "./form";

const CreateBooking = () => {
  const { state } = useLocation();

  return <CreateBookingForm festivalId={state.festivalId} />;
};

export default CreateBooking;
