import { Box, Container } from "@mui/material";
import React, { PropsWithChildren } from "react";
interface IPageContainer {
  title: string;
}
export const PageContainer: React.FC<PropsWithChildren<IPageContainer>> = ({
  title,
  children,
}) => {
  return (
    <div
      style={{
        borderRadius:16,
        backgroundColor: "white",
        padding: 18,
        paddingTop: 8,
        marginTop: 20,
        minHeight: "100%",
        width: "100%",
      }}
    >
      <h2>{title}</h2>
      {children}
    </div>
  );
};
