import { Box, Container } from "@mui/material";
import React, { PropsWithChildren } from "react";
interface IPageContainer {
  // title: string;
}
export const ButtonContainer: React.FC<PropsWithChildren<IPageContainer>> = ({
  children,
}) => {
  return (
    <Container
      style={{
        flexDirection: "column",
        display: "flex",
        width: 200,
        justifySelf: "start",
        gap: 8,
      }}
    >
      {children}
    </Container>
  );
};
