import { useMutation } from "@apollo/client";
import React from "react";
import { SEND_NOTIFICATION } from "./graphql";
import { Box, Button, Container, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
const schema = yup
  .object({
    title: yup.string().required(),
    message: yup.string().required(),
  })
  .required();

export const SendNotification = () => {
  // export number
  const { id: exportNumber } = useParams();
  const navigate = useNavigate();
  const [sendNotification, { data, error }] = useMutation(SEND_NOTIFICATION, {
    onCompleted: () => {
      toast("Notification sent");
      setTimeout(() => {
        navigate("/notification/history");
      }, 2000);
    },
    onError: (error) => toast(JSON.stringify(error)),
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const onSubmit = (data: { title: string; message: string }) => {
    const { title, message } = data;
    const exportNum = exportNumber?.length === 0 ? null : exportNumber;
    // console.log("data", data, exportNum);
    sendNotification({
      variables: {
        title,
        message,
        exportNumber: exportNum,
      },
    });
  };
  return (
    <Box style={{ backgroundColor: "white", padding: 18, marginTop: 20 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>Send a notification</h2>
        {exportNumber && (
          <h3>
            As you have updated the lineup, Do you want to send notification
            update to the app?
          </h3>
        )}
        {/* <Container> */}
        <TextField
          id="outlined-basic"
          label="Title"
          variant="outlined"
          {...register("title")}
          style={{ width: "80%" }}
        />
        <p>{errors.title?.message}</p>
        <TextField
          id="outlined-basic"
          label="Message"
          multiline
          minRows={18}
          style={{ width: "80%" }}
          variant="outlined"
          {...register("message")}
        />
        <p>{errors.message?.message}</p>
        <Button type="submit" variant="contained">
          Send
        </Button>
      </form>
    </Box>
  );
};
