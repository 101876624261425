import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  Router,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { ProtectedRoute } from "./routes/helpers";
import Organisation from "./routes/org";
import Login from "./routes/login";
import Bands from "./routes/bands";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Posts from "./routes/posts";
import Post from "./routes/post/edit";
import Editions from "./routes/editions";
import EditionBands from "./routes/editionsBands";
import { ImageEditor } from "./routes/band/imageEdit";
import CreatePost from "./routes/post/create";
import Booking from "./routes/booking/edit";
import EditBooking from "./routes/booking/edit";
import CreateBooking from "./routes/booking/create";
import Stages from "./routes/stages";
import Stage from "./routes/stage";
import Band from "./routes/band/edit";
import CreateBand from "./routes/band/create";
import Edition from "./routes/edition/edit";
import CreateEdition from "./routes/edition/create";
import { SendNotification } from "./routes/notification";
import Landing from "./routes/landing";
import { Settings } from "./routes/settings";
import NotificationHistory from "./routes/notifications";
import ShowcaseBands from "./routes/showcase";
import Users from "./routes/users";
import Drinks from "./routes/drinks";
import CreateUser from "./routes/users/create";
import EditUser from "./routes/users/edit";
import Links from "./routes/links";
import ImageSelection from "./routes/images";
import CreateStage from "./routes/stage/create";
import Branding from "./routes/images/preview";
import Icons from "./routes/images/icons";
import Theme from "./routes/images/theme";
import { Config as Meta } from "./routes/meta";
import { ViewConfig as ViewMeta } from "./routes/meta/view";
import { ManageConfig } from "./routes/config";
import { CreateAppConfig } from "./routes/meta/config/create";
import FoodVendors from "./routes/vendors";
import CreateVendor from "./routes/vendors/vendor/create";
import Vendor from "./routes/vendors/vendor/edit";
import {Map} from './routes/map/index'
import { InfoPage } from "./routes/info";
import { EditInfo } from "./routes/info/edit";
import Sponsors from "./routes/sponsors";
import CreateSponsor from "./routes/sponsor/create";
import Sponsor from "./routes/sponsor";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route key="route_landing" index element={<Landing />} />
            <Route
              key="route_landing_path"
              path="/landing"
              element={<Landing />}
            />
            <Route key="route_news" path="/news" element={<Posts />} />
            <Route
              key="route_ceate"
              path="/post/create"
              element={<CreatePost />}
            />
            <Route key="route_post_id" path="/post/:id" element={<Post />} />
            <Route key="route_bands" path="/bands" element={<Bands />} />
            <Route
              key="route_band_create"
              path="/band/create"
              element={<CreateBand />}
            />
            <Route key="route_band_id" path="/band/:id" element={<Band />} />
            <Route
              key="route_showcase"
              path="/showcase"
              element={<ShowcaseBands />}
            />
            <Route
              key="route_editions"
              path="/editions"
              element={<Editions />}
            />
            <Route
              key="route_edition_edit"
              path="/edition/edit/:id"
              element={<Edition />}
            />
            <Route
              key="route_edition_create"
              path="/edition/create"
              element={<CreateEdition />}
            />
            <Route
              key="route_edition_id"
              path="/edition/:id"
              element={<EditionBands />}
            />
            <Route
              key="route_booking_create"
              path="/booking/create"
              element={<CreateBooking />}
            />
            <Route
              key="route_booking_id"
              path="/booking/:id"
              element={<EditBooking />}
            />
            <Route key="route_stages" path="/stages" element={<Stages />} />
            <Route
              key="route_stage_id"
              path="/stage/create"
              element={<CreateStage />}
            />
            <Route key="route_stage_id" path="/stage/:id" element={<Stage />} />
            <Route key="route_image" path="/image" element={<ImageEditor />} />
            <Route
              key="route_notifications"
              path="/notifications"
              element={<SendNotification />}
            />
            <Route
              key="route_notifications"
              path="/notifications/:id"
              element={<SendNotification />}
            />
            <Route
              key="route_notifications"
              path="/notification/history"
              element={<NotificationHistory />}
            />
            <Route
              key="route_settings"
              path="/settings"
              element={<Settings />}
            />
            <Route key="route_users" path="/users" element={<Users />} />
            <Route
              key="route_users"
              path="/user/create"
              element={<CreateUser />}
            />
            <Route
              key="route_users"
              path="/user/edit/:id"
              element={<EditUser />}
            />
            <Route key="route_drinks" path="/drinks" element={<Drinks />} />
            <Route key="route_links" path="/links" element={<Links />} />
            <Route key="route_information" path="/information" element={<InfoPage />} />
            <Route key="route_information_edit" path="/information/edit/:id" element={<EditInfo />} />
            <Route
              key="route_image_editor"
              path="/image-tools"
              element={<ImageSelection />}
            />
            <Route
              key="route_branding"
              path="/branding"
              element={<Branding />}
            />
            <Route key="route_theme" path="/theme" element={<Theme />} />
            <Route key="route_icons" path="/icons" element={<Icons />} />
            {/* <Route
              key="route_meta_config"
              path="/meta/config"
              element={<Meta />}
            />
            <Route
              key="route_create_meta_config"
              path="/meta/create"
              element={<CreateAppConfig />}
            /> */}
            {/* <Route
              key="route_view_meta_config"
              path="/meta/config/:id"
              element={<ViewMeta />}
            /> */}

            <Route
              key="route_config"
              path="/config"
              element={<ManageConfig />}
            />
            <Route
              key="food_vendor"
              path="/vendors"
              element={<FoodVendors />}
            />
            <Route
              key="route_vendor_create"
              path="/vendor/create"
              element={<CreateVendor />}
            />
            <Route
              key="route_vendor_edit"
              path="/vendor/:id"
              element={<Vendor />}
            />
              <Route
              key="map"
              path="/map"
              element={<Map />}
            />
            <Route key="route_band_id" path="/band/:id" element={<Band />} />
            {/* <Route key="route_view_config" path="/config/:id" element={<ViewMeta />} /> */}
            <Route
              key="sponsors"
              path="/sponsors"
              element={<Sponsors />}
            />
            <Route
              key="route_sponsor_create"
              path="/sponsor/create"
              element={<CreateSponsor />}
            />
            <Route
              key="route_sponsor_edit"
              path="/sponsor/:id"
              element={<Sponsor />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
