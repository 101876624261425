import { Container, TextField, Button } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BandBooking } from "../../../__generated__/graphql";
import { DELETE_BOOKING, UPDATE_BOOKING } from "../graphql";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import { confirmAlert } from "react-confirm-alert";

import { StagePicker } from "../../../components/StagePicker";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PageContainer } from "../../../components/PageContainer";
import { ButtonContainer } from "../../../components/ButtonContainer";
import { ErrorField } from "../../../components/ErrorField";
import { Info } from "../../../components/Info";
const schema = yup
  .object({
    selectedStartTime: yup
      .string()
      .nonNullable()
      .required("Start time is required")
      .test(
        "valid-starttime",
        "Start time must be before endtime",
        (selectedStartTime, ctx) => {
          const { selectedEndTime } = ctx.parent;
          return dayjs(selectedEndTime).isAfter(dayjs(selectedStartTime));
        }
      ),
    selectedEndTime: yup
      .string()
      .nonNullable()
      .required("End time is required")
      .test(
        "valid-endtime",
        "End time must be after start time",
        (selectedEndTime, ctx) => {
          const { selectedStartTime } = ctx.parent;
          return dayjs(selectedEndTime).isAfter(dayjs(selectedStartTime));
        }
      ),
    // .when(
    //   ["selectedStartTime", "selectedEndTime"],
    //   (selectedStartTime, selectedEndTime) => {
    //     return dayjs(selectedStartTime).isAfter(dayjs(selectedEndTime));
    //   }
    // ),
    //   .when('selectedStartTime', (selectedStartTime) => {
    //     if (selectedStartTime) {
    //         return yup.date()
    //             .min(selectedStartTime, 'End Date must be after Start Date')
    //             .typeError('End Date is required')
    //     }
    // }),
    // .test('is-after-start',(value,ctx)=> {
    //   value
    //   ctx.parent

    // }),
  })
  .required();

interface IBookingFormProps {
  booking: BandBooking;
}

export const BookingForm = ({ booking }: IBookingFormProps) => {
  const navigate = useNavigate();
  const [selectedStartTime, setSelectedStartTime] = useState<Dayjs | null>(
    dayjs(booking.startTime)
  );
  const [selectedEndTime, setSelectedEndTime] = useState<Dayjs | null>(
    dayjs(booking.endTime)
  );
  const [selectedStage, setSelectedStage] = useState<string>(booking.stage.id);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      selectedStartTime: booking.startTime,
      selectedEndTime: booking.endTime,
    },
  });
  const location = useLocation();
  const [updateBooking, { loading: updateLoading, error }] = useMutation(
    UPDATE_BOOKING,
    {
      refetchQueries: ["festivalEditions", "festivalSingalEdition"],
      onCompleted: () => {
        toast("Update complete");
      },
    }
  );
  const [deleteBooking] = useMutation(DELETE_BOOKING, {
    onCompleted: () => {
      toast("booking deleted");
      navigate(-1);
    },
  });

  const updateEndTime = (value: Dayjs | null) => {
    setValue("selectedEndTime", value?.toString() ?? "");
    trigger("selectedEndTime");
    setSelectedEndTime(value!);
  };

  const updateStartTime = (value: Dayjs | null) => {
    setValue("selectedStartTime", value?.toString() ?? "");
    trigger("selectedStartTime");
    setSelectedStartTime(value!);
  };

  // const imageUrl =  ?? ;
  const onSubmit = (data: any) => {
    let date = dayjs(selectedStartTime).clone();
    date = date.set("hour", 0);
    date = date.set("minute", 0);

    updateBooking({
      variables: {
        id: booking.id,
        startTime: selectedStartTime?.toDate().toString()!,
        endTime: selectedEndTime?.toDate().toString()!,
        stageId: selectedStage,
        date: date.toDate().toString()!,
      },
    });
  };
  const handleDelete = () => {
    confirmAlert({
      title: "Delete this booking?",
      message: "Are you sure? this can't be undone",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            deleteBooking({ variables: { bookingId: booking.id } }),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <PageContainer title={booking.band.name}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container style={{ flexDirection: "column", display: "flex" }}>
          <DateTimePicker
            format="YYYY/MM/DD HH:mm"
            timezone="Europe/London"
            label="Start Time"
            ampm={false}
            value={dayjs(selectedStartTime)}
            onChange={(value: Dayjs | null) => updateStartTime(value)}
          />
          <ErrorField>{errors.selectedStartTime?.message}</ErrorField>
          <DateTimePicker
            format="YYYY/MM/DD HH:mm"
            ampm={false}
            timezone="Europe/London"
            label="End Time"
            value={dayjs(selectedEndTime)}
            onChange={(value: Dayjs | null) => updateEndTime(value)}
          />
          <ErrorField>{errors.selectedEndTime?.message}</ErrorField>
          <StagePicker
            defaultValue={booking.stage.id}
            value={selectedStage}
            handleChange={(value) => setSelectedStage(value)}
            festivalId={booking.edition.id}
          />
          <ButtonContainer>
            <Button
              type="submit"
              variant="contained"
              style={{ marginTop: 18, marginBottom: 18 }}
            >
              Save
            </Button>
            <Button
              type="button"
              variant="contained"
              onClick={handleDelete}
              style={{ backgroundColor: "red" }}
            >
              DELETE
            </Button>
          </ButtonContainer>
        </Container>
        {Object.keys(errors).length > 0 && (
          <p>errors: {JSON.stringify(errors)}</p>
        )}
      </form>
      <Info />
    </PageContainer>
  );
};
