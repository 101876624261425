import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../helpers";
import { useMutation } from "@apollo/client";
import { LOGIN_MUTATION } from "./graphql";
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField } from "@mui/material";

const Login = () => {
  const auth = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  
  useEffect(() => {
    setAuthToken(undefined);
  }, []);

  const setAuthToken = async (token: string | undefined) => {
    await auth?.setToken(token);
  };

  const [login, { data, loading }] = useMutation(LOGIN_MUTATION, {
    onCompleted: async (state) => {
      await setAuthToken(state.signin.token);

      navigate("/landing");
    },
  });

  const onSubmit = (data: any) =>
    login({ variables: { email: data.email, password: data.password } });

  return (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      noValidate
      sx={{ mt: 1 }}
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box>
        <TextField
          fullWidth
          type="email"
          placeholder="email"
          {...register("email")}
        />

        <TextField
          fullWidth
          placeholder="password"
          type="password"
          {...register("password", { required: true })}
        />

        {errors.email && <span>This field is required</span>}

        <Button variant="contained" type="submit">
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
