import { useMutation, useQuery } from "@apollo/client";
import TableContainer from "@mui/material/TableContainer";
import {
  Pagination,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import { DELETE_STAGE, GET_ALL_STAGES } from "./graphql";
import { LinkButton } from "../../components/LinkButton";
import { PageContainer } from "../../components/PageContainer";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

const Stages = () => {
  const { data, error, loading, refetch } = useQuery(GET_ALL_STAGES, {
    fetchPolicy: "cache-first",
  });
  const [deleteStage] = useMutation(DELETE_STAGE, {
    onCompleted: () => {
      toast("Stage deleted");
      refetch()
    },
  });

  const handleDelete = (stageId: string) => {
    confirmAlert({
      title: "Delete this Stage?",
      message: `Are you sure? this can't be undone. NOTE: if there are any bookings on this stage, this operation will fail`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteStage({ variables: { stageId: stageId } }),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  if (loading) return null;

  return (
    <PageContainer title="Stages">
      <LinkButton
        path="/stage/create"
        text="Create new Stage"
        variant={"contained"}
      />
      <TableContainer component={Paper} style={{ marginTop: 16 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">name</TableCell>
              <TableCell align="left">order</TableCell>
              <TableCell align="center">actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.stages.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.order}</TableCell>
                <TableCell align="center">
                  <LinkButton path={`/stage/${row.id}`} text="View" />
                  <Button
                    title="Delete"
                    variant="contained"
                    color="error"
                    onClick={() => handleDelete(row.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageContainer>
  );
};

export default Stages;
