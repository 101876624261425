import { Container, TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Stage } from "../../__generated__/graphql";
import { PageContainer } from "../../components/PageContainer";
import { ButtonContainer } from "../../components/ButtonContainer";
import { Note } from "../../components/Note";
import { UPDATE_STAGE } from "./graphql";

const schema = yup
  .object({
    name: yup.string().required(),
    order: yup.number().required(),
  })
  .required();
interface IPostFormProps {
  stage: Stage;
}
export const StageForm = ({ stage }: IPostFormProps) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { name: stage.name, order: stage.order },
  });
  const location = useLocation();
  //   const [deletePost] = useMutation(DELETE_POST, {
  //     onCompleted: () => {
  //       toast("post deleted");
  //       navigate("/news");
  //     },
  //   });
  const [updateStage, { loading: updateLoading, error }] = useMutation(
    UPDATE_STAGE,
    {
      onCompleted: () => {
        toast("Update complete");
        navigate("/stages");
      },
    }
  );

  // const imageUrl =  ?? ;
  const onSubmit = (data: Partial<Stage>) => {
    const { name, order } = data;
    updateStage({
      variables: {
        id: stage.id,
        name,
        order,
      },
    });
  };

  // const handleDelete = () => {
  //   confirmAlert({
  //     title: "Delete this Stage?",
  //     message: "Are you sure? this can't be undone",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => {}, //deletePost({ variables: { postId: post.id } }),
  //       },
  //       {
  //         label: "No",
  //         onClick: () => {},
  //       },
  //     ],
  //   });
  // };

  const imageKey = location?.state?.updated ?? "imageUrl";
  return (
    <PageContainer title={"Edit Stage"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container style={{ flexDirection: "column", display: "flex" }}>
          <TextField
            // style={{margin:20}}
            id="outlined-basic"
            label="Name"
            variant="outlined"
            defaultValue={stage?.name}
            {...register("name")}
          />
          <p>{errors.name?.message}</p>
          <TextField
            id="outlined-basic"
            type="number"
            label="Order"
            variant="outlined"
            defaultValue={stage?.order}
            {...register("order")}
          />
          <p>{errors.order?.message}</p>

          <ButtonContainer>
            <Button type="submit" variant="contained">
              Save
            </Button>
            {/* <Button
              variant="contained"
              onClick={handleDelete}
              style={{ backgroundColor: "red" }}
            >
              Delete Stage
            </Button> */}
          </ButtonContainer>
        </Container>
      </form>
      <Note message={"Changes won't be seen until you publish"} />
    </PageContainer>
  );
};
