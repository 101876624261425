import { gql } from "../../../__generated__/gql";

export const GET_BANDS = gql(`
query bands($take: Int!, $skip: Int!, $where: BandWhereInput) {
  bandCount
  bands(skip:$skip, take: $take, where: $where) {
    id
    name
    description
    bookings {
      edition {
        id
        year
      }
      id
      date
      stage {
        name
      }
    }
  }
}
`);
