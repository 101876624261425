import { gql } from "../../../__generated__/gql";

export const GET_SHOWCASE_BANDS = gql(`
query showcaseBands {
    bands(where: { showcase: { equals: true } }) {
      id
      name
      imageUrl
      description
      showcase
    }
  }
`);

export const EXPORT_MISC_APP_CONFIG = gql(`mutation exportMiscAppConfig {
  exportMiscAppConfig
}`);

export const REMOVE_ALL_SHOWCASES = gql(`mutation removeAllShowcases { 
  removeAllShowcases
  }`);
