import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { SponsorForm } from "./form";
import { GET_SPONSOR } from "./graphql";

const Sponsor = () => {
  const [getSponsor, { data, error, loading }] = useLazyQuery(GET_SPONSOR);
  const { id } = useParams();

  useEffect(() => {
    console.log(id);
    if (!id) return;

    getSponsor({ variables: { id: id } });
  }, [id]);

  if (loading || !data) return null;
  
  if(!data.sponsor) return<div>sponsor not found</div>

  return <SponsorForm sponsor={data?.sponsor} />;
};

export default Sponsor;
