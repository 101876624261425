import { gql } from "../../../../__generated__";

export const GET_APP_CONFIGS = gql(`
    query getAppAndAsetConfigs {
        appConfigs {
            id
            value
        }
        assetConfigs {
            id
            value
        }
    }
    `);

export const GET_ASSET_CONFIG = gql(`
query assetConfig($id: String!) {
  assetConfig(where: { id: $id }) {
    id
    value
  }
}
    `);

export const CREATE_APP_AND_ASSET_CONFIG =
  gql(`mutation createAppAndAssetConfig($id: String!) {
    createAppAndAssetConfig(id: $id)
}`);

export const UPDATE_APP_CONFIG =
  gql(`mutation updateAppConfig($id: String!, $value: Json!) {
    updateAppConfig(id: $id, value: $value){
      id
      value
    }
}
`);

export const UPDATE_ASSET_CONFIG =
  gql(`mutation updateAssetConfig($id: String!, $value: Json!) {
    updateAssetConfig(id: $id, value: $value){
      id
      value
    }
}
`);

export const GET_APP_AND_ASSET_CONFIGS = gql(`
query getAppAndAssetConfigs($id: String!) {
    appConfig(where: { id: $id }) {
        id
        value
      }
    assetConfig(where: { id: $id }) {
      id
      value
    }
  }
`);

export const GET_META_ASSET_URL = gql(`
  query getMetaAssetUrl($key: String!) {
  getMetaAssetUrl(key: $key)
}
      `);
