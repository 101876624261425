import { gql } from "../../../__generated__/gql";

export const GET_USERS = gql(`
query users {
  users {
    id
    email
    firstName
    lastName
    fullName
    type
  }
}
`);

export const GET_USER = gql(`
query user($id: String!) {
  users(where: {id: {equals: $id}}) {
    id
    email
    firstName
    lastName
    fullName
    type
    password
  }
}
`);

export const CREATE_USER = gql(`
mutation createUser($email: String!, $firstName: String!, $lastName: String!, $organisationId: String!, $password: String!) {
  createUser(
    email: $email
    firstName: $firstName
    lastName: $lastName
    organisationId: $organisationId
    password: $password
  ) {
    id
  }
}
`);

export const UPDATE_USER = gql(`
mutation updateUser($id:String!, $email: String, $firstName: String, $lastName: String, $password: String) {
  updateUser(user: {
    id: $id
    email: $email
    firstName: $firstName
    lastName: $lastName
    password: $password
  }
  ) {
    id
  }
}
`);

export const DELETE_USER = gql(`
mutation createUser($email: String!, $firstName: String!, $lastName: String!, $organisationId: String!, $password: String!) {
  createUser(
    email: $email
    firstName: $firstName
    lastName: $lastName
    organisationId: $organisationId
    password: $password
  ) {
    id
  }
}
`);

export const GET_ME = gql(`
query me {
  me {
    id
    firstName
    lastName  
    userRole: type
  }
}
`);
