import { gql } from "../../../__generated__";

export const UPDATE_FESTIVAL_SETTINGS =
  gql(`mutation updateFestivalSettingsMutation($minVersion:String!, $downForMaintenance: Boolean!, $editionId : String!, $hiddenMenuItems: String! $countdownDate: DateTime) {
    updateFestivalSettings(minVersion: $minVersion, downForMaintenance: $downForMaintenance, editionId: $editionId, hiddenMenuItems: $hiddenMenuItems, countdownDate: $countdownDate) {
      id
    }
  }`);

export const GET_FESTIVAL_SETTINGS = gql(`
query settings {
    settings {
      id
      minVersion
      downForMaintenance    
      editionId
      hiddenMenuItems
      countdownDate
    }
    versionNumber
  }`);
