import { gql } from "../../../__generated__/gql";

export const GET_EDITION = gql(`
query festivalEdition($id: String!) {
  festivalEdition(where: {
    id: $id
  }) {
    name
    year
    bands {
      id
      showcase
      band {
        id
        name
        description
        imageUrl
        bookings {
          id
          startTime
          endTime
        }
      }
      date
      duration
      stage {
        name
      }
      startTime
      endTime
    }
    days {
      id
      date
      name
    }
  }
}
`);

export const EXPORT_FESTIVAL =
  gql(`query clashfinderExport($name: String!, $id:String!) {
  clashfinderExport(name: $name, id:$id)
}`);
