import { gql } from "../../../__generated__/gql";

export const GET_POSTS = gql(`
query news($take: Int!, $skip: Int!) {
  posts(skip: $skip, take: $take, orderBy: { date: desc }) {
    id
    date
    description
    image
    title
  }
}
`);
