import { MelioraTheme } from "./types";

interface IHomeLayoutProps {
  theme: MelioraTheme;
}

export const LineupLayout = (props: IHomeLayoutProps) => {
  const {
    primaryColor,
    primaryColorText,
    primaryColorDarker,
  } = props.theme;
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: primaryColor,
        height: "100%",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          height: 50,
          backgroundColor: primaryColorDarker,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <text
          style={{
            color: primaryColorText,
          }}
        >
          Lineup
        </text>
      </div>
      <div
        style={{
          width: "100%",
          height: 100,
          backgroundColor: primaryColor,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <text
          style={{
            color: primaryColorText,
            fontSize: 35,
            margin: 20,
          }}
        >
          FRI
        </text>
        <text
          style={{
            color: primaryColorText,
            fontSize: 35,
            margin: 20,
          }}
        >
          SAT
        </text>
        <text
          style={{
            color: primaryColorText,
            fontSize: 35,
            margin: 20,
          }}
        >
          SUN
        </text>
      </div>

      <SearchBar theme={props.theme} />
      <Divider theme={props.theme} />
      <ArtistRow theme={props.theme} />
      <ArtistRow theme={props.theme} />
      <ArtistRow theme={props.theme} />
      <ArtistRow theme={props.theme} />
      <ArtistRow theme={props.theme} />
      <Divider theme={props.theme} />
      <ArtistRow theme={props.theme} />
      <ArtistRow theme={props.theme} />
    </div>
  );
};

const SearchBar = ({ theme }: { theme: Partial<MelioraTheme> }) => {
  const { primaryColor, primaryColorText } = theme;
  return (
    <div
      style={{
        height: 50,
        backgroundColor: primaryColor,
        paddingTop: 10,
        paddingLeft: 30,
      }}
    >
      <text style={{ color: primaryColorText }}>Search...</text>
    </div>
  );
};

const Divider = ({ theme }: { theme: Partial<MelioraTheme> }) => {
  const { primaryColorDarker, primaryColorText } = theme;
  return (
    <div
      style={{
        height: 40,
        backgroundColor: primaryColorDarker,
        paddingTop: 10,
        paddingLeft: 30,
      }}
    >
      <text style={{ color: primaryColorText }}>2024/01/01</text>
    </div>
  );
};

const ArtistRow = ({ theme }: { theme: Partial<MelioraTheme> }) => {
  const { primaryColor, primaryColorText } = theme;
  return (
    <div
      style={{
        height: 90,
        backgroundColor: primaryColor,
        // paddingTop: 10,
        paddingLeft: 100,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        marginBottom: 2,
      }}
    >
      <text style={{ color: primaryColorText, fontSize: 20 }}>Band Name</text>
      <text style={{ color: primaryColorText, fontSize: 10 }}>Stage Name</text>
    </div>
  );
};
