import { Button, InputLabel, Typography } from "@mui/material";
import { useExportMiscAppConfig } from "./helpers";
import { Label } from "@mui/icons-material";
import { confirmAlert } from "react-confirm-alert";

export const UpdateMiscConfigButton = () => {
  const [exportMiscAppConfig, { loading }] = useExportMiscAppConfig();
  const handlePress = () => {
    confirmAlert({
      title: "Publish Settings",
      message: `This will release all changes to 
      Configs, Vendors, Drinks, Showcase, Links and the Map
      `,
      buttons: [
        {
          label: "Yes",
          onClick: () => exportMiscAppConfig(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 8,
      }}
    >
      <Button
        variant="contained"
        color="secondary"
        onClick={handlePress}
        style={{ marginLeft: 8 }}
      >
        Update All Config
      </Button>
    </div>
  );
};
