import { MelioraTheme } from "./types";

interface IHomeLayoutProps {
  theme: MelioraTheme;
}

export const ClashfinderLayout = (props: IHomeLayoutProps) => {
  const { primaryColor, primaryColorDarker, primaryColorText } = props.theme;
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: primaryColor,
        height: "100%",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          height: 50,
          backgroundColor: primaryColorDarker,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <text
          style={{
            color: primaryColorText,
          }}
        >
          Clashfinder
        </text>
      </div>

      <Divider theme={props.theme} />

      {/* <ArtistRow theme={props.theme} />
      <ArtistRow theme={props.theme} />
      <ArtistRow theme={props.theme} />
      <ArtistRow theme={props.theme} />
      <ArtistRow theme={props.theme} />
      <Divider theme={props.theme} />
      <ArtistRow theme={props.theme} /> */}
      <Backround theme={props.theme} />
    </div>
  );
};

const SearchBar = ({ theme }: { theme: Partial<MelioraTheme> }) => {
  const { primaryColor, primaryColorText } = theme;
  return (
    <div
      style={{
        height: 50,
        backgroundColor: primaryColor,
        paddingTop: 10,
        paddingLeft: 30,
      }}
    >
      <text style={{ color: primaryColorText }}>Search...</text>
    </div>
  );
};

const Divider = ({ theme }: { theme: Partial<MelioraTheme> }) => {
  const { primaryColorDarker, primaryColorText } = theme;
  return (
    <div
      style={{
        height: 40,
        backgroundColor: primaryColorDarker,
        paddingTop: 10,
        paddingLeft: 80,
        display: "flex",
        gap: 60,
      }}
    >
      <text style={{ color: primaryColorText }}>Main Stage</text>
      <text style={{ color: primaryColorText }}>Second Stage</text>
    </div>
  );
};

const Backround = ({ theme }: { theme: Partial<MelioraTheme> }) => {
  const { primaryColorDark } = theme;
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: primaryColorDark,
        // paddingTop: 10,
        paddingLeft: 100,
        display: "flex",
        marginBottom: 2,
        position: "relative",
      }}
    >
      <Time top={0} left={10} height={90} />
      <Time top={150} left={10} height={90} />
      <Time top={300} left={10} height={90} />
      <Time top={450} left={10} height={90} />
      <Time top={600} left={10} height={90} />

      <Band theme={theme} top={30} left={80} height={90} />
      <Band theme={theme} top={140} left={80} height={90} />
      <Band theme={theme} top={250} left={80} height={240} />
      <Band theme={theme} top={510} left={80} height={240} />
      <Band theme={theme} top={30} left={225} height={90} />
      <Band theme={theme} top={140} left={225} height={90} />
      <Band theme={theme} top={250} left={225} height={240} />
      <Band theme={theme} top={510} left={225} height={240} />
    </div>
  );
};

const Band = ({
  top,
  left,
  height,
  theme
}: {
  top: number;
  left: number;
  height: number;
  theme: Partial<MelioraTheme>
}) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        width: 100,
        height,
        borderRadius: 12,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top,
        left,
      }}
    >
      <text style={{ fontSize: 16, color: theme.alternativeTextColor}}>Artist Name</text>
      <text style={{ fontSize: 10, color: theme.alternativeTextColor}}>time - time</text>
    </div>
  );
};

const Time = ({
  top,
  left,
  height,
}: {
  top: number;
  left: number;
  height: number;
}) => {
  return (
    <div
      style={{
        width: 40,
        height,
        borderRadius: 12,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top,
        left,
      }}
    >
      <text style={{ fontSize: 16, rotate: "270deg", color: "white" }}>
        time
      </text>
      <div
        style={{
          width: 800,
          marginTop: -12,
          height: 1,
          backgroundColor: "rgba(255,255,255,0.35)",
        }}
      ></div>
    </div>
  );
};
