import { useMutation } from "@apollo/client";
import { UPDATE_FESTIVAL_SETTINGS } from "./graphql";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  Container,
  FormLabel,
  Hidden,
  Input,
  TextField,
} from "@mui/material";
import "react-image-crop/dist/ReactCrop.css";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import semver from "semver";

import { PageContainer } from "../../components/PageContainer";
import { ButtonContainer } from "../../components/ButtonContainer";
import { Setting } from "../../__generated__/graphql";
import { Note } from "../../components/Note";
import { EditionPicker } from "../../components/EditionPIcker";
import { ChangeEvent } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const menuItems = ["VIP", "Map", "Debug", "Showcase"];

const schema = yup
  .object({
    minVersion: yup
      .string()
      .required()
      .test(
        "semver-valid",
        "is not a valid version number",
        (value) => !!semver.valid(value)
      ),
    downForMaintenance: yup.boolean().required(),
    editionId: yup.string().required(),
    hiddenMenuItems: yup.string(),
    countdownDate: yup.date().nullable(),
    checkboxValues: yup
      .array()
      .of(yup.object({ name: yup.string(), checked: yup.bool() })),
  })
  .required();

export interface ISettingsProps {
  setting: Setting;
  versionNumber: string;
}

export const SettingsForm = ({ setting, versionNumber }: ISettingsProps) => {
  const defaultCheckboxes = menuItems.map((item) => {
    const checked = setting.hiddenMenuItems?.includes(item);

    return {
      name: item,
      checked: !!checked,
    };
  });

  const [updateSettings, { loading: updateLoading, error }] = useMutation(
    UPDATE_FESTIVAL_SETTINGS,
    {
      onCompleted: () => {
        toast("settings updated");
      },
    }
  );
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      downForMaintenance: setting.downForMaintenance,
      minVersion: setting.minVersion,
      editionId: setting.editionId,
      hiddenMenuItems: setting.hiddenMenuItems ?? "",
      checkboxValues: defaultCheckboxes,
      countdownDate: setting.countdownDate,
    },
  });
  const onSubmit = (val: any) => {
    const {
      minVersion,
      downForMaintenance,
      editionId,
      hiddenMenuItems,
      checkboxValues,
      countdownDate,
    } = val;

    const names = checkboxValues.flatMap((item: any) => {
      if (!item.checked) return [];
      return [item.name];
    });

    updateSettings({
      variables: {
        minVersion,
        downForMaintenance,
        editionId,
        hiddenMenuItems: names.toString(),
        countdownDate,
      },
    });
  };
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "checkboxValues", // unique name for your Field Array
    }
  );

  return (
    <PageContainer title="App Settings">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container style={{ flexDirection: "column", display: "flex" }}>
          <TextField
            id="outlined-basic"
            label="Minimum version"
            variant="outlined"
            {...register("minVersion")}
          />
          <p>{errors.minVersion?.message}</p>
          <TextField
            id="outlined-basic"
            label="Down for maintenance"
            variant="outlined"
            // defaultValue={false}
            {...register("downForMaintenance")}
          />
          <p>{errors.downForMaintenance?.message}</p>
          <Controller
            control={control}
            name="editionId"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <>
                <EditionPicker
                  {...register("editionId")}
                  handleChange={onChange}
                  defaultValue={value}
                  value={value}
                />
              </>
            )}
          />
          <p>{errors.editionId?.message}</p>
          {/* <TextField
            id="outlined-basic"
            label="Countdown Date"
            variant="outlined"
            // defaultValue={false}
            {...register("countdownDate")}
          /> */}
          <Controller
            control={control}
            name="countdownDate"
            render={({ field: { onChange, onBlur, value, ref } }) => {
              console.log("date value", value);
              return (
                <DateTimePicker
                  format="YYYY/MM/DD HH:mm"
                  ampm={false}
                  timezone="Europe/London"
                  label="Countdown Date"
                  // @ts-ignore
                  defaultValue={value ? dayjs(value): null}
                  onChange={onChange}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="hiddenMenuItems"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Input
                type="hidden"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          <p>{JSON.stringify(errors).replace("{", "").replace("}", "")}</p>

          <h3>Hidden tabs</h3>

          {fields.map((row, index) => (
            <Controller
              key={row.name}
              control={control}
              name={`checkboxValues.${index}.name`}
              render={({ field }) => (
                <>
                  <FormLabel>{row.name}</FormLabel>
                  <Checkbox
                    id="outlined-basic"
                    {...field}
                    defaultChecked={row.checked}
                    onChange={(
                      event: ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) => {
                      setValue(`checkboxValues.${index}.checked`, checked);
                    }}
                  />
                </>
              )}
            />
          ))}

          <ButtonContainer>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </ButtonContainer>
        </Container>
      </form>
      <Note message="This is the Danger Zone: Superadmin required." />
      <div style={{ paddingTop: 20 }}>Info:</div>
      <div>Env Name:{process.env.REACT_APP_ENV_NAME}</div>
      <div>Bucket: {process.env.REACT_APP_BUCKET_URL}</div>
      <div>Backend Version: {versionNumber}</div>
    </PageContainer>
  );
};
