import { gql } from "../../../__generated__";

export const GET_BANDS = gql(`
query bandsForPicker($orgId: String!) {
  bands(where: { organisationId: { equals: $orgId } }) {
    id
    name
  }
}
`);
