import { Button, Input } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { PageContainer } from "../../components/PageContainer";
import { Details } from "@mui/icons-material";
import CropImage from "./crop";
import React from "react";
import { gzipSync } from "zlib";
import { archiveFiles } from "./helpers/archiver";
import { useMutation, useQuery } from "@apollo/client";
import { s3Put } from "../band/s3";
import { ADD_ASSET } from "./graphql";
import { updateConfig } from "./helpers/configs";
import { GET_ASSET_CONFIG, UPDATE_ASSET_CONFIG } from "../meta/config/graphql";
import { toast } from "react-toastify";
import { AssetConfigQuery } from "../../__generated__/graphql";
import { useParams } from "react-router-dom";

export const aspectRatios: { [key in SelectedPreview]: number } = {
  home_background: 1705 / 1206,
  home_logo: 1756 / 1543,
  menu_background: 1867 / 4944,
  adaptive_icon: 1200 / 1200,
  adaptive_background: 1200 / 1200,
  ios_icon: 1200 / 1200,
  thumbnail: 1200 / 1200,
  ic_stat_onesignal_default: 96 / 96,
  ic_onesignal_large_icon_default: 256 / 256,
};
export const getBrandingUrl = (
  data: AssetConfigQuery,
  iconName: string,
  id: string
) => {
  const fileName = data?.assetConfig?.value["branding"]?.[iconName];
  console.log("fileName", fileName);
  return `https://meliora-meta-assets.s3.eu-west-2.amazonaws.com/${id}/branding/${fileName}`;
};

const Branding = () => {
  const [active, setActive] = useState<SelectedPreview>("menu_background");
  const { id } = useParams();
  const [updateAssetConfig] = useMutation(UPDATE_ASSET_CONFIG, {
    onCompleted: () => {
      toast.success("Updated Config");
    },
  });
  const { data, loading } = useQuery(GET_ASSET_CONFIG, {
    variables: {
      id: id!,
    },
  });
  const [getAssetUploadUrl] = useMutation(ADD_ASSET);
  const [details, setDetails] = useState<ImageDetails>({
    home_background: {
      ref: React.createRef(),
    },
    home_logo: {
      ref: React.createRef(),
    },
    menu_background: {
      ref: React.createRef(),
    },
  } as ImageDetails);

  useEffect(() => {
    if (!data?.assetConfig?.value || data.assetConfig.value === "undefined")
      return;

    setDetails({
      // @ts-ignore
      home_background: {
        ref: React.createRef(),
        crop: getBrandingUrl(data, "home_background", id!),
      },
      // @ts-ignore
      home_logo: {
        ref: React.createRef(),
        crop: getBrandingUrl(data, "home_logo", id!),
      },
      // @ts-ignore
      menu_background: {
        ref: React.createRef(),
        crop: getBrandingUrl(data, "menu_background", id!),
      },
    });
  }, [data?.assetConfig]);

  const handleSave = async () => {
    // this probably needs to be controlled by the server
    const basePath = `${id}/branding`;
    const brandingConfig = {};

    // console.log('archiveFiles', JSON.stringify(details, null,2))

    // return

    Object.keys(details).map(async (key) => {
      const fileBlob = await fetch(details[key as SelectedPreview].crop!).then(
        (r) => r.blob()
      );

      const item = details[key as SelectedPreview];
      const fileName = item?.file?.name;
      const split = item?.file.name.split(".");
      const extension = split[split.length - 1];
      const imageName = `${key}.${extension}`;

      if (!imageName) return;

      const filepath = `${basePath}/${imageName}`;
      const targeturl = (
        await getAssetUploadUrl({ variables: { key: filepath } })
      ).data?.addAssets;

      await s3Put(targeturl, fileBlob);

      // @ts-ignore
      brandingConfig[key] = imageName;

      return;
    });
    const configs = await archiveFiles(details, "branding", true);

    const targeturl = (
      await getAssetUploadUrl({
        variables: { key: `${basePath}/branding.zip` },
      })
    ).data?.addAssets;

    // reloading the zip might be problematic
    // if it removes the other files.
    await s3Put(targeturl, configs?.zipFile as Blob);

    const updatedConfig = updateConfig(data, {}, brandingConfig);

    updateAssetConfig({
      variables: {
        id: id!,
        value: updatedConfig.value,
      },
    });
  };

  const setImageDetail = ({
    key,
    image,
    file,
    clearCrop,
  }: {
    key: string;
    file: any;
    image: string;
    clearCrop?: boolean;
  }) => {
    const currentItem = details[key as SelectedPreview];

    const updatedItems = {
      ...details,
      [key]: {
        ...currentItem,
        key,
        file,
        image,
        aspectRatio: aspectRatios[key as SelectedPreview] as number,
        crop: clearCrop === true ? undefined : currentItem.crop,
      },
    };

    setDetails(updatedItems);
  };

  const setCrop = (key: SelectedPreview, crop: string | undefined) => {
    console.log("setCrop", key);
    const item = details[key];

    const updatedItems = {
      ...details,
      [key]: {
        ...item,
        crop,
      },
    };

    setDetails(updatedItems);
  };

  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);

  return (
    <PageContainer title="Setup Branding">
      <a
        ref={hiddenAnchorRef}
        download
        style={{
          position: "absolute",
          top: "-200vh",
          visibility: "hidden",
        }}
      >
        Hidden download
      </a>
      <Button
        onClick={() => setActive("menu_background")}
        variant={active === "menu_background" ? "contained" : "outlined"}
      >
        Menu Background
      </Button>
      <Button
        onClick={() => setActive("home_background")}
        variant={active === "home_background" ? "contained" : "outlined"}
      >
        Home Background
      </Button>
      <Button
        onClick={() => setActive("home_logo")}
        variant={active === "home_logo" ? "contained" : "outlined"}
      >
        Home Logo
      </Button>
      <Button onClick={() => handleSave()} color="success" variant="contained">
        Save to s3 / database
      </Button>
      <Button
        onClick={() => archiveFiles(details, "branding")}
        color="success"
        variant="contained"
      >
        Download
      </Button>
      <PhoneMockup
        selectedPreview={active}
        key={active}
        imageDetails={details}
        setImageDetail={setImageDetail}
        setCrop={setCrop}
      />
    </PageContainer>
  );
};

export type SelectedPreview =
  | "menu_background"
  | "home_background"
  | "home_logo"
  | "adaptive_icon"
  | "adaptive_background"
  | "ios_icon"
  | "thumbnail"
  | "ic_stat_onesignal_default"
  | "ic_onesignal_large_icon_default";

// const item = typeof SelectedPreview
// keyof typeof SelectedPreview
// const vals = keyof typeof SelectedPreview
export type ImageDetail = {
  key: SelectedPreview;
  image: string;
  file: File;
  ref: React.RefObject<HTMLCanvasElement> | undefined;
  aspectRatio: number;
  crop: string | undefined;
  size: number | undefined;
};

export type ImageDetails = {
  [key in SelectedPreview]: ImageDetail;
};

// dimensions in mm
const iPhone15 = { width: 71.6, height: 146 };
const iPhone15ProMax = { width: 76.7, height: 159.9 };
const pixel8Pro = { height: 162.6, width: 76.5 };
const s22Ultra = { width: 77.9, height: 163.3 };
const iPhone12Mini = { height: 132, width: 64 };

const PhoneMockup = ({
  selectedPreview,
  imageDetails,
  setImageDetail,
  setCrop,
}: {
  selectedPreview: SelectedPreview;
  imageDetails: ImageDetails;
  setImageDetail: ({
    file,
    image,
    key,
    clearCrop,
  }: {
    file: any;
    image: string;
    key: string;
    clearCrop?: boolean;
  }) => void;
  setCrop: (key: SelectedPreview, crop: string | undefined) => void;
}) => {
  const scale = 6;
  const scaledDimensions = {
    height: iPhone15.height * scale,
    width: iPhone15.width * scale,
  };

  const imageDetail = imageDetails[selectedPreview];
  const menuBackgroundDetail = imageDetails["menu_background"];
  const homeLogoDetail = imageDetails["home_logo"];
  const homeBackgroundDetail = imageDetails["home_background"];

  const { image, file } = menuBackgroundDetail ?? { image: undefined };
  const { image: homeLogo } = homeLogoDetail ?? { image: undefined };
  const { image: homeBacground } = homeBackgroundDetail ?? {
    image: undefined,
  };

  const onFileChange = async (event: any) => {
    try {
      const file = event.target.files[0];

      const obj = URL.createObjectURL(event.target.files[0]);
      console.log("EVENT OBJ", event.target.files);
      // const newRef = React.createRef();
      // console.log("file", file);

      // setCrop(selectedPreview, undefined);

      // setTimeout(() => {
      setImageDetail({
        key: selectedPreview,
        image: obj,
        file: file,
        clearCrop: true,
      });
      // }, 0);
    } catch (err) {
      console.log(err);
    }
  };

  const menuIsActive = selectedPreview === "menu_background";

  return (
    <div>
      <Input
        // key={`${selectedPreview}-image-input`}
        type="file"
        onChange={onFileChange}
        name="logo"
        placeholder="logo"
      />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            ...scaledDimensions,
            border: "1px solid black",
            borderRadius: 16,
            overflow: "hidden",
            position: "relative",
          }}
        >
          {/* {selectedPreview === "home_background" && ( */}
          <>
            <HomeHeaderBackground
              imageDetail={homeBackgroundDetail}
              image={homeBacground}
              menuIsActive={menuIsActive}
            >
              <HomeHeaderLogo image={homeLogo} imageDetail={homeLogoDetail} />
            </HomeHeaderBackground>
          </>
          {/* )} */}
          {/* {selectedPreview === "home_logo" && (
          <>
            <HomeHeaderBackground image={undefined}>
              <HomeHeaderLogo image={image} />
            </HomeHeaderBackground>
          </>
        )} */}
          {menuIsActive && (
            <MenuBackground image={image} imageDetail={menuBackgroundDetail} />
          )}
        </div>
        {!imageDetail?.crop && (
          <CropImage
            key={`cropImage-${imageDetail?.image}`}
            file={image}
            imageDetail={imageDetail}
            handleCroppedFile={setCrop}
          />
        )}
      </div>
    </div>
  );
};

export const MenuBackground = ({
  image,
  imageDetail,
}: {
  image?: string;
  imageDetail: ImageDetail;
}) => {
  const hasCrop = !!imageDetail?.crop;
  console.log("crop!", !!imageDetail?.crop);

  return (
    <>
      {hasCrop && (
        <img
          src={imageDetail?.crop}
          style={{
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            width: "75%", // app is about 70%
            height: "100%",
            backgroundColor: "purple",
            zIndex: 5,
          }}
        />
      )}
      {!hasCrop && (
        <canvas
          // key={imageDetail?.key}
          ref={imageDetail?.ref ?? undefined}
          style={{
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            width: "75%", // app is about 70%
            height: "100%",
            backgroundColor: "purple",
            zIndex: 5,
          }}
        />
      )}
    </>
  );
};

export const HomeHeaderBackground: React.FC<
  React.PropsWithChildren<{
    image?: string;
    menuIsActive: boolean;
    imageDetail: ImageDetail;
  }>
> = ({ children, image, menuIsActive, imageDetail }) => {
  const hasCrop = !!imageDetail?.crop;

  return (
    <div
      style={{
        position: "relative",
        width: "100%", // app is about 70%
        height: "30%",
        backgroundColor: "red",
        // actual props
        objectFit: "cover",
        display: "flex",
        // paddingTop: 45,
        justifyContent: "center",
        opacity: menuIsActive ? 0.6 : 1,
      }}
    >
      {hasCrop && (
        <img
          src={imageDetail?.crop}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            zIndex: 0,
          }}
        />
      )}
      {!hasCrop && (
        <canvas
          ref={imageDetail?.ref ?? undefined}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            zIndex: 0,
          }}
        />
      )}
      {children}
    </div>
  );
};

export const HomeHeaderLogo = ({
  image,
  imageDetail,
}: {
  image?: string;
  imageDetail: ImageDetail;
}) => {
  const hasCrop = !!imageDetail?.crop;
  return (
    <>
      {hasCrop && (
        <img
          src={imageDetail?.crop}
          style={{
            objectFit: "contain",
            width: "50%", // app is about 70%
            height: "50%",
            // backgroundColor: "blue",
            // actual props
            alignSelf: "center",
            zIndex: 1,
          }}
        />
      )}
      {!hasCrop && (
        <canvas
          ref={imageDetail?.ref ?? undefined}
          style={{
            objectFit: "contain",
            width: "50%", // app is about 70%
            height: "50%",
            // backgroundColor: "blue",
            // actual props
            alignSelf: "center",
            zIndex: 1,
          }}
        />
      )}
    </>
  );
};

export default Branding;
