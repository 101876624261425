import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { EXPORT_FESTIVAL, GET_EDITION } from "./graphql";
import TableContainer from "@mui/material/TableContainer";
import {
  Pagination,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { BandBooking } from "../../__generated__/graphql";
import dayjs from "dayjs";
import BandsTable from "./tables";
import BandsTables from "./tables";
import { toast } from "react-toastify";
import { PageContainer } from "../../components/PageContainer";
import { LoadingOverlay } from "../../components/Loading";

const EditionBands = () => {
  const { id } = useParams();
  const { data, error, loading } = useQuery(GET_EDITION, {
    variables: {
      id: id!,
    },
    fetchPolicy: "cache-first",
  });
  const [bands, setBands] = useState<Map<string, BandBooking[]>>(
    new Map<string, BandBooking[]>()
  );
  const [selectedDate, setSelectedDate] = useState<string | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!data?.festivalEdition?.bands) return;

    const bandsPerDay: Map<string, BandBooking[]> =
      data.festivalEdition?.bands.reduce((prev, next) => {
        const date = dayjs(next.startTime).utc(true);
        let effectDate = date.format("YYYY/MM/DD");

        if (date.hour() < 5) {
          effectDate = date.subtract(1, "day").format("YYYY/MM/DD");
        }

        const bands = prev.get(effectDate) ?? [];

        prev.set(effectDate, [...bands, next]);

        return prev;
      }, new Map());
    setBands(bandsPerDay);
  }, [data]);
  const [exportFestival, { loading: updateLoading }] = useLazyQuery(
    EXPORT_FESTIVAL,
    {
      onCompleted: (data) => {
        console.log("data", data);
        toast("App updated");
        setTimeout(
          () => navigate(`/notifications/${data.clashfinderExport}`),
          2000
        );
      },
      onError: () => {
        toast("Error updating lineup");
      },
    }
  );

  const handleSelectDay = (selectedDate: string) => {
    setSelectedDate(selectedDate);
  };

  const keys = Array.from(bands?.keys());

  const selectedBands = bands.get(selectedDate ?? keys[0]);

  const handleExport = () => {
    let name = data?.festivalEdition?.name!;

    exportFestival({
      variables: {
        name: name,
        id: id!,
      },
    });
  };

  return (
    <LoadingOverlay loading={loading}>
      <PageContainer title={data?.festivalEdition?.name ?? ""}>
        {keys.map((item) => (
          <Button onClick={() => handleSelectDay(item)}>
            {dayjs(item).format("dddd DD")}
          </Button>
        ))}
        <Button
          variant="contained"
          style={{ margin: 16 }}
          onClick={() => {
            navigate("/booking/create", {
              state: {
                festivalId: id,
              },
            });
          }}
        >
          Create Booking
        </Button>
        <Button variant="contained" onClick={handleExport}>
          Update App
        </Button>
        {selectedBands && <BandsTables bands={selectedBands} />}
      </PageContainer>
    </LoadingOverlay>
  );
};

export default EditionBands;
