import { gql } from "@apollo/client";

export const ADD_ASSET = gql(`
mutation addAssets($key: String!){
  addAssets(key: $key)
}
`);

export const EXPORT_META_CONFIG = gql(`
mutation getExportMetaConfigUrl($key: String!){
  getExportMetaConfigUrl(key: $key)
}
`);
