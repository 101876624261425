import { gql } from "@apollo/client";

export const GET_CONFIG = gql(`
query config { 
    configs {
        keyId: key
        key
        value
  }
  }`);

export const UPDATE_CONFIG = gql(`
mutation createConfig($settings: [ConfigInputType]!) { 
    updateConfig(settings: $settings)
}
`);

export const DELETE_CONFIG = gql(`
mutation deleteConfig($key: String!) { 
    deleteConfig(key: $key) {
        key
    }
}
`);

