import { Button, Input } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { PageContainer } from "../../components/PageContainer";
import { Details } from "@mui/icons-material";
import CropImage from "./crop";
import React from "react";
import { gzipSync } from "zlib";
import { archiveFiles } from "./helpers/archiver";
import {
  ImageDetail,
  ImageDetails,
  SelectedPreview,
  aspectRatios,
} from "./preview";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_ASSET } from "./graphql";
import { s3Put } from "../band/s3";
import {
  GET_APP_CONFIGS,
  GET_ASSET_CONFIG,
  UPDATE_ASSET_CONFIG,
} from "../meta/config/graphql";
import { toast } from "react-toastify";
import { updateConfig } from "./helpers/configs";
import { LoadingOverlay } from "../../components/Loading";
import { AssetConfigQuery } from "../../__generated__/graphql";
import { useParams } from "react-router-dom";

const Icons = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(GET_ASSET_CONFIG, {
    variables: {
      id: id!
    },
  });
  const getIconUrl = (data: AssetConfigQuery, iconName: string, _id: string) => {
    const fileName = data?.assetConfig?.value["icons"]?.[iconName];  
    return `https://meliora-meta-assets.s3.eu-west-2.amazonaws.com/${id}/icons/${fileName}`;
  };
  
  const [updateAssetConfig] = useMutation(UPDATE_ASSET_CONFIG, {
    onCompleted: () => {
      toast.success("Updated Config");
    },
  });
  const [active, setActive] = useState<SelectedPreview>("adaptive_icon");
  const [getAssetUploadUrl] = useMutation(ADD_ASSET);
  const [details, setDetails] = useState<ImageDetails>({
    adaptive_icon: {
      ref: React.createRef(),
    },
    adaptive_background: {
      ref: React.createRef(),
    },
    ios_icon: {
      ref: React.createRef(),
    },
    thumbnail: {
      ref: React.createRef(),
    },
    ic_stat_onesignal_default: {
      ref: React.createRef(),
      size: 96, // onesignal specified
    },
    ic_onesignal_large_icon_default: {
      ref: React.createRef(),
      size: 256, // onesignal specified
    },
  } as ImageDetails);

  useEffect(() => {
    console.log("test", JSON.stringify(data?.assetConfig, null, 2));

    if (!data?.assetConfig?.value || data.assetConfig.value === "undefined")
      return;
    // console.log('got here')
    // console.log('v', typeof data?.assetConfig?.value, data?.assetConfig?.value === undefined)

    // const item =
    // console.log("ITEM", item);
    setDetails({
      // @ts-ignore
      adaptive_icon: {
        ref: React.createRef(),
        crop: getIconUrl(data, "adaptive_icon", id!),
      },
      // @ts-ignore
      adaptive_background: {
        ref: React.createRef(),
        crop: getIconUrl(data, "adaptive_background", id!),
      },
      // @ts-ignore
      ios_icon: {
        ref: React.createRef(),
        crop: getIconUrl(data, "ios_icon", id!),
      },
      // @ts-ignore
      thumbnail: {
        ref: React.createRef(),
        crop: getIconUrl(data, "ios_icon", id!),
      },
      // @ts-ignore
      ic_stat_onesignal_default: {
        ref: React.createRef(),
        size: 96, // onesignal specified
        crop: getIconUrl(data, "ic_stat_onesignal_default", id!),
      },
      // @ts-ignore
      ic_onesignal_large_icon_default: {
        ref: React.createRef(),
        size: 256, // onesignal specified
        crop: getIconUrl(data, "ic_onesignal_large_icon_default", id!),
      },
    });
  }, [data?.assetConfig]);

  const handleSave = async () => {
    // this probably needs to be controlled by the server
    const basePath = `${id}/icons`
    const brandingConfig = {};

    Object.keys(details).map(async (key) => {
      const fileBlob = await fetch(details[key as SelectedPreview].crop!).then(
        (r) => r.blob()
      );

      const item = details[key as SelectedPreview];
      const fileName = details[key as SelectedPreview].file?.name;

      if (!fileName) return;

      const split = item?.file.name.split(".");
      const extension = split[split.length - 1];
      const imageName = `${key}.${extension}`;
      
      const filepath = `${basePath}/${imageName}`;
      const targeturl = (
        await getAssetUploadUrl({ variables: { key: filepath } })
      ).data?.addAssets;

      await s3Put(targeturl, fileBlob);

      // @ts-ignore
      brandingConfig[key] = imageName;

      return;
    });
    const configs = await archiveFiles(details, "icons", true);

    const targeturl = (
      await getAssetUploadUrl({ variables: { key: `${basePath}/icons.zip` } })
    ).data?.addAssets;

    await s3Put(targeturl, configs?.zipFile as Blob);

    const updatedConfig = updateConfig(data, brandingConfig, {});

    updateAssetConfig({
      variables: {
        id: id!,
        value: updatedConfig.value,
      },
    });
  };

  const setImageDetail = ({
    key,
    image,
    file,
    clearCrop,
  }: {
    key: string;
    file: any;
    image: string;
    clearCrop?: boolean;
  }) => {
    const currentItem = details[key as SelectedPreview];
    console.log("key", key);

    const updatedItems = {
      ...details,
      [key]: {
        ...currentItem,
        key,
        file,
        image,
        aspectRatio: aspectRatios[key as SelectedPreview] as number,
        crop: clearCrop === true ? undefined : currentItem.crop,
      },
    };

    setDetails(updatedItems);
  };

  const setCrop = (key: SelectedPreview, crop: string | undefined) => {
    const item = details[key];

    const updatedItems = {
      ...details,
      [key]: {
        ...item,
        crop,
      },
    };

    setDetails(updatedItems);
  };

  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);

  if (loading) return <LoadingOverlay loading={true} />;

  return (
    <PageContainer title="Setup Branding - Icons">
      <a
        ref={hiddenAnchorRef}
        download
        style={{
          position: "absolute",
          top: "-200vh",
          visibility: "hidden",
        }}
      >
        Hidden download
      </a>
      <Button
        onClick={() => setActive("adaptive_icon")}
        variant={active === "adaptive_icon" ? "contained" : "outlined"}
      >
        adaptive_icon Icon
      </Button>
      <Button
        onClick={() => setActive("adaptive_background")}
        variant={active === "adaptive_background" ? "contained" : "outlined"}
      >
        Adaptive Background
      </Button>
      <Button
        onClick={() => setActive("ios_icon")}
        variant={active === "ios_icon" ? "contained" : "outlined"}
      >
        iOS Icon
      </Button>
      <Button
        onClick={() => setActive("thumbnail")}
        variant={active === "thumbnail" ? "contained" : "outlined"}
      >
        thumbnail
      </Button>
      <Button
        onClick={() => setActive("ic_onesignal_large_icon_default")}
        variant={
          active === "ic_onesignal_large_icon_default"
            ? "contained"
            : "outlined"
        }
      >
        Large notification icon
      </Button>
      <Button
        onClick={() => setActive("ic_stat_onesignal_default")}
        variant={
          active === "ic_stat_onesignal_default" ? "contained" : "outlined"
        }
      >
        Small notification icon
      </Button>
      <Button
        onClick={() => archiveFiles(details, "icons")}
        color="success"
        variant="contained"
      >
        Download
      </Button>
      <Button onClick={() => handleSave()} color="success" variant="contained">
        Save to S3 / database
      </Button>

      <IconMockup
        selectedPreview={active}
        key={active}
        imageDetails={details}
        setImageDetail={setImageDetail}
        setCrop={setCrop}
      />
    </PageContainer>
  );
};
// dimensions in mm
const iPhone15 = { width: 71.6, height: 146 };

const IconMockup = ({
  selectedPreview,
  imageDetails,
  setImageDetail,
  setCrop,
}: {
  selectedPreview: SelectedPreview;
  imageDetails: ImageDetails;
  setImageDetail: ({
    file,
    image,
    key,
    clearCrop,
  }: {
    file: any;
    image: string;
    key: string;
    clearCrop?: boolean;
  }) => void;
  setCrop: (key: SelectedPreview, crop: string | undefined) => void;
}) => {
  const scaledDimensions = {
    height: 500,
    width: 500,
  };

  const imageDetail = imageDetails[selectedPreview];

  const selectedDetail = imageDetails[selectedPreview];
  const adapativeIconBackground = imageDetails["adaptive_background"];
  const adapativeIconLogo = imageDetails["adaptive_icon"];
  const iosIcon = imageDetails["ios_icon"];

  const { image } = selectedDetail ?? { image: undefined };
  const { image: backgroundImage, file } = adapativeIconBackground ?? {
    image: undefined,
  };
  const { image: iconLogo } = adapativeIconLogo ?? { image: undefined };
  const { image: iosIconImage } = iosIcon ?? {
    image: undefined,
  };

  const onFileChange = async (event: any) => {
    try {
      const file = event.target.files[0];
      const name = file.name;

      const obj = URL.createObjectURL(event.target.files[0]);
      // console.log("EVENT OBJ", event.target.files);
      // const newRef = React.createRef();
      // console.log("file", file);

      // setCrop(selectedPreview, undefined);

      // setTimeout(() => {
      setImageDetail({
        key: selectedPreview,
        image: obj,
        file: file,
        clearCrop: true,
      });
      // }, 0);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Input
        inputProps={{ accept: ".png,.jpeg,.webp" }}
        type="file"
        onChange={onFileChange}
        name="logo"
        placeholder="logo"
      />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            ...scaledDimensions,
            border: !selectedPreview.includes("onesignal")
              ? "1px solid black"
              : undefined,
            borderRadius: 16,
            overflow: "hidden",
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {selectedPreview.includes("adaptive") && (
            <>
              <AdaptiveIconBackground
                imageDetail={adapativeIconBackground}
                image={backgroundImage}
              >
                <AdapativeIconLogo
                  image={iconLogo}
                  imageDetail={adapativeIconLogo}
                />
                {selectedPreview === "adaptive_icon" && (
                  <div
                    style={{
                      position: "absolute",
                      border: "108px solid black",
                      width: "100%",
                      height: "100%",
                      opacity: 0.5,
                    }}
                  ></div>
                )}
              </AdaptiveIconBackground>
            </>
          )}
          {selectedPreview === "ios_icon" && (
            <IosIcon image={iosIconImage} imageDetail={iosIcon} />
          )}
          {selectedPreview === "thumbnail" && (
            <IosIcon image={image} imageDetail={imageDetail} />
          )}
          {selectedPreview === "ic_onesignal_large_icon_default" && (
            <GenericIcon image={image} imageDetail={imageDetail} />
          )}
          {selectedPreview === "ic_stat_onesignal_default" && (
            <GenericIcon image={image} imageDetail={imageDetail} />
          )}
        </div>

        {!imageDetail?.crop && (
          <CropImage
            key={`cropImage-${imageDetail?.image}`}
            file={image}
            imageDetail={imageDetail}
            handleCroppedFile={setCrop}
          />
        )}
      </div>
    </div>
  );
};

export const IosIcon = ({
  image,
  imageDetail,
}: {
  image?: string;
  imageDetail: ImageDetail;
}) => {
  const hasCrop = !!imageDetail?.crop;
  console.log("crop!", !!imageDetail?.crop);

  return (
    <>
      {hasCrop && (
        <img
          src={imageDetail?.crop}
          style={{
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%", // app is about 70%
            height: "100%",
            // backgroundColor: "purple",
            zIndex: 5,
          }}
        />
      )}
      {!hasCrop && (
        <canvas
          // key={imageDetail?.key}
          ref={imageDetail?.ref ?? undefined}
          style={{
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%", // app is about 70%
            height: "100%",
            // backgroundColor: "purple",
            zIndex: 5,
          }}
        />
      )}
    </>
  );
};

export const GenericIcon = ({
  image,
  imageDetail,
}: {
  image?: string;
  imageDetail: ImageDetail;
}) => {
  const hasCrop = !!imageDetail?.crop;
  const { size } = imageDetail;

  return (
    <>
      {hasCrop && (
        <img
          src={imageDetail?.crop}
          style={{
            objectFit: "cover",
            // position: "absolute",
            width: size,
            height: size,
            zIndex: 5,
            alignSelf: "center",
            justifySelf: "center",
          }}
        />
      )}
      {!hasCrop && (
        <canvas
          // key={imageDetail?.key}
          ref={imageDetail?.ref ?? undefined}
          style={{
            objectFit: "cover",
            position: "absolute",
            width: size,
            height: size,
            alignSelf: "center",
            justifySelf: "center",
            zIndex: 5,
          }}
        />
      )}
    </>
  );
};

export const AdaptiveIconBackground: React.FC<
  React.PropsWithChildren<{
    image?: string;
    imageDetail: ImageDetail;
  }>
> = ({ children, image, imageDetail }) => {
  const hasCrop = !!imageDetail?.crop;

  return (
    <div
      style={{
        position: "relative",
        width: "100%", // app is about 70%
        height: "100%",
        // actual props
        objectFit: "cover",
        display: "flex",
        // paddingTop: 45,
        justifyContent: "center",
      }}
    >
      {hasCrop && (
        <img
          src={imageDetail?.crop}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            zIndex: 0,
          }}
        />
      )}
      {!hasCrop && (
        <canvas
          ref={imageDetail?.ref ?? undefined}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            zIndex: 0,
          }}
        />
      )}
      {children}
    </div>
  );
};

export const AdapativeIconLogo = ({
  image,
  imageDetail,
}: {
  image?: string;
  imageDetail: ImageDetail;
}) => {
  const hasCrop = !!imageDetail?.crop;
  return (
    <>
      {hasCrop && (
        <img
          src={imageDetail?.crop}
          style={{
            objectFit: "contain",
            width: "90%", // app is about 70%
            height: "90%",
            // backgroundColor: "blue",
            // actual props
            // padding: 150,
            alignSelf: "center",
            // justifyContent: 'center',
            zIndex: 1,
          }}
        />
      )}
      {!hasCrop && (
        <canvas
          ref={imageDetail?.ref ?? undefined}
          style={{
            objectFit: "contain",
            width: "90%", // app is about 70%
            height: "90%",
            // padding: "25%",
            // backgroundColor: "yellow",
            // actual props
            // padding: 150,
            alignSelf: "center",
            zIndex: 1,
          }}
        />
      )}
    </>
  );
};

export default Icons;
