import { useQuery } from "@apollo/client";
import {
  CircularProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import React from "react";
import { LinkButton } from "../../../components/LinkButton";
import { LoadingOverlay } from "../../../components/Loading";
import { PageContainer } from "../../../components/PageContainer";
import { BandBooking } from "../../../__generated__/graphql";
const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Name", width: 130 },
  {
    field: "bookings",
    headerName: "Bookings",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.bookings.length}`,
  },
];
interface IBandPerformances {
  bookings: BandBooking[];
}

const BandPerformances = ({ bookings }: IBandPerformances) => {
  return (
    <PageContainer title="Performances">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">name</TableCell>
              <TableCell align="left">bookings</TableCell>
              <TableCell align="left">actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookings.map((row) => (
              <TableRow
                key={row.edition.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{row.edition.name}</TableCell>
                <TableCell align="left">{row.stage.name}</TableCell>
                <TableCell align="left">
                  <LinkButton path={`/booking/${row.id}`} text="View" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageContainer>
  );
};

export default BandPerformances;
