import { useMutation } from "@apollo/client";
import { CREATE_USER } from "../graphql";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Container, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PageContainer } from "../../../components/PageContainer";
import { ButtonContainer } from "../../../components/ButtonContainer";

const schema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

export const UserForm = () => {
  const navigate = useNavigate();
  const [createUser, { loading: updateLoading, error }] = useMutation(
    CREATE_USER,
    {
      onCompleted: (data) => {
        toast("user created");
        navigate(`/users`);
      },
    }
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (val: any) => {
    createUser({
      variables: {
        ...val,
        organisationId: process.env.REACT_APP_ORG_ID ?? "",
      },
    });
  };

  return (
    <PageContainer title="Create User">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container style={{ flexDirection: "column", display: "flex" }}>
          <TextField
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            {...register("firstName")}
          />
          <p>{errors.firstName?.message}</p>
          <TextField
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            {...register("lastName")}
          />
          <p>{errors.lastName?.message}</p>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            {...register("email")}
          />
          <p>{errors.email?.message}</p>
          <TextField
            id="outlined-basic"
            label="Password"
            type="password"
            variant="outlined"
            {...register("password")}
          />
          <p>{errors.password?.message}</p>
          <ButtonContainer>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </ButtonContainer>
        </Container>
      </form>
    </PageContainer>
  );
};
