import { AssetConfigQuery } from "../../../__generated__/graphql";
import { MelioraTheme } from "../pages/types";
import { SelectedPreview } from "../preview";

export const updateConfig = (
  data: AssetConfigQuery | undefined,
  icons?: { [key: string]: string },
  branding?: { [key: string]: string },
  theme?: { [key: string]: string }
) => {
  // @ts-ignore
  const existingValue = data?.assetConfig?.value;

  const existingIcons = existingValue["icons"];

  const existingBranding = existingValue["branding"];
  const existingTheme = existingValue["theme"];

  const config = {
    ...(data?.assetConfig || {}),
    value: {
      ...existingValue,
      branding: {
        ...existingBranding,
        ...branding,
      },
      icons: {
        ...existingIcons,
        ...icons,
      },
      theme: {
        ...existingTheme,
        ...theme,
      },
    } as IAssetConfig,
  };
  return config;
};

export interface IAssetConfig {
  branding: BrandingConfig;
  icons: IIconConfig;
  theme: MelioraTheme;
}

export interface IIconConfig {
  adaptive_icon: string;
  adaptive_background: string;
  ios_icon: string;
  thumbnail: string;
  ic_stat_onesignal_default: string;
  ic_onesignal_large_icon_default: string;
}
export type BrandingConfig = {
  [key in SelectedPreview]: string;
};
