import { useMutation } from "@apollo/client";
import { CREATE_VENDOR } from "../graphql";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Container, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ButtonContainer } from "../../../../components/ButtonContainer";
import { PageContainer } from "../../../../components/PageContainer";
const schema = yup
  .object({
    name: yup.string().required(),
    description: yup.string().required(),
  })
  .required();

export const Vendor = () => {
  const navigate = useNavigate();
  const [createVendor, { loading: updateLoading, error }] = useMutation(
    CREATE_VENDOR,
    {
      onCompleted: (data) => {
        toast("vendor created");
        navigate(`/vendor/${data.createFoodVendor.id}`)
      },
    }
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  

  const onSubmit = (val: any) => {
    const { name, description } = val;
    createVendor({
      variables: {
        name,
        description,
      },
    });
  };

  return (
    <PageContainer title="Create Vendor">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container style={{ flexDirection: "column", display: "flex" }}>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            {...register("name")}
          />
          <p>{errors.name?.message}</p>
          <TextField
            id="outlined-basic"
            label="Description"
            variant="outlined"
            multiline={true}
            {...register("description")}
          />
          <p>{errors.description?.message}</p>
          <ButtonContainer>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </ButtonContainer>
        </Container>
      </form>
    </PageContainer>
  );
};
