import TableContainer from "@mui/material/TableContainer";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { BandBooking } from "../../__generated__/graphql";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import BandsTable from "./table";
interface IBandTableProps {
  bands: BandBooking[];
}

const BandsTables = ({ bands }: IBandTableProps) => {
  const [bandsPerStage, setBandsPerStage] = useState<
    Map<string, BandBooking[]>
  >(new Map<string, BandBooking[]>());
  useEffect(() => {
    if (!bands) return;

    const perStage: Map<string, BandBooking[]> = bands.reduce((prev, next) => {
      const key = next.stage.name;
      const bands = prev.get(key) ?? [];

      prev.set(key, [...bands, next]);

      return prev;
    }, new Map());
    setBandsPerStage(perStage);
  }, [bands]);
  const keys = Array.from(bandsPerStage?.keys());

  return (
    <>
      {keys.map((key) => {
        const bands = bandsPerStage.get(key);
        if (!bands) return null;
        return <BandsTable bands={bands} />;
      })}
    </>
  );
};

export default BandsTables;
