import { gql } from "../../../__generated__/gql";

export const GET_SPONSOR = gql(`
  query sponsor($id: String!) {
  sponsor(where: { id: $id }) {
    id
    name
    order
    value
  }
}
  `);

export const GET_ALL_SPONSORS = gql(`
query getSponsors {
  sponsors(orderBy: {order: asc}) {
    id
    name
    order
    value
  }
}
`);

export const DELETE_SPONSOR = gql(`
mutation deleteSponsor($sponsorId:String!) {
  deleteSponsor(sponsorId: $sponsorId) {
    id
    }
}
`);

export const CREATE_SPONSOR =
  gql(`mutation createSponsor($name: String!, $value: Json!) {
  createSponsor(
    name: $name,
    value: $value    
  ) {
    id
    name
    order
    value
  }
}`);

export const UPDATE_SPONSOR =
  gql(`mutation updateSponsor($id: String!,$name: String!, $order: Int!, $value: Json!) {
  updateSponsor(
    id: $id,
    name: $name,
    order: $order,
    value: $value    
  ) {
    id
    name
    order
    value
  }
}`);
