import { useMutation, useQuery } from "@apollo/client";
import { GET_USERS } from "./graphql";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import React from "react";
import { LinkButton } from "../../components/LinkButton";
import { PageContainer } from "../../components/PageContainer";
import { LoadingOverlay } from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import { UPDATE_BAND } from "../band/graphql";
import { toast } from "react-toastify";

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Name", width: 130 },
  {
    field: "bookings",
    headerName: "Bookings",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.bookings.length}`,
  },
];

const Users = () => {
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const navigate = useNavigate();
  const { data, error, loading, refetch } = useQuery(GET_USERS);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const [updateBand, { loading: updateLoading, error: addBandError }] =
    useMutation(UPDATE_BAND, {
      onCompleted: () => {
        refetch();
        toast("Update complete");
      },
    });

  const onAdd = (id: string) => {
    updateBand({
      variables: {
        id: id,
        showcase: true,
      },
    });
  };

  const onRemove = (id: string) => {
    updateBand({
      variables: {
        id: id,
        showcase: false,
      },
    });
  };

  return (
    <PageContainer title="Users">
      <TextField
        id="outlined-basic"
        label="Search"
        disabled
        variant="outlined"
        placeholder="Search..."
        style={{ width: "40%" }}
        onChange={(value) => setSearch(value.target.value)}
      />
      <LinkButton
        path="/user/create"
        text="Create user"
        variant={"contained"}
        style={{ marginLeft: 30 }}
      />
      <LoadingOverlay loading={loading}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">name</TableCell>
                <TableCell align="left">role</TableCell>
                <TableCell align="left">actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.users.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{row.fullName}</TableCell>
                  <TableCell align="left">{row.type}</TableCell>
                  <TableCell align="left">
                    <LinkButton path={`/user/edit/${row.id}`} text="Edit" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </LoadingOverlay>
    </PageContainer>
  );
};

export default Users;
