import { useLazyQuery } from "@apollo/client";
import { GET_EDITION } from "./graphql";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import "react-image-crop/dist/ReactCrop.css";
import EditionForm from './form'

const Edition = () => {
  const [getEdition, { data, loading }] = useLazyQuery(GET_EDITION);
  const { id } = useParams();
  useEffect(() => {
    if (!id) return;
    getEdition({ variables: { festivalId: id } });
  }, [id]);

  if (loading) return null;
  // aspect ratio 3∶2
  if (!data?.festivalEdition) return <div>edition not found</div>;

  return <EditionForm edition={data?.festivalEdition} />;
};

export default Edition;
