import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";
import "./LinkTypePicker.css";
import { LinkType } from "../../__generated__/graphql";

interface ILinkTypePickerProps {
  handleChange: (enumValue: string) => void;
  defaultValue?: string;
  value: string | undefined;
  placeholder?: string;
  controlStyle?: React.CSSProperties;
}

export const LinkTypePicker = ({
  handleChange,
  defaultValue,
  value,
  placeholder,
  controlStyle,
}: ILinkTypePickerProps) => {
  const options = Object.keys(LinkType).map((item) => ({
    name: item,
    value: item,
  }));
  let capitalized: string = defaultValue ?? "";

  capitalized = capitalized[0].toUpperCase() + capitalized.slice(1);

  return (
    <FormControl
      fullWidth
      style={{
        display: "flex",
        backgroundColor: "white",
        width: "100%",
        ...controlStyle,
      }}
    >
      <InputLabel id="demo-simple-select-label">Link Type</InputLabel>
      <Select
        variant="outlined"
        labelId="link-type-select"
        id="link-type-select"
        value={capitalized}
        label="Link type"
        defaultValue={capitalized}
        onChange={(event) => handleChange(event.target.value.toLowerCase())}
      >
        {options?.map((item) => {
          return <MenuItem value={item.name}>{item.value}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};
