import { CircularProgress } from "@mui/material";
import React, { PropsWithChildren } from "react";
interface ILoadingOverlayProps {
  loading: boolean;
}

export const LoadingOverlay: React.FC<PropsWithChildren<ILoadingOverlayProps>> = ({ loading, children }) => {
  if (!loading) return <>{children}</>;
  return (
    <div
      style={{
        position:'sticky',
        width: '100%',
        height: 600,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
};
