import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { apolloClient } from "./client";
import { ApolloProvider, useQuery } from "@apollo/client";
import { Outlet, useNavigate } from "react-router-dom";
import { AuthProvider } from "./routes/helpers";
import { Box, List, ListItemButton } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ILinkButtonProps } from "./components/LinkButton";
import ResponsiveDrawer from "./components/ResponsiveDrawer";
import { GET_ME } from "./routes/users/graphql";
import { UserRole } from "./__generated__/graphql";
dayjs.extend(utc);
dayjs.extend(timezone);

const defaultTheme = createTheme();

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ApolloProvider client={apolloClient}>
          <AuthProvider>
            <header className="App-links">
              <ResponsiveDrawer>
                <List component="nav">
                  <Routes />
                </List>
              </ResponsiveDrawer>
            </header>
            <CssBaseline />
            <Box
              component="main"
              className="main-background"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: "95vh",
                // width: '60vw'
              }}
            >
              {/* <Container
                maxWidth="xl"
                style={{flexGrow:1}}
                sx={{
                  sm: {
                    margin: 0,
                    padding: 0,
                  },
                }}
              > */}
              <Outlet />
              {/* </Container> */}
            </Box>
            <ToastContainer />
          </AuthProvider>
        </ApolloProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
const Routes = () => {
  const { data } = useQuery(GET_ME);

  const role = data?.me?.userRole;
  return (
    <>
      <NavigationItem
        path="/news"
        text="News"
        variant="contained"
        style={{ width: 200 }}
      />

      <NavigationItem
        path="/bands"
        text="Bands"
        variant="contained"
        style={{ width: 200 }}
      />

      <NavigationItem
        path="/editions"
        text="Festival Edition"
        variant="contained"
        style={{ width: 200 }}
      />

      <NavigationItem
        path="/notification/history"
        text="Notifications"
        variant="contained"
        style={{ width: 200 }}
      />

      <NavigationItem
        path="/links"
        text="Links"
        variant="contained"
        style={{ width: 200 }}
      />
      <NavigationItem
        path="/stages"
        text="Stages"
        variant="contained"
        style={{ width: 200 }}
      />
      <NavigationItem
        path="/drinks"
        text="Drinks"
        variant="contained"
        style={{ width: 200 }}
      />
      <NavigationItem
        path="/vendors"
        text="Vendors"
        variant="contained"
        style={{ width: 200 }}
      />
      <NavigationItem
        path="/map"
        text="Map"
        variant="contained"
        style={{ width: 200 }}
      />
      <NavigationItem
        path="/settings"
        text="Settings"
        variant="contained"
        style={{ width: 200 }}
      />
      <NavigationItem
        path="/information"
        text="Info"
        variant="contained"
        style={{ width: 200 }}
      />
      <NavigationItem
        path="/sponsors"
        text="Sponsors"
        variant="contained"
        style={{ width: 200 }}
      />
      {role === UserRole.Superadmin && (
        <>
          <p style={{ paddingLeft: 18, fontWeight: "bold", paddingTop: 28 }}>
            SuperAdmin only
          </p>
          <NavigationItem
            path="/image-tools"
            text="Image Tools (Admin)"
            variant="contained"
            style={{ width: 200 }}
          />
          {/* <NavigationItem
            path="/showcase"
            text="Showcase"
            variant="contained"
            style={{ width: 200 }}
          /> */}

          <NavigationItem
            path="/users"
            text="Users"
            variant="contained"
            style={{ width: 200 }}
          />

          {/* <NavigationItem
            path="/meta/config"
            text="Meta Config"
            variant="contained"
            style={{ width: 200 }}
          /> */}
          {/* <NavigationItem
            path="/config"
            text="Config"
            variant="contained"
            style={{ width: 200 }}
          /> */}
        </>
      )}
    </>
  );
};
const NavigationItem = (props: ILinkButtonProps) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(props.path);
  };
  return <ListItemButton onClick={handleClick}>{props.text}</ListItemButton>;
};

export default App;
