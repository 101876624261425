import { useMutation } from "@apollo/client";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Container, TextField } from "@mui/material";
import { type Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Info } from "@mui/icons-material";
import { FoodVendor } from "../../../../__generated__/graphql";
import { ButtonContainer } from "../../../../components/ButtonContainer";
import { PageContainer } from "../../../../components/PageContainer";
import { DELETE_VENDOR, UPDATE_VENDOR } from "../graphql";

const schema = yup
  .object({
    name: yup.string().required(),
    description: yup.string().required(),
    vendorType: yup.string(),
    image: yup.string().required(),
  })
  .required();

const VendorForm = ({ vendor }: { vendor: FoodVendor }) => {
  const navigate = useNavigate();
  const [updateVendor, { loading: updateLoading, error }] = useMutation(
    UPDATE_VENDOR,
    {
      onCompleted: () => {
        toast("Vendor updated");
      },
    }
  );
  const [deleteVendor] = useMutation(DELETE_VENDOR, {
    onCompleted: () => {
      toast("Vendor deleted");
      navigate("/vendors");
    },
  });
  const { id } = useParams();
  const [crop, setCrop] = useState<Crop>({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const handleDelete = () => {
    confirmAlert({
      title: "Delete this Vendor?",
      message: "Are you sure? this can't be undone",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteVendor({ variables: { vendorId: vendor.id } }),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const [imageUrl, setImageUrl] = useState(vendor.image ?? "");

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.updatedImageUrl) {
      setImageUrl(location?.state?.updatedImageUrl);
    }
  }, [location.state]);

  const onSubmit = (val: any) => {
    const { name, description, vendorType } = val;
    updateVendor({
      variables: {
        vendor: {
          id: vendor.id,
          name,
          description,
          image: imageUrl,
          type: vendorType,
        },
      },
    });
  };
  // aspect ratio 3∶2

  const imageKey = location?.state?.updated ?? "imageUrl";

  return (
    <PageContainer title={`Edit Vendor: ${vendor.name}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container style={{ flexDirection: "column", display: "flex" }}>
          <img
            src={imageUrl}
            width={500}
            style={{ paddingBottom: 8 }}
            key={imageKey}
          />
          <Button
            variant="contained"
            style={{ marginBottom: 18 }}
            onClick={() => {
              navigate("/image", {
                state: {
                  vendorId: vendor.id,
                },
              });
            }}
          >
            Update Image
          </Button>
          <TextField
            id="outlined-basic"
            label="image"
            variant="outlined"
            defaultValue={vendor?.image}
            {...register("image")}
            onChange={(value) => {
              setImageUrl(value.target.value);
            }}
          />
           <p>{errors.image?.message}</p>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            defaultValue={vendor?.name}
            {...register("name")}
          />
          <p>{errors.name?.message}</p>
          <TextField
            id="outlined-basic"
            label="Description"
            variant="outlined"
            multiline={true}
            defaultValue={vendor?.description}
            {...register("description")}
          />
          <p>{errors.description?.message}</p>
          <TextField
            id="outlined-basic"
            label="Vendor Type"
            variant="outlined"
            multiline={true}
            disabled
            defaultValue={vendor?.type || "food"}
            {...register("vendorType")}
          />
          <p>{errors.description?.message}</p>

          <ButtonContainer>
            <Button
              type="submit"
              variant="contained"
              style={{ marginTop: 18, marginBottom: 18 }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              onClick={handleDelete}
              style={{ backgroundColor: "red" }}
            >
              Delete Vendor
            </Button>
          </ButtonContainer>
        </Container>
      </form>
    </PageContainer>
  );
};

export default VendorForm;
