import { Container, TextField, Button, Box } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Post } from "../../../__generated__/graphql";
import { CREATE_POST } from "../graphql";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { PageContainer } from "../../../components/PageContainer";
import { FieldContainer } from "../../../components/FieldContainer";
import { ButtonContainer } from "../../../components/ButtonContainer";
import { useNavigate } from "react-router-dom";
import { DateTimePicker } from "@mui/x-date-pickers";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Note } from "../../../components/Note";
import { SocialLinks, defaultData as defaultSocials } from "../SocialLinks";
import dayjs from "dayjs";

const schema = yup
  .object({
    title: yup.string().required(),
    description: yup.string().required(),
    date: yup.string().required(),
    socials: yup.object({
      facebook: yup.string().url().optional(),
      tiktok: yup.string().url().optional(),
      twitter: yup.string().url().optional(),
      instagram: yup.string().url().optional(),
    }),
  })
  .required();

export const PostForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      date: dayjs(new Date()).format("yyyy-MM-dd hh:mm"),
      socials: defaultSocials,
    },
  });
  const [createPost, { loading: updateLoading, error }] = useMutation(
    CREATE_POST,
    {
      onCompleted: (data) => {
        toast("Added successfully");
        navigate(`/post/${data.createPost.id}`);
      },
    }
  );
  const onSubmit = (data: Partial<Post>) => {
    const { title, description, date, image, socials } = data;
    createPost({
      variables: {
        title: title!,
        description: description!,
        date: new Date(date).toString(),
        image: "",
        socials,
      },
    });
  };
  return (
    <PageContainer title="Create Post">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldContainer>
          <img src={""} width={500} style={{ paddingBottom: 8 }} />
          <TextField
            fullWidth
            id="outlined-basic"
            label="Name"
            variant="outlined"
            {...register("title")}
          />
          <p>{errors.title?.message}</p>
          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DateTimePicker
                format="YYYY/MM/DD HH:mm"
                ampm={false}
                timezone="Europe/London"
                label="Publish Date"
                // @ts-ignore
                // defaultValue={dayjs(getValues("date"), "yyyy/MM/dd hh:mm")}
                // @ts-ignore
                onChange={onChange} // send value to hook form
              />
            )}
          />
          <p>{errors.date?.message}</p>
          <Note
            message={`Note: you can schedule news posts by picking a date in the future - just publish the item as normal - the app won't load the news until the scheduled date and TIME`}
            style={{ marginTop: -40, marginBottom: 20 }}
          />
          <TextField
            fullWidth
            id="outlined-basic"
            label="Description"
            variant="outlined"
            multiline={true}
            {...register("description")}
          />
          <p>{errors.description?.message}</p>
          <Controller
            control={control}
            name="socials"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <SocialLinks
                data={value}
                // @ts-ignore
                onChange={onChange}
                errors={errors.socials}
              />
            )}
          />
          <ButtonContainer>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </ButtonContainer>
        </FieldContainer>
      </form>
    </PageContainer>
  );
};
