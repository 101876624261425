import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Container, TextField } from "@mui/material";
import "react-image-crop/dist/ReactCrop.css";
import { User } from "../../../__generated__/graphql";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PageContainer } from "../../../components/PageContainer";
import { ButtonContainer } from "../../../components/ButtonContainer";
import { DELETE_USER, UPDATE_USER } from "../graphql";

const schema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required(),
    type: yup.string().required(),
  })
  .required();

const UserForm = ({ user }: { user: User }) => {
  const navigate = useNavigate();
  const [updateUser, { loading: updateLoading, error }] = useMutation(
    UPDATE_USER,
    {
      onCompleted: () => {
        toast("User updated");
      },
    }
  );
  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: () => {
      toast("User deleted");
      navigate("/users");
    },
  });
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    control,
    formState: { errors, dirtyFields, isDirty },
  } = useForm({ resolver: yupResolver(schema) });

  const handleDelete = () => {
    confirmAlert({
      title: "Delete this User?",
      message: "Are you sure? this can't be undone",
      buttons: [
        {
          label: "Yes",
          onClick: () => {}, // deleteUser({ variables: { id: user.id } }),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const onSubmit = (val: any) => {
    if (Object.keys(dirtyFields).length === 0)
      return toast("Nothing to update");

    const fields = Object.keys(dirtyFields);

    const updatedOnly = fields.reduce((prev, next) => {
      // @ts-ignore
      const value = getValues(next);

      return {
        ...prev,
        [next]: value,
      };
    }, {});

    // const result = Object.keys(val).flatMap((key) => {
    //   const item = val[key];

    //   if (fields.includes(key)) {
    //     return { [key]: item };
    //   }

    //   return [];
    // });

    // console.log("result!", JSON.stringify(result));

    // if()

    // const { name, description, showcase } = val;
    updateUser({
      variables: {
        id: user.id,
        ...updatedOnly,
      },
    });
  };

  return (
    <PageContainer title={`Edit User: ${user.fullName}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container style={{ flexDirection: "column", display: "flex" }}>
          <TextField
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            defaultValue={user?.firstName}
            {...register("firstName")}
          />
          <p>{errors.firstName?.message}</p>
          <TextField
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            defaultValue={user?.lastName}
            {...register("lastName")}
          />
          <p>{errors.lastName?.message}</p>
          <TextField
            id="outlined-basic"
            label="Password"
            variant="outlined"
            defaultValue={user?.email}
            {...register("email")}
          />
          <p>{errors.email?.message}</p>
          <TextField
            id="outlined-basic"
            label="Password"
            type="password"
            variant="outlined"
            defaultValue={user?.password}
            {...register("password")}
          />
          <p>{errors.password?.message}</p>
          <TextField
            id="outlined-basic"
            label="Type"
            variant="outlined"
            defaultValue={user?.type}
            {...register("type")}
          />
          <p>{errors.type?.message}</p>

          <ButtonContainer>
            <Button
              type="submit"
              variant="contained"
              disabled={!isDirty}
              style={{ marginTop: 18, marginBottom: 18 }}
            >
              Save
            </Button>
            {/* <Button
              variant="contained"
              onClick={handleDelete}
              style={{ backgroundColor: "red" }}
            >
              Delete band
            </Button> */}
          </ButtonContainer>
        </Container>
      </form>
    </PageContainer>
  );
};

export default UserForm;
