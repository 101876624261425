import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

// App.tsx
const API = process.env.REACT_APP_API

const getCurrentUserToken = () => {
  const token = localStorage.getItem("token");

  if (token) return JSON.parse(token);

  return undefined;
};

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    if(graphQLErrors[0].message.includes('Not Authorised!') || graphQLErrors[0].message.includes('jwt malformed')) {
      // Not Authorised! jwt malformed
      localStorage.removeItem("token");
      window.location.reload()

    }
    console.log("gql error", JSON.stringify(graphQLErrors));
  }

  forward(operation);
});

const authorizationLink = setContext(async (_req, { headers }) => {
  if (_req.operationName === "signin") {
    return {
      headers,
    };
  }

  const authorization = getCurrentUserToken();
  const authHeader = authorization ? { authorization: authorization } : {};

  return {
    headers: {
      ...headers,
      ...authHeader,
    } as Headers,
  };
});

// const refreshLink = new TokenRefreshLink({
//   accessTokenField: 'newToken',
//   fetchAccessToken: async () => {
//     const token =  undefined//getCurrentUserToken()

//     if (!token) {
//       return null
//     }

//     // ts-ignore
//     const response = await fetch(`${API}`, {
//       body: JSON.stringify({
//         query: `
//           mutation {
//             refresh {
//               token
//             }
//           }
//         `,
//       }),
//       credentials: 'include',
//       headers: {
//         'content-type': 'application/json',
//       },
//       method: 'POST',
//     })

//     return response.json()
//   },
//   handleError: (error) => {
//     console.error('Cannot refresh access token:', error)
//   },
//   handleFetch: (newToken) => {
//     //setCurrentUserToken(newToken)
//   },
// //   handleResponse: () => (response: {
// //     data: { refresh: Mutation['refresh'] }
// //     errors?: unknown[]
// //   }) => {
// //     if (!response) {
// //       try {
// //         Sentry.captureMessage(
// //           'refreshLink handleResponse: response was undefined or null',
// //         )
// //       } catch (ex) {
// //         console.log('refreshLink handleResponse: error calling captureMessage')
// //       }
// //       clearCurrentUserToken()
// //       window.location.reload()

// //       return
// //     }

// //     if (response.errors) {
// //       logError(JSON.stringify(response.errors))
// //       clearCurrentUserToken()
// //       window.location.reload()

// //       return
// //     }

// //     return { newToken: response.data?.refresh?.token }
// //   },
//   isTokenValidOrUndefined: () => {
//       return true
// //     const token = getCurrentUserToken()

// //     if (!token) {
// //       return true
// //     }
// //     // @ts-expect-error
// //     if (token && decode(token)?.exp * 1000 > Date.now()) {
// //       return true
// //     }
// //     return false
//   },
// })

// const httpLink = createUploadLink({
//   credentials: "include",
//   fetch,
//   uri: API,
// });

const link = from([authorizationLink, errorLink, new HttpLink({ uri: API })]);

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  uri: API,
  cache,
  link,
  defaultOptions: { watchQuery: { fetchPolicy: "cache-and-network" } },
});
