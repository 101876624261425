import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { BookingForm } from "./form";
import { BandBooking } from "../../../__generated__/graphql";
import { GET_BOOKING } from "../graphql";
import { LoadingOverlay } from "../../../components/Loading";

const EditBooking = () => {
  const [getBand, { data, error, loading }] = useLazyQuery(GET_BOOKING);
  const { id } = useParams();

  useEffect(() => {
    console.log(id);
    if (!id) return;

    getBand({ variables: { id: id } });
  }, [id]);

  if (!data) return null;

  if (!data?.bandBooking) return null;

  return (
    <LoadingOverlay loading={loading}>
      <BookingForm booking={data?.bandBooking as BandBooking} />
    </LoadingOverlay>
  );
};

export default EditBooking;
