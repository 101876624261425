import {
  AccountCircle,
  FacebookOutlined,
  Instagram,
  Twitter,
} from "@mui/icons-material";
import { Input, InputAdornment, TextField } from "@mui/material";
import { ClockIcon } from "@mui/x-date-pickers";
import React, { ChangeEvent } from "react";
import { Merge, FieldError, FieldErrorsImpl } from "react-hook-form";

type SocialLinks = {
  [key: string]: string;
};

interface ISocialLinks {
  onChange: (event: string | React.ChangeEvent<Element>) => void;
  data: SocialLinks;
  errors:
    | Merge<
        FieldError,
        FieldErrorsImpl<{
          facebook: string;
          tiktok: string;
          twitter: string;
          instagram: string;
        }>
      >
    | undefined;
}
export const defaultData = {
  facebook: process.env.REACT_APP_SOCIALS_FACEBOOK ?? undefined,
  tiktok: process.env.REACT_APP_SOCIALS_TIKTOK ?? undefined,
  twitter: process.env.REACT_APP_SOCIALS_TWITTER ?? undefined,
  instagram: process.env.REACT_APP_SOCIALS_INSTAGRAM ?? undefined,
};

export const SocialLinks = ({ data, onChange, errors }: ISocialLinks) => {
  const changeField = (key: string, value: string) => {
    const newData = { ...data, [key]: value };
    // @ts-ignore
    onChange(newData);
  };
  const item = { ...defaultData, ...data } ?? defaultData;
  return (
    <div>
      <h3>Social Links</h3>
      <p>These appear as pressable icons on the bottom of news posts</p>
      {Object.keys(item).map((key) => {
        return (
          <RenderRow
            objectKey={key}
            // @ts-ignore
            value={item[key]}
            onChange={changeField}
            // @ts-ignore
            errors={errors ? errors[key] : undefined}
          />
        );
      })}
    </div>
  );
};

const RenderRow = ({
  objectKey,
  value,
  onChange,
  errors,
}: {
  objectKey: string;
  value: string;
  onChange: (key: string, val: string) => void;
  errors?: { message?: string };
}) => {
  let icon = null;
  switch (objectKey) {
    case "facebook":
      icon = <FacebookOutlined />;
      break;
    case "tiktok":
      icon = (
        <div style={{ width: 24 }}>
          <TikTokIcon color="rgba(0, 0, 0, 0.54)" />
        </div>
      );
      break;
    case "twitter":
      icon = <Twitter />;
      break;
    case "instagram":
      icon = <Instagram />;
      break;
    default:
      icon = <Instagram />;
  }
  const errorMessage = errors?.message?.replace("socials.", "Link for ");
  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "auto auto",
        }}
      >
        <TextField
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{icon}</InputAdornment>
            ),
          }}
          type="text"
          value={value}
          onChange={(event) => onChange(objectKey, event.target.value)}
        />
        {errorMessage}
      </div>
    </div>
  );
};
const TikTokIcon = ({ color = "#000000" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100%"
      height="100%"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};
