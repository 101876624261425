import { gql } from "../../../__generated__/gql";

export const GET_POST = gql(`
query post($id: String!) {
  post (where: {id: $id}){
    id
    date
    description
    image
    title
    socials
  }
}
`);

export const UPDATE_POST = gql(`
mutation updatePost($id: String!, $title: String!, $description: String!, $date: String!, $image: String!, $socials: JSON) {
  updatePost(post: { id: $id, title: $title, description: $description, date: $date, image:$image, socials: $socials }) {
    id
  }
}
`);

export const CREATE_POST = gql(`
mutation createPost($title: String!, $description: String!,$date: DateTime!, $image: String!, $socials: JSON) {
  createPost(title: $title, description: $description, date: $date, image: $image, socials: $socials ) {
    id
  }
}
`);

export const EXPORT_POST = gql(`mutation exportPost($postId: String!) {
  exportPost(postId: $postId)
}`);

export const DELETE_POST = gql(`mutation deletePost($postId:String!) {
  deletePost(postId: $postId){
    id
  }
}`);
