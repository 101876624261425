import { useMutation } from "@apollo/client";
import { CREATE_STAGE } from "../graphql";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Container, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PageContainer } from "../../../components/PageContainer";
import { ButtonContainer } from "../../../components/ButtonContainer";

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

export const StageForm = () => {
  const navigate = useNavigate();
  const [createStage, { loading: updateLoading, error }] = useMutation(
    CREATE_STAGE,
    {
      onCompleted: (data) => {
        toast("stage created");
        navigate(`/stage/${data.createStage.id}`);
      },
    }
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (val: any) => {
    const { name } = val;
    createStage({
      variables: {
        name,
        organisationId: process.env.REACT_APP_ORG_ID ?? "",
      },
    });
  };

  return (
    <PageContainer title="Create Stage">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container style={{ flexDirection: "column", display: "flex" }}>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            {...register("name")}
          />
          <p>{errors.name?.message}</p>
          <ButtonContainer>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </ButtonContainer>
        </Container>
      </form>
    </PageContainer>
  );
};
