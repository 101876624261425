import { gql } from "../../../__generated__/gql";

export const GET_BAND = gql(`
query band($id: String!) {
  band (where: {id: $id}){
    id
    name
    description
    imageUrl
    showcase
    bookings {
      id
      edition {
        id
        name
      }
      startTime
      endTime
      stage {
        name
      }
    }
  }
}
`);

export const UPDATE_BAND = gql(`
mutation updateBand($id: String!, $name: String, $description: String, $imageUrl: String, $showcase: Boolean) {
  updateBand(band: { id: $id, name: $name, description: $description, imageUrl: $imageUrl, showcase: $showcase }) {
    id
    name
    description
    imageUrl
    showcase
  }
}
`);

export const CREATE_BAND = gql(`
mutation ceateBand($name: String!, $description:String!, $organisationId: String!) {
  createBand(name: $name, description:$description,organisationId: $organisationId){
    id
  }
}
`);

export const ADD_IMAGE = gql(`
mutation addImage($image: String!){
  addImage(image: $image)
}
`);

export const DELETE_BAND = gql(`mutation deleteBand($bandId:String!) {
  deleteBand(bandId: $bandId){
    id
  }
}`)