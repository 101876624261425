import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { EXPORT_MISC_APP_CONFIG } from "../showcase/graphql";

export const useExportMiscAppConfig = (): [
  () => Promise<boolean>,
  { loading: boolean }
] => {
  const [exportMiscAppConfig, { loading }] = useMutation(
    EXPORT_MISC_APP_CONFIG,
    {
      onCompleted: (data) => {
        toast("App config updated");
      },
      onError: () => {
        toast("Error exporting app config");
      },
    }
  );

  return [
    exportMiscAppConfig as unknown as () => Promise<boolean>,
    { loading },
  ];
};
