import { gql } from "@apollo/client";

export const GET_STAGE = gql(`
query getStageById($id: String!) {
  stage(where: { id: $id }) {
    id
    name
    order
  }
}
`);

export const CREATE_STAGE = gql`
  mutation createStage($name: String!) {
    createStage(name: $name) {
      id
    }
  }
`;

export const UPDATE_STAGE = gql`
mutation updateStage($id: String!, $name:String!, $order:Int!) {
  updateStage(stage: {id: $id, name: $name, order: $order}) {
      id
      name
      order
    }
  }`;
