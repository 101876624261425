import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_INFO } from "../graphql";
import { EditInfoForm } from "./edit";
import { useParams } from "react-router-dom";
import { LoadingOverlay } from "../../../components/Loading";
import { SecondForm } from "./secondForm";

export const EditInfo = () => {
  const { id } = useParams();
  const { data, loading, error, refetch } = useQuery(GET_INFO, {
    variables: {
      id: id!,
    },
  });

  if (!data?.info) return <LoadingOverlay loading={true} />;

  const values = [...data?.info?.value?.items ?? []];
  // @ts-ignore
  const sorted = values.sort((a, b) => a.order - b.order);

  return (
    <>
      <EditInfoForm info={data.info} refetch={refetch} />
      <SecondForm info={data.info} items={sorted} refetch={refetch} />
    </>
  );
};
