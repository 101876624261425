import { gql } from "../../../__generated__/gql";

export const GET_BOOKING = gql(`
query bandBooking($id:String) { 
  bandBooking(where: {id: $id}){
    id
    edition {
      id
    }
    band  {
      id
      name
    }
    stage {
      id
      name
    }
    date
    duration
    startTime
    endTime  
  }}
`);

export const UPDATE_BOOKING = gql(`
mutation updateBooking($id: String!, $startTime: String!, $endTime: String!, $date: String!, $stageId: String!) {
  updateBandBooking(bandBooking: { id: $id, startTime:$startTime, endTime: $endTime, date: $date, stageId: $stageId})
  {
    id
    band  {
      id
      name
    }
    date
    duration
    startTime
    endTime 
    stage {
      name
    }
  }
}
`);

export const DELETE_BOOKING = gql(`
mutation deleteBandBooking($bookingId: String!) {
  deleteBandBooking(bookingId: $bookingId) {
    id
  }
}
`);

export const CREATE_BOOKING = gql(`
mutation createBooking($bandId: String!, $startTime: String!,$editionId: String!, $stageId: String!, $endTime: String!, $date: String!  ) {
  createBandBooking(bandId: $bandId,
                   startTime: $startTime,
  editionId: $editionId,
  stageId: $stageId,
            endTime: $endTime,
            date: $date) {
    id
                   }
}
`);
