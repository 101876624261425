import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export interface ILinkButtonProps {
  path: string;
  text: string;
  variant?: "text" | "outlined" | "contained";
  style?:React.CSSProperties
}
export const LinkButton = ({
  path,
  text = "text",
  variant,
  style
}: ILinkButtonProps) => {

  return (
    <Button variant={variant} style={style} href={path}>
      {text}
    </Button>
  );
};
