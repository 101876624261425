import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { ButtonContainer } from "../../components/ButtonContainer";
import { PageContainer } from "../../components/PageContainer";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_CONFIG, GET_CONFIG, UPDATE_CONFIG } from "./graphql";
import { Config, ConfigInputType } from "../../__generated__/graphql";
import { toast } from "react-toastify";
import { UpdateMiscConfigButton } from "../links/updateMiscConfig";
// yup.addMethod(yup.mixed, "uniqueIn", function (array = [], message) {
//   return this.test("uniqueIn", message, function (value) {
//     // @ts-ignore
//     return array.filter((item) => item === value).length < 2;
//   });
// });
// yup.addMethod(yup.array, "uniqueIn", function (array = [], message) {
//   return this.test("uniqueIn", message, function (value) {
//     // @ts-ignore
//     return array.filter((item) => item === value).length < 2;
//   });
// });

// @ts-ignore
// yup.addMethod(yup.array, "uniqueProperty", function (propertyPath, message) {
//   return this.test("unique", "", function (list) {
//     // // @ts-ignore
//     // const errors = [];

//     // // @ts-ignore
//     // list.forEach((item, index) => {
//     //   const propertyValue = r.find(propertyPath, item )

//     //   // @ts-ignore
//     //   const hasPropertyValue = (a) => a[propertyPath] as string === propertyValue as string

//     //   // @ts-ignore
//     //   const filtered = r.filter(pipe(r.prop(propertyPath), r.includes(propertyValue)), list)

//     //   if (
//     //     propertyValue &&  filtered.length > 1

//     //   ) {
//     //     errors.push(
//     //       this.createError({
//     //         path: `${this.path}[${index}].${propertyPath}`,
//     //         message,
//     //       })
//     //     );
//     //   }
//     // });

//     // // @ts-ignore
//     // if (!r.isEmpty(errors)) {
//     //   // @ts-ignore
//     //   throw new yup.ValidationError(errors);
//     // }

//     return true;
//   });
// });
yup.addMethod(yup.object, "uniqueProperty", function (propertyName, message) {
  return this.test("unique", message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    if (
      this.parent
        // @ts-ignore
        .filter((v) => v !== value)
        // @ts-ignore
        .some((v) => v[propertyName] === value[propertyName])
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
      });
    }

    return true;
  });
});

// @ts-ignore
// yup.addMethod(yup.array, "unique", function (message, mapper = (a) => a) {
//   return this.test("unique", message, function (list) {
//     // @ts-ignore
//     return list.length === new Set(list.map(mapper)).size;
//   });
// });

const schema = yup
  .object({
    config: yup.array().of(
      yup
        .object({
          keyId: yup.string(),
          value: yup.string(),
        })
        .test("unique", "keyId must be unique", function (value) {
          const propertyName = "keyId";
          if (!value || !value[propertyName]) {
            return true;
          }

          const { path } = this;
          const options = [...this.parent];
          const currentIndex = options.indexOf(value);

          const subOptions = options.slice(0, currentIndex);

          if (
            subOptions.some(
              (option) => option[propertyName] === value[propertyName]
            )
          ) {
            throw this.createError({
              path: `${path}.${propertyName}`,
              message: "keyId must be unique",
            });
          }

          return true;
        })
    ),
  })
  .required();

export const ManageConfigTable = ({
  data,
  refetch,
}: {
  data: Config[];
  refetch: () => void;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      config: data ?? ([] as { keyId: string; value: string }[]),
    },
  });

  const { fields, replace, append, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "config", // unique name for your Field Array
    }
  );

  const [updateConfig, { loading, error }] = useMutation(UPDATE_CONFIG, {
    awaitRefetchQueries: true,
    refetchQueries: ["config"],
    onCompleted: () => {
      refetch()
      toast("Update complete");
    },
  });

  const [deleteConfig] = useMutation(DELETE_CONFIG, {
    awaitRefetchQueries: true,
    refetchQueries: ["config"],
    onCompleted: () => {
      refetch()
      toast("Delete complete");
    },
  });

  const onSubmit = (values: {
    config:
      | { keyId?: string | undefined; value?: string | undefined }[]
      | undefined;
  }) => {
    const settings = values?.config?.map((item) => {
      return { key: item.keyId, value: item.value };
    });

    updateConfig({
      variables: {
        settings,
      },
    });
  };

  return (
    <PageContainer title="Config">
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "flex", flexDirection: "column", width: "50%" }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            minHeight: "100%",
            alignItems: "center",
            minWidth: 800,
          }}
        >
          <Button
            onClick={() =>
              append({
                keyId: "",
                value: "",
              })
            }
            color="success"
            variant="contained"
            style={{ marginTop: 18, marginBottom: 18, width: 200 }}
          >
            Add New
          </Button>
          <UpdateMiscConfigButton />
        </div>
        <TableContainer component={Paper} style={{ overflow: "visible" }}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            style={{ overflow: "visible" }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">key</TableCell>
                <TableCell align="left">value</TableCell>
                <TableCell align="left">action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((item, index) => {
                // @ts-ignore
                // console.log("errors!", errors.config[index]?.keyId?.message);
                return (
                  <TableRow
                    key={item.keyId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    style={{ overflow: "visible" }}
                  >
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        disabled={!!item.keyId}
                        id="outlined-basic"
                        label="key"
                        variant="outlined"
                        defaultValue={item.keyId}
                        {...register(`config.${index}.keyId`)}
                      />
                      {/* @ts-ignore */}

                      {errors.config?.[index]?.keyId?.message}
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="value"
                        variant="outlined"
                        defaultValue={item.value}
                        {...register(`config.${index}.value`)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        onClick={() => {
                          // @ts-ignore
                          if (item.keyId === "") {
                            remove(index);
                          } else {
                            deleteConfig({ variables: { key: item.keyId } });
                          }
                        }}
                        color="error"
                        variant="contained"
                        style={{
                          // marginTop: 18,
                          marginBottom: 20,
                          // marginRight: 8,
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <p>{JSON.stringify(errors.config)}</p> */}
        {/* {JSON.stringify(errors.config ?? '', null, 2)} */}
        <ButtonContainer>
          <Button
            type="submit"
            variant="contained"
            style={{ width: 300, marginTop: 20 }}
          >
            Save
          </Button>
        </ButtonContainer>
      </form>
    </PageContainer>
  );
};
