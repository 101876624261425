import { Button, Input } from "@mui/material";
import React, { ReactNode, useState } from "react";
import { PageContainer } from "../../../components/PageContainer";
interface ISelectProps {
  onCompletePress: (file: string, fileName: string) => void;
}

export const Select = ({ onCompletePress }: ISelectProps) => {
  const [image, setImage] = useState<string>();
  const [file, setFile] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const [fileSize, setFileSize] = useState<string>();
  const onFileChange = async (event: any) => {
    try {
      const file = event.target.files[0];
      const name = file.name;
      setFileName(name);

      const obj = URL.createObjectURL(event.target.files[0]);
      // setFileSize(`${Math.round(file.size / 1024 / 1024)}mb`);
      setImage(obj);
      setFile(file);
    } catch (err) {
      console.log(err);
    }
  };
  const handleClick = () => {
    if (image && fileName) {
      onCompletePress(image, fileName);
    }
  };

  return (
    <PageContainer title="Select an image">
      <EditImageContainer>
        <Input type="file" onChange={onFileChange} />
        <Button
          disabled={!file}
          onClick={handleClick}
          variant="contained"
          style={{ alignSelf: "flex-end" }}
        >
          Proceed
        </Button>
      </EditImageContainer>
      <img src={image} alt="" width={"75%"} />
    </PageContainer>
  );
};

type PropsWithChildren<P> = P & { children?: ReactNode };

export const EditImageContainer: React.FunctionComponent<
  PropsWithChildren<{}>
> = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: 46,
      }}
    >
      {children}
    </div>
  );
};
