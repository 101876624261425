import { Container, Button } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useForm } from "react-hook-form";
import { Post } from "../../../__generated__/graphql";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { StagePicker } from "../../../components/StagePicker";
import { CREATE_BOOKING } from "../graphql";
import { BandPicker } from "../../../components/BandPicker";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PageContainer } from "../../../components/PageContainer";
import { ButtonContainer } from "../../../components/ButtonContainer";
import { ErrorField } from "../../../components/ErrorField";
import { useNavigate } from "react-router-dom";
const schema = yup
  .object({
    band: yup.string().required("A band is required"),
    selectedStartTime: yup
      .string()
      .nonNullable()
      .required("Start time is required")
      .test(
        "valid-starttime",
        "Start time must be before endtime",
        (selectedStartTime, ctx) => {
          const { selectedEndTime } = ctx.parent;
          return dayjs(selectedEndTime).isAfter(dayjs(selectedStartTime));
        }
      ),
    selectedEndTime: yup
      .string()
      .nonNullable()
      .required("End time is required")
      .test(
        "valid-endtime",
        "End time must be after start time",
        (selectedEndTime, ctx) => {
          const { selectedStartTime } = ctx.parent;
          return dayjs(selectedEndTime).isAfter(dayjs(selectedStartTime));
        }
      ),
    stage: yup.string().required("Stage is required"),
  })
  .required();

interface ICreateBookingFormProps {
  festivalId: string;
}

export const CreateBookingForm = ({ festivalId }: ICreateBookingFormProps) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    setError,
    trigger,
  } = useForm({ resolver: yupResolver(schema) });
  const [selectedStartTime, setSelectedStartTime] = useState<Dayjs | null>(
    null
  );
  const [selectedEndTime, setSelectedEndTime] = useState<Dayjs | null>(null);
  const [selectedStage, setSelectedStage] = useState<string>();
  const [selectedBand, setSelectedBand] = useState<string>();
  const [createBooking, { loading: updateLoading, error }] = useMutation(
    CREATE_BOOKING,
    {
      refetchQueries: ['festivalEditions', 'festivalSingalEdition'],
      onCompleted: (data) => {
        toast("Added successfully");
        navigate(`/booking/${data.createBandBooking.id}`);
      },
    }
  );
  const onSubmit = (data: any) => {
    let date = dayjs(selectedStartTime).clone();
    date = date.set("hour", 0);
    date = date.set("minute", 0);

    createBooking({
      variables: {
        startTime: selectedStartTime?.toDate().toString()!,
        endTime: selectedEndTime?.toDate().toString()!,
        stageId: selectedStage!,
        date: date.toDate().toString()!,
        bandId: selectedBand!,
        editionId: festivalId,
      },
    });
  };
  const updateEndTime = (value: Dayjs | null) => {
    setValue("selectedEndTime", value?.toString() ?? "");
    trigger("selectedEndTime");
    setSelectedEndTime(value!);
  };

  const updateStartTime = (value: Dayjs | null) => {
    setValue("selectedStartTime", value?.toString() ?? "");
    trigger("selectedStartTime");
    setSelectedStartTime(value!);
  };
  const updateStage = (value: string) => {
    setValue("stage", value?.toString() ?? "");
    trigger("stage");
    setSelectedStage(value!);
  };
  const updateBand = (value: string) => {
    setValue("band", value?.toString() ?? "");
    trigger("band");
    setSelectedBand(value!);
  };
  return (
    <PageContainer title={"Create a booking"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container style={{ flexDirection: "column", display: "flex" }}>
          <BandPicker
            handleChange={(value) => updateBand(value)}
            value={selectedBand}
          />
          <ErrorField additionalStyle={{ marginTop: -6 }}>
            {errors.band?.message}
          </ErrorField>
          <DateTimePicker
            format="YYYY/MM/DD HH:mm"
            ampm={false}
            timezone="Europe/London"
            label="Start Time"
            value={dayjs(selectedStartTime)}
            onChange={(value: Dayjs | null) => updateStartTime(value!)}
          />{" "}
          <ErrorField>{errors.selectedStartTime?.message}</ErrorField>
          <DateTimePicker
            format="YYYY/MM/DD HH:mm"
            ampm={false}
            timezone="Europe/London"
            label="End Time"
            value={dayjs(selectedEndTime)}
            onChange={(value: Dayjs | null) => updateEndTime(value!)}
          />
          <ErrorField>{errors.selectedEndTime?.message}</ErrorField>
          <StagePicker
            value={selectedStage}
            handleChange={(value) => updateStage(value)}
            festivalId={festivalId}
          />
          <ErrorField additionalStyle={{ marginTop: 6 }}>
            {errors.stage?.message}
          </ErrorField>
          <ButtonContainer>
            <Button type="submit" variant="contained" style={{ marginTop: 18 }}>
              Save
            </Button>
          </ButtonContainer>
        </Container>
      </form>
    </PageContainer>
  );
};
