import { PageContainer } from "../../components/PageContainer";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_CONFIG } from "../config/graphql";
import { toast } from "react-toastify";
import { GET_INFOS } from "./graphql";
import { InfoList } from "./list";

export const InfoPage = () => {
  const { data, loading, error, refetch } = useQuery(GET_INFOS);
  const [updateConfig] = useMutation(UPDATE_CONFIG, {
    awaitRefetchQueries: true,
    refetchQueries: ["config"],
    onCompleted: () => {
      toast("Map Url Updated");
    },
  });

  const values = [...(data?.infos ?? [])];
  // @ts-ignore
  const sorted = values.sort((a, b) => a.order - b.order);

  return <InfoList infos={sorted} refetch={refetch} />;
};
