import { Box, CircularProgress, Container, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
export const Info: React.FC = () => {
  return (
    <Container
      style={{
        // margin: 30,
        backgroundColor: "lightblue",
        width: "50%",
        borderRadius: 14,
        height: 150,
        justifyContent: "flex-end",
        display: "flex",
        alignItems: "center",
        // textAlign: "center",
        // alignSelf:'center',
        margin: "auto",
        marginTop: 30,
      }}
    >
      <p
        style={{
          // alignSelf: "center",
          // justifySelf: "center",

          // backgroundColor: "red",
          // textAlign:'center'
          // verticalAlign:'center'
          justifyContent: "center",
          alignContent: "center",
          textAlign: "center",
        }}
      >
        Note: Changes will not appear in the app until the festival edition has
        been republished - via the Update App button
      </p>
    </Container>
  );
};
